import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createAgencies } from '../../Repositories';

const agenciesReq = createAgencies();

/**
 * this function(query) is to get All agencies
 * @function useAgenciesGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useAgenciesGetQuery(reqParams, options) {
  return useQuery(['agenciesGetQuery', reqParams], () => agenciesReq.getAllAgencies(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to get agencies by Id
 * @function useAgencyGetQueryById
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useAgencyGetQueryById(reqParams, options) {
  return useQuery(['agencyGetQueryById', reqParams], () => agenciesReq.getAgencyById(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post agency
 * @function useAddAgencyQuery
 * @returns api response
 */
export function useAddAgencyQuery() {
  const queryClient = useQueryClient();
  return useMutation(['agencyPostQuery'], (args) => agenciesReq.postAgency(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['agenciesGetQuery']);
    },
  });
}

/**
 * this function(query) is to put agency by Id
 * @function useUpdateAgencyQueryById
 * @returns api response
 */
export function useUpdateAgencyQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['agencyPutQueryById'], (args) => agenciesReq.putAgencyById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['agenciesGetQuery']);
    },
  });
}

/**
 * this function(query) is to delete agency by Id
 * @function useDeleteAgencyQueryById
 * @returns api response
 */
export function useDeleteAgencyQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['agencyDeleteQueryById'], (args) => agenciesReq.deleteAgencyById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['agenciesGetQuery']);
    },
  });
}
