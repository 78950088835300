import React from 'react';
import { Typography } from '@mui/material';

import { MAX_CHARACTER_LIMIT } from '../../constants';

import QuillEditorWithoutCounter from './quillEditor';

import './editor.css';

function QuillEditor(props) {
  const quillEditorLength = props.description.replace(/(<([^>]+)>)|\n|&nbsp;/gi, '').length;
  return (
    <div className={`counter-editor${props.isDark ? '-dark' : ''}`}>
      <QuillEditorWithoutCounter {...props} />
      <div className="word-counter-container">
        <Typography variant="h6">
          {quillEditorLength}/{MAX_CHARACTER_LIMIT}
        </Typography>
      </div>
    </div>
  );
}

export default QuillEditor;
