import { Children, cloneElement, useEffect, useState } from 'react';

import { useUpdateLevelQueryById } from '../../Common/Queries/levelQueries';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => values;

export function EditLevelContainer(props) {
  const putToLevel = useUpdateLevelQueryById();
  const { formData } = props;
  const [values, setValues] = useState(formData);

  useEffect(() => {
    setValues(formData);
  }, [formData]);

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   */
  function handleOnSubmit(body) {
    const reqBody = formateData(body);
    const reqParams = { reqBody, reqId: props.levelId };
    putToLevel.mutate(reqParams);
  }

  // for closing pop over after adding position
  useEffect(() => {
    if (putToLevel.isSuccess) {
      props.setOpen(false);
    }
  }, [putToLevel.isSuccess]);

  const children = Children.only(props.children);
  return cloneElement(children, { values: values || {}, putToLevel, handleOnSubmit });
}
