import { Children, cloneElement } from 'react';

import { useTeamsGetQuery } from '../../../Common/Queries/teamQueries';

import { ALL_DATA_LIMIT } from '../../../constants';

export function TeamFieldContainer({ children, positionID, ...props }) {
  const teams = useTeamsGetQuery({ limit: ALL_DATA_LIMIT, positionID });

  return Children.only(cloneElement(children, { teams, ...props }));
}
