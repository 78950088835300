import React, { useContext, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';

import { AuthContext } from '../../../constants';

import LogoutUIWrapper from '../Logout';

export default function LogoutButton() {
  const { userInfo } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const profileName = userInfo?.name;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        disableInteractive
        title={
          <>
            <Typography variant="h6">{profileName}</Typography>
            <Typography variant="caption">{userInfo?.sub}</Typography>
          </>
        }
      >
        <button className="user-circle" type="button" onClick={handleClick}>
          {profileName ? profileName[0] : '/'}
        </button>
      </Tooltip>
      <LogoutUIWrapper anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
}
