import React, { useEffect, useState } from 'react';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { GetNotificationsContainer } from '../../Container';

import { filter4xxError } from '../../utils';

import CustomPopover from '../CustomPopover';
import { AlertError } from '../Error';

import NotificationContent from './notification';

const Div = styled('div')``;

export function NotificationsUI({ notifications }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (anchorEl) {
      notifications.refetch();
    }
  }, [anchorEl]);

  return (
    <>
      {notifications.isError && (
        <AlertError errors={[notifications.error]} errorMapper={filter4xxError} />
      )}
      <Div sx={{ ...(anchorEl && { backgroundColor: 'lightgray', borderRadius: '50%' }) }}>
        <IconButton onClick={handleClick}>
          <Badge badgeContent={notifications.data?.length || 0} color="secondary">
            <NotificationsNoneOutlined />
          </Badge>
        </IconButton>
        <CustomPopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <NotificationContent handleClose={handleClose} notifications={notifications} />
        </CustomPopover>
      </Div>
    </>
  );
}

export default function Notifications(props) {
  return (
    <GetNotificationsContainer {...props}>
      <NotificationsUI />
    </GetNotificationsContainer>
  );
}
