// @ts-check

import { convertToISO } from '../Date/convertToISO';

/**
 * @function isValidDate
 * @param {String} dates string of dates joined with ','
 * @returns whether all the dates are in valid format or not
 */
export function isValidDate(dates) {
  if (!dates) return false;
  const datesArr = dates.split(',');
  return datesArr.every(
    (date) => new Date(date).toString() !== 'Invalid Date' && date === convertToISO(date)
  );
}
