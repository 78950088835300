import { Children, cloneElement, useEffect, useState } from 'react';

import { useUpdateSkillQueryById } from '../../Common/Queries/skillQueries';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => values;

export function EditSkillContainer(props) {
  const putToSkill = useUpdateSkillQueryById();
  const { formData } = props;
  const [values, setValues] = useState(formData);

  useEffect(() => setValues(formData), [formData]);

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   */
  function handleOnSubmit(e, body) {
    e.preventDefault();
    const reqBody = formateData(body);
    const reqParams = { reqBody, reqId: props.skillId };
    putToSkill.mutate(reqParams);
  }

  // for closing pop over after adding skill
  useEffect(() => {
    if (putToSkill.isSuccess) props.setOpen(false);
  }, [putToSkill.isSuccess]);

  const children = Children.only(props.children);
  return cloneElement(children, { values: values || {}, putToSkill, handleOnSubmit });
}
