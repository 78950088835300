import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import {
  useDeleteSkillQueryById,
  useSkillsGetQuery,
  useUpdateSkillQueryById,
} from '../../Common/Queries/skillQueries';

import { isValidID } from '../../utils';

import { AuthContext } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = ['status', 'searchValue', 'page', 'order', 'orderBy'];
  return keyArray.every((key) => {
    if (key === keyArray[0]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[1]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[2]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[3]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return (
      filterObject[key] === '' || filterObject[key] === 'name' || filterObject[key] === 'createdAt'
    );
  });
};

const initialValues = { status: [], searchValue: '', order: '', orderBy: '', page: 0 };

export const SkillListContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const initialStatus = urlQuery.get('status');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialSearch = urlQuery.get('search');

  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-skill`);

  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);

  const initialFilterValuesToApply = {
    ...initialValues,
    page: initialPage ? Number(initialPage - 1) : 0,
    ...(filterValuesObj && validateFilterObject(filterValuesObj)
      ? {
          status: initialStatus ? initialStatus.split(',') : filterValuesObj.status,
          searchValue: initialSearch || filterValuesObj.searchValue,
          order: 'desc',
          orderBy: 'createdAt',
        }
      : {
          status: initialStatus ? initialStatus.split(',') : [],
          searchValue: initialSearch || '',
          order: 'desc',
          orderBy: 'createdAt',
        }),
  };
  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  const skills = useSkillsGetQuery({
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    status: filters.status.join(','),
    q: filters.searchValue,
    limit: 20,
  });

  const deleteSkill = useDeleteSkillQueryById();

  const putToSkill = useUpdateSkillQueryById();

  const handleOnDelete = (id) => () => {
    const deletedRecord = skills.data.data.filter((requirement) => requirement.id === id)[0];
    const reqBody = { versionKey: deletedRecord.versionKey };
    const reqParams = { reqBody, reqId: id };
    deleteSkill.mutate(reqParams);
  };

  const handleOnPut = (id, status) => {
    const versionKeyArray = skills.data.data.filter((item) => item.id === id);
    const reqParams = { reqBody: { ...versionKeyArray[0], status }, reqId: id };
    putToSkill.mutate(reqParams);
  };

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, {
    skills,
    deleteSkill,
    putToSkill,
    handleOnDeleteSkill: handleOnDelete,
    handleOnPutSkill: handleOnPut,
    filterHandler: handleOnFilterChange,
    filters,
  });
};
