import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createStreams } from '../../Repositories';

const streamReq = createStreams();

/**
 * this function(query) is to get All stream
 * @function useStreamGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useStreamGetQuery(reqParams, options) {
  return useQuery(['streamGetQuery', reqParams], () => streamReq.getAllStream(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to get All stream by Id
 * @function useStreamGetQueryById
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useStreamGetQueryById(reqParams, options) {
  return useQuery(['streamGetQueryById', reqParams], () => streamReq.getStreamById(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post stream
 * @function useAddStreamQuery
 * @returns api response
 */
export function useAddStreamQuery() {
  const queryClient = useQueryClient();
  return useMutation(['streamPostQuery'], (args) => streamReq.postStream(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['streamGetQuery']);
    },
  });
}

/**
 * this function(query) is to put stream by Id
 * @function useUpdateStreamQueryById
 * @returns api response
 */
export function useUpdateStreamQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['streamPutQueryById'], (args) => streamReq.putStreamById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['streamGetQuery']);
    },
  });
}

/**
 * this function(query) is to delete stream by Id
 * @function useDeleteStreamQueryById
 * @returns api response
 */
export function useDeleteStreamQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['streamDeleteQueryById'], (args) => streamReq.deleteStreamById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['streamGetQuery']);
    },
  });
}
