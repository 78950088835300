import { Children, cloneElement, useEffect } from 'react';

import { useConfigGetQuery, useConfigPutQuery } from '../../../Common/Queries/configQueries';

const initialValues = { position: '', screeningTime: 0 };

export function PositionScreeningTimeContainer({ children, setOpen, ...props }) {
  const child = Children.only(children);
  const putToConfig = useConfigPutQuery();
  const configData = useConfigGetQuery();

  function handleOnSubmitDefaultTime(req) {
    const reqParams = {
      ...configData?.data,
      automaticScreeningTime: {
        ...configData?.data?.automaticScreeningTime,
        default: req.defaultTime,
      },
    };
    putToConfig.mutate(reqParams);
  }

  function handleOnSubmit(req) {
    const latestOverrides = configData?.data?.automaticScreeningTime?.overrides?.filter(
      ({ positionID }) => positionID !== req.positionID
    );

    const reqParams = {
      ...configData?.data,
      automaticScreeningTime: {
        ...configData?.data?.automaticScreeningTime,
        overrides: [...latestOverrides, req],
      },
    };
    putToConfig.mutate(reqParams);
  }

  useEffect(() => {
    if (putToConfig.isSuccess) setOpen(false);
  }, [putToConfig.isSuccess]);

  return cloneElement(child, {
    putToConfig,
    initialValues,
    handleOnSubmit,
    handleOnSubmitDefaultTime,
    selectedPositions: configData?.data?.automaticScreeningTime?.overrides?.map(
      ({ positionID }) => positionID
    ),
    ...props,
  });
}
