import { Children, cloneElement, useEffect, useState } from 'react';

import { useTeamPutQueryById } from '../../Common/Queries/teamQueries';

export const formateData = (values) => values;
export function EditTeamContainer(props) {
  const putToTeam = useTeamPutQueryById();
  const { formData } = props;
  const [values, setValues] = useState(formData);

  useEffect(() => setValues(formData), [formData]);

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   */
  function handleOnSubmit(e, body) {
    e.preventDefault();
    const reqBody = formateData(body);
    const reqParams = { reqBody, reqId: props.teamId };
    putToTeam.mutate(reqParams);
  }

  // for closing pop over after adding position
  useEffect(() => {
    if (putToTeam.isSuccess) props.setOpen(false);
  }, [putToTeam.isSuccess]);

  const children = Children.only(props.children);
  return cloneElement(children, { values: values || {}, putToTeam, handleOnSubmit });
}
