import React, { useRef, useState } from 'react';
import { faro } from '@grafana/faro-web-sdk';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TextField } from '@mui/material';

import { useFileUploadGetQuery } from '../../Common/Queries/applicationsQueries';

import { ACCEPT_TYPES } from '../../constants';

import { PrimaryCustomButton } from '../CustomButton';
import { AlertError } from '../Error';
import SimpleLoader from '../Loader/SimpleLoader';

import './index.css';

export function FileUpload({
  handleOnFileUploading = () => {},
  handleOnSuccess,
  handleOnUploadError = () => {},
  buttonText = 'Upload File',
}) {
  const hiddenFileInput = useRef();

  const [fileName, setFileName] = useState('');
  const [fileStatus, setFileStatus] = useState('');
  const [message, setMessage] = useState('');

  const { refetch } = useFileUploadGetQuery();

  const handleFileUpload = async (event) => {
    event.preventDefault();
    let resumeValue = event.target.files && event.target.files[0];
    if (!resumeValue) resumeValue = event?.dataTransfer?.files && event?.dataTransfer?.files[0];
    if (resumeValue) {
      const fileSize = resumeValue.size / 1024 / 1024;
      if (fileSize > 5) {
        setFileStatus('ERROR');
        setMessage('file size should not be more than 5MB');
        return;
      }
      if (
        resumeValue.type !== 'image/jpeg' &&
        resumeValue.type !== 'image/webp' &&
        resumeValue.type !== 'image/png' &&
        resumeValue.type !== 'application/pdf' &&
        resumeValue.type !== 'application/msword' &&
        resumeValue.type !==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        setFileStatus('ERROR');
        setMessage('file should be of type .pdf, .doc, .docx, .png, .webp, .jpeg only');
        return;
      }
      try {
        setFileStatus('LOADING');
        const { data } = await refetch();

        const uploadUrl = data?.['upload-url'];
        if (!uploadUrl) {
          throw new Error('Failed to get upload URL');
        }
        handleOnFileUploading(true);
        const file = new Blob([resumeValue], {
          type: resumeValue.type,
        });
        const resp = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
        });
        if (!resp.ok) {
          throw resp;
        }
        setFileStatus('UPLOADED');
        setFileName(resumeValue.name);
        handleOnSuccess({
          data: { value: data?.url },
        });
      } catch (error) {
        if (faro && faro.api && faro.api.pushError) {
          faro.api.pushError(error);
        }
        setFileStatus('ERROR');
        setMessage('error while uploading the file');
        handleOnUploadError({ error: { message: 'file upload fails', status: error.status } });
      }
    }
  };

  const handleOnFileClear = (e) => {
    e.preventDefault();
    setFileName('');
    handleOnSuccess({ data: { value: '' } });
  };

  return (
    <div className="application-same-row-wrapper-item">
      {fileStatus === 'ERROR' && (
        <AlertError
          message={message}
          handleClose={(_value) => {
            setFileStatus('');
            setMessage('');
          }}
        />
      )}
      <TextField
        inputRef={hiddenFileInput}
        fullWidth
        sx={{ display: 'none', marginTop: '5px' }}
        id="resume"
        type="file"
        inputProps={{
          'data-testid': 'testResume',
          accept: ACCEPT_TYPES,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleFileUpload}
        key={Date.now()}
      />
      <PrimaryCustomButton
        data-testid="testResumeButton"
        id="add-resume-button"
        sx={{ width: '130px', whiteSpace: 'nowrap' }}
        disabled={fileName !== '' || fileStatus === 'LOADING'}
        onClick={() => {
          hiddenFileInput.current.click();
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDrop={handleFileUpload}
      >
        {buttonText}
      </PrimaryCustomButton>
      {fileStatus === 'LOADING' && <SimpleLoader size={30} />}
      <span className="resume-name">{fileName}</span>
      {fileName && (
        <IconButton onClick={handleOnFileClear} data-testid="testClearResume">
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
}
