import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createReferrals } from '../../Repositories';

const referralsReq = createReferrals();

/**
 * this function(query) is to get All referrals
 * @function useReferralsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useReferralsGetQuery(reqParams, options) {
  return useQuery(['referralsGetQuery', reqParams], () => referralsReq.getAllReferrals(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post referral
 * @function useAddReferralQuery
 * @returns api response
 */
export function useAddReferralQuery() {
  const queryClient = useQueryClient();
  return useMutation(['referralPostQuery'], (args) => referralsReq.postReferral(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['referralsGetQuery']);
      queryClient.invalidateQueries(['dashboardGetQuery']);
    },
  });
}
