import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { FaroErrorBoundary, withFaroProfiler } from '@grafana/faro-react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Error } from './Components/Error';

import ContextProvider from './services/context';
import { initializeFaro } from './initialize';
import Router from './routes';
import theme from './theme';

const queryClient = new QueryClient();

Bugsnag.start({
  apiKey: 'fe8bc4473ad8a1fe687dd69ae7989857',
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function App() {
  useEffect(() => {
    initializeFaro();
  }, []);

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={Error}>
        <FaroErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <ContextProvider>
                  <Router />
                </ContextProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </QueryClientProvider>
        </FaroErrorBoundary>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default withFaroProfiler(App);
