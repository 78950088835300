import { useQuery } from '@tanstack/react-query';

import { createReports } from '../../Repositories';

const reportsReq = createReports();

export function useReportsGetQuery(reqParams, options) {
  return useQuery(['reportsGetQuery', reqParams], () => reportsReq.getReports(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}
