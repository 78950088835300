import { Children, cloneElement, useEffect } from 'react';

import {
  useApplicationsPostQuery,
  useDuplicateCheckQuery,
} from '../../Common/Queries/applicationsQueries';

import { htmlToMD, IntlFormatedDate } from '../../utils';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (state, positionData, noticeValue) => {
  const markdown = htmlToMD(state.additionalInfo);
  return {
    name: state.name,
    phone: `+91${state.phone}`,
    email: state.email,
    links: state.links.filter((link) => link.type !== '' && link.value !== ''),
    resume: state.resume,
    skills: state.skills.filter((skill) => skill.name !== '' && skill.level !== ''),
    noticePeriod:
      noticeValue === 'Immediate' || noticeValue === 'Serving'
        ? `${noticeValue} (${IntlFormatedDate(state.noticePeriod)})`
        : state.noticePeriod,
    additionalInfo: markdown,
    currentSalary: Number(state.currentSalary),
    expectedSalary: Number(state.expectedSalary),
    currentLocation: state.currentLocation,
    position: positionData || state.position,
    agencyId: state.agencyId,
    isUpload: state.isUpload,
  };
};

/**
 * @function duplicateData
 * @param values
 * @returns duplicateData check data values
 */

export const duplicateData = (state) => {
  return {
    phone: `+91${state.phone}`,
    email: state.email,
    position: state.position,
  };
};

export function AddApplicationContainer({ setOpen, positionData, children }) {
  const postToApplications = useApplicationsPostQuery();

  const postDuplicateCheck = useDuplicateCheckQuery();

  function handleSubmitForAddApplication(finalValues, noticeValue) {
    const reqBody = formateData(finalValues, positionData, noticeValue);
    postToApplications.mutate(reqBody);
  }

  function handleDuplicateCheck(values) {
    const reqBody = duplicateData(values);
    postDuplicateCheck.mutate(reqBody);
  }

  useEffect(() => {
    if (postToApplications.isSuccess) {
      setOpen(false);
    }
  }, [postToApplications.isSuccess]);

  return Children.only(
    cloneElement(children, {
      positionData,
      postToApplications,
      handleSubmit: handleSubmitForAddApplication,
      handleDuplicateCheck,
      postDuplicateCheck,
    })
  );
}
