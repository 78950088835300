import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { APPLICATIONS_ENDPOINT, BASE_URL, POSITIONS_ENDPOINT } from '../../constants';

/**
 * Creates a new Person.
 * @Positions
 */
export class Positions {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Positions(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = POSITIONS_ENDPOINT;
    this.applications_endpoint = APPLICATIONS_ENDPOINT;
    this.fetcher = fetcher;
  }
  /**
   * this function is to get All positions
   * @function getAllPosition
   ** @param {reqParam} default parameter
   */

  async getAllPosition(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }
  /**
   * this function is to get All ApplicantStatsCount
   * @function getAllApplicantStatsCount
   ** @param {reqParam} default parameter
   */

  async getAllApplicantStatsCount(reqParams) {
    const { reqId, ...queryParams } = reqParams;
    const queryString = queryBuilder(queryParams);
    const url = `${this.base_url}${this.endpoint}/${reqId}/stats${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
  /**
   * this function is to get position by Id
   * @function getPositionById
   ** @param {reqId} default parameter
   */

  async getPositionById(reqParams) {
    const { reqId, ...queryParams } = reqParams;
    const queryString = queryBuilder(queryParams);
    const url = `${this.base_url}${this.endpoint}/${reqId}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  /**
   * this function is to get applications related to position by Id
   * @function getPositionKanbanById
   ** @param {object} reqParams parameter
   */

  async getPositionKanbanById(reqParams) {
    const { reqId, ...queryParams } = reqParams;
    const queryString = queryBuilder(queryParams);
    const url = `${this.base_url}${this.endpoint}/${reqId}${this.applications_endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  /**
   * this function is to update position by Id
   * @function putPositionById
   ** @param {reqParams} default parameter
   */

  async putPositionById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}`;
    const response = await this.fetcher.putData(url, reqParams.reqBody);
    return response?.data;
  }

  /**
   * this function is to update position by Id
   * @function putPositionStatusById
   ** @param {reqParams} default parameter
   */

  async putPositionStatusById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}/status`;
    const response = await this.fetcher.putData(url, reqParams.reqBody);
    return response?.data;
  }
  /**
   * this function is to post position
   * @function postPosition
   ** @param {reqBody} default parameter
   */

  async postPosition(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }

  /**
   * this function is to delete positions by Id
   * @function deletePositionById
   ** @param {reqId} default parameter
   */

  async deletePositionById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}`;
    const response = await this.fetcher.deleteData(url, reqParams.reqBody);
    return response;
  }
}

export const createPositions = () => Positions.getInstance();
