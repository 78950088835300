import { Children, cloneElement, useState } from 'react';

import { useUsersGetQuery } from '../../../Common/Queries/usersQueries';

export function InterviewerFieldContainer({ children, ...props }) {
  const [search, setSearch] = useState('');
  const interviewerList = useUsersGetQuery(
    { q: search },
    { enabled: search.length >= 2 } // enable query only if user search something
  );

  /**
   * function to handle search data for Interviewer
   * @param {String} value search input
   */
  const handleSearch = (value) => {
    setSearch(value);
  };

  return Children.only(cloneElement(children, { interviewerList, handleSearch, ...props }));
}
