import { Children, cloneElement } from 'react';

import { useDashboardGetQuery } from '../../Common/Queries/dashboardQueries';

export const DashboardDataContainer = ({ children }) => {
  const dashboard = useDashboardGetQuery();
  const child = Children.only(children);

  return cloneElement(child, { dashboard });
};
