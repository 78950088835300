import { Children, cloneElement, useEffect, useState } from 'react';

import { useUpdateAgencyQueryById } from '../../Common/Queries/agenciesQueries';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return {
    ...values,
    name: values.name,
    email: values.email,
    phone: `+91${values.phone}`,
    type: values.type,
    website: values.website,
  };
};

export function EditAgencyContainer(props) {
  const putToAgency = useUpdateAgencyQueryById();

  const { formData } = props;
  const [values, setValues] = useState({ ...formData, phone: formData.phone?.slice(3) });

  useEffect(() => {
    setValues({ ...formData, phone: formData.phone?.slice(3) });
  }, [formData]);

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   */
  function handleOnSubmit(e, body) {
    e.preventDefault();
    const reqBody = formateData(body);
    const reqParams = { reqBody, reqId: props.agencyId };
    putToAgency.mutate(reqParams);
  }

  // for closing pop over after adding position
  useEffect(() => {
    if (putToAgency.isSuccess) {
      props.setOpen(false);
    }
  }, [putToAgency.isSuccess]);

  const children = Children.only(props.children);
  return cloneElement(children, { values, putToAgency, handleOnSubmit });
}
