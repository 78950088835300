import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Badge, IconButton, InputAdornment } from '@mui/material';

import useWindowSize from '../../Common/Hook/useWindowSize';

import { MOBILE_VIEW_WIDTH } from '../../constants';

import CustomPopover from '../CustomPopover';
import { InputText } from '../Input';

import SearchMobileView from './searchMobileView';

export default function Search({ filterValues, setFilterValues, placeholder = 'Search', setPage }) {
  const windowSize = useWindowSize();
  const isMobileScreen = windowSize.width <= MOBILE_VIEW_WIDTH;
  const [anchorEl, setAnchorEl] = useState(null);

  const [values, setValues] = useState(filterValues);
  useEffect(() => {
    setValues(filterValues);
  }, [filterValues]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    const searchValue = event.target.value.replace('#', '');
    setValues((prevValues) => {
      return { ...prevValues, searchValue };
    });
    if (filterValues?.searchValue?.length > 0 && searchValue.length === 0) {
      setFilterValues({ ...values, searchValue: '' });
    }
  };

  const handelSearch = () => {
    if (
      values?.searchValue?.trim().length >= 2 ||
      (!values?.searchValue?.length && filterValues?.searchValue?.length > 0)
    ) {
      if (setPage) setPage(0);
      setFilterValues({ ...values, searchValue: values?.searchValue?.trim() });
    }
  };

  const handleKeyPress = (event) => {
    if (
      event.key === 'Enter' &&
      (values?.searchValue?.trim().length >= 2 ||
        (!values?.searchValue?.length && filterValues?.searchValue?.length > 0))
    ) {
      if (setPage) setPage(0);
      setFilterValues({ ...values, searchValue: values?.searchValue?.trim() });
    }
  };
  return (
    <>
      {isMobileScreen ? (
        <>
          <IconButton
            onClick={handleOpen}
            data-testid="testSearchLens"
            className="global-search-icon"
          >
            <Badge variant="dot" color="secondary" invisible={!values.searchValue}>
              <SearchIcon />
            </Badge>
          </IconButton>

          <CustomPopover
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            sx={{ '& .MuiPopover-paper': { width: '90%' } }}
          >
            <SearchMobileView
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              placeholder={`${placeholder} (min 2 characters)`}
            />
          </CustomPopover>
        </>
      ) : (
        <InputText
          placeholder={`${placeholder} (min 2 characters)`}
          value={values.searchValue}
          onChange={handleChange}
          name="candidate-name"
          id="name"
          sx={{ width: '100%', backgroundColor: 'white' }}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handelSearch}
                  data-testid="testSearchLens"
                  className="global-search-icon"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
}
