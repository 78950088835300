// @ts-check

/**
 *@function queryBuilder
 * @param {object} queryObject
 * @returns queryObject in a string
 */
export const queryBuilder = (queryObject) => {
  const queryString = Object.keys(queryObject || {}).reduce((accum, key) => {
    if (typeof queryObject[key] === 'number' && queryObject[key] > -1)
      return `${accum}&${key}=${queryObject[key]}`;
    if (queryObject[key]) return `${accum}&${key}=${queryObject[key]}`;
    return accum;
  }, '');

  if (queryString.length) return `?${queryString.slice(1)}`;
  return queryString;
};
