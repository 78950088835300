import { Children, cloneElement, useEffect } from 'react';

import { useAddSkillQuery } from '../../Common/Queries/skillQueries';
/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => ({ name: values.name });

export const initialValues = { name: '' };

export function AddSkillContainer({ setOpen, children }) {
  const postToSkill = useAddSkillQuery();

  function handleSubmitForAddSkill(e, values) {
    e.preventDefault();
    const reqBody = formateData(values);
    postToSkill.mutate(reqBody);
  }

  useEffect(() => {
    if (postToSkill.isSuccess) setOpen(false);
  }, [postToSkill.isSuccess]);

  return Children.only(
    cloneElement(children, { postToSkill, handleSubmitForAddSkill, initialValues })
  );
}
