import React from 'react';
import { Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export default function SimpleLoader({ size = 34, ...props }) {
  return (
    <Box
      sx={{ position: 'relative', height: size, marginTop: '2px' }}
      data-testid="testSimpleLoader"
    >
      <CircularProgress
        variant="determinate"
        sx={{ color: (theme) => theme.palette.grey[200] }}
        size={size}
        thickness={3}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#52bbab',
          animationDuration: '1500ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        thickness={3}
        {...props}
      />
    </Box>
  );
}
