import { Children, cloneElement } from 'react';

import { useInterviewsPutQueryById } from '../../../Common/Queries/interviewsQueries';

/**
 * this function is utility function for formate the data in required formate
 * @param {*} values remaining values
 * @param {*} editedValue edited values
 * @returns values in required formate
 */
export const formateData = (values, editedValue) => {
  return {
    application: values.application,
    round: values.round,
    status: values.status,
    versionKey: values.versionKey,
    message: values.message || '',
    customLink: values.customLink || '',
    interviewer: values.interviewer,
    timeZone: 'Asia/Kolkata',
    startTime: Math.floor(new Date(editedValue.time) / 1000),
    text: editedValue.reason,
    duration: editedValue.duration,
    location: values.location || 'ONLINE',
    ...(editedValue?.isUpdateStartTime && { isUpdateStartTime: editedValue.isUpdateStartTime }),
  };
};

export function RescheduleContainer({ children, interview, id }) {
  const putToInterview = useInterviewsPutQueryById();

  const handleReschedule = (finalValues) => {
    const reqBody = formateData(interview, finalValues);
    const reqParams = { reqBody, reqId: id };
    putToInterview.mutate(reqParams);
  };

  return Children.only(cloneElement(children, { handleReschedule, putToInterview, interview }));
}
