import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import { useReferralsGetQuery } from '../../Common/Queries/referralsQueries';

import { isValidID } from '../../utils';

import { AuthContext, ROUND_RESPONSE } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = [
    'status',
    'positionID',
    'searchValue',
    'page',
    'order',
    'orderBy',
    'rounds',
    'roundStatus',
  ];
  return keyArray.every((key) => {
    if (key === keyArray[0] || key === keyArray[1]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[2]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[3]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[4]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return (
      filterObject[key] === '' ||
      filterObject[key] === 'name' ||
      filterObject[key] === 'positionTitle' ||
      filterObject[key] === 'createdAt'
    );
  });
};

const initialValues = {
  status: [],
  order: 'desc',
  orderBy: 'createdAt',
  searchValue: '',
  page: 0,
  positionID: [],
  roundStatus: [],
  rounds: [],
};

export const ReferralListContainer = ({ children }) => {
  const { userInfo } = useContext(AuthContext);
  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-referral`);
  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);
  const urlQuery = useURLQuery();
  const initialStatus = urlQuery.get('status');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialSearch = urlQuery.get('search');
  const initialPositionID = isValidID(urlQuery.get('positionID')) && urlQuery.get('positionID');

  const initialFilterValuesToApply = {
    ...initialValues,
    page: initialPage ? Number(initialPage - 1) : 0,
    ...(!filterValuesObj || !validateFilterObject(filterValuesObj)
      ? {
          status: initialStatus ? initialStatus.split(',') : [],
          positionID: initialPositionID
            ? initialPositionID.split(',').map((item) => Number(item))
            : [],
          searchValue: initialSearch || '',
        }
      : {
          status: initialStatus ? initialStatus.split(',') : filterValuesObj.status,
          positionID: initialPositionID
            ? initialPositionID.split(',').map((item) => Number(item))
            : filterValuesObj.positionID,
          searchValue: initialSearch || filterValuesObj.searchValue,
        }),
  };

  const [filters, updateFilters] = useState(() => initialFilterValuesToApply);

  const referrals = useReferralsGetQuery({
    interviewRound: filters.rounds.map((item) => ROUND_RESPONSE[item] || item).join(','),
    positionID: filters.positionID.join(','),
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    updatedStatus: filters.status.join(','),
    q: filters.searchValue,
    limit: 20,
  });

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, { referrals, filterHandler: handleOnFilterChange, filters });
};
