import { Children, cloneElement, useEffect } from 'react';

import { usePositionsPutQueryById } from '../../Common/Queries/positionsQueries';

import { htmlToMD } from '../../utils';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return {
    description: htmlToMD(values.description),
    location: values.location,
    title: values.title,
    status: values.status,
    interviewer: values.interviewer,
    hr: values.hr,
    skill: values.skill,
    stream: values.stream,
    level: values.level,
    isCampus: values.isCampus,
    isPublish: values.isPublish,
    offlineInviteTemplate: values.offlineInviteTemplate,
    onlineInviteTemplate: values.onlineInviteTemplate,
    endDate: values.endDate,
    versionKey: values.versionKey,
    skipRounds: values.skipRounds,
    wordCountLimit: values.wordCountLimit,
  };
};

export function EditPositionContainer({ positionData, setOpen, children, ...props }) {
  const putToPositions = usePositionsPutQueryById();

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   */
  function handleSubmitForEditPosition(finalValues) {
    const reqBody = formateData(finalValues);

    const reqParams = { reqBody, reqId: positionData?.id };
    putToPositions.mutate(reqParams);
  }

  // for closing pop over after adding position
  useEffect(() => {
    if (putToPositions.isSuccess) {
      setOpen(false);
    }
  }, [putToPositions.isSuccess]);

  return Children.only(
    cloneElement(children, {
      initialValues: positionData || {},
      putToPositions,
      handleSubmitForEditPosition,
      ...props,
    })
  );
}
