import { Children, cloneElement, useState } from 'react';

import { useApplicationsGetQuery } from '../../Common/Queries/applicationsQueries';

import { ALL_DATA_LIMIT } from '../../constants';

export const PreScreeningListContainer = ({ children }) => {
  const [source, setSource] = useState();
  const screeningApplication = useApplicationsGetQuery({
    view: 'PRE_SCREENING',
    limit: ALL_DATA_LIMIT,
    uploadedBy: source,
  });
  const child = Children.only(children);

  return cloneElement(child, { screeningApplication, setSource });
};
