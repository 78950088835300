import { Children, cloneElement } from 'react';

import {
  useInterviewsGetQueryById,
  useInterviewsPutQueryById,
} from '../../../../Common/Queries/interviewsQueries';

export const FeedbackEditorContainer = ({ children, ...props }) => {
  const interview = useInterviewsGetQueryById(props.id, {});
  const putToInterview = useInterviewsPutQueryById();
  const child = Children.only(children);

  return cloneElement(child, { interview, putToInterview, ...props });
};
