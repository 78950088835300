import { useMutation, useQuery } from '@tanstack/react-query';

import { createServices } from '../../Repositories';

const serviceRequest = createServices();

/**
 * Will post services which are required and it
 * @returns Google service links which are used to configure
 */
export function useServicePostQuery() {
  return useMutation(['useServicePostQuery'], (args) =>
    serviceRequest.postGenerateAuthTokens(args)
  );
}

/**
 *
 * @returns Refresh Token for the services which are configured
 */
export function useServiceGetRefreshTokensQuery() {
  return useQuery(['useServiceGetQuery'], () => serviceRequest.getServiceRefreshTokens(), {
    enabled: false,
  });
}

export function useServicePutRefreshTokensQuery() {
  return useMutation(['useServicePutQuery'], (args) => serviceRequest.putGenerateAuthTokens(args));
}
