import { Children, cloneElement, useEffect } from 'react';

import { useAddStreamQuery } from '../../Common/Queries/streamQueries';
/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return {
    name: values.name,
    department: values.department,
    skills: values.skills,
    levels: values.levels,
    manager: values.manager,
    webHookURL: values.webHookURL,
  };
};

export const initialValues = {
  name: '',
  department: {},
  skills: [],
  levels: [],
  manager: {},
};

export function AddStreamContainer({ setOpen, children }) {
  const postToStream = useAddStreamQuery();

  function handleSubmitForAddStream(e, values) {
    e.preventDefault();
    const reqBody = formateData(values);
    postToStream.mutate(reqBody);
  }

  useEffect(() => {
    if (postToStream.isSuccess) setOpen(false);
  }, [postToStream.isSuccess]);

  return Children.only(
    cloneElement(children, { postToStream, handleSubmitForAddStream, initialValues })
  );
}
