import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createConfig } from '../../Repositories';

const configRequest = createConfig();

/**
 * this function(query) is to get all config
 * @function useConfigGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useConfigGetQuery(reqParams, options) {
  return useQuery(['configGetQuery', reqParams], () => configRequest.getAllConfig(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}
/**
 * this function(query) is to update config
 * @function useConfigPutQuery
 * @returns api response
 */
export function useConfigPutQuery() {
  const queryClient = useQueryClient();
  return useMutation(['configPutQuery'], (args) => configRequest.putConfig(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['configGetQuery']);
    },
  });
}

export function useConfigPostQuery(options) {
  return useMutation(['postConfigQuery'], (reqPayload) => configRequest.postConfig(reqPayload), {
    ...options,
  });
}
