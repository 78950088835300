import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

import './index.css';

const Span = styled('span')({ paddingLeft: '1rem' });

export function CustomSelect({
  options = [],
  defaultValue,
  labelFor,
  onChangeHandler,
  isRequired,
  fieldName,
  labelText,
  className,
  isLoading = false,
  getTitle = (value) => value.title,
}) {
  const handleOnChange = (e) => {
    if (typeof onChangeHandler === 'function') {
      onChangeHandler(e.target.value);
    }
  };

  return (
    <>
      <span className="label">
        {labelText}
        {isRequired && labelFor && <em className="requiredInput"> *</em>}
      </span>
      <Select
        value={defaultValue || ''}
        required={!!isRequired}
        size="small"
        id={labelFor}
        className={className ?? 'inputText'}
        defaultValue={defaultValue}
        variant="outlined"
        inputProps={{ 'data-testid': 'testSelect' }}
        sx={{
          '&:hover': {
            '&& fieldset': {
              border: '1px solid #F3F7F7',
            },
          },
        }}
        onChange={handleOnChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: '30rem' } } }}
      >
        {isLoading && <Span>Loading...</Span>}
        {!isLoading && <MenuItem value="" disabled={isRequired}>{`Select ${fieldName}`}</MenuItem>}
        {!isLoading &&
          (options || []).map((option) => (
            <MenuItem key={option.id || option} value={option.id || option}>
              {getTitle(option)}
            </MenuItem>
          ))}
      </Select>
    </>
  );
}
