import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import SimpleLoader from '../Loader/SimpleLoader';

const Div = styled('div')({ padding: '10px', flexGrow: 1, maxHeight: '500px', overflow: 'auto' });

export default function NotificationContent({ notifications, handleClose }) {
  return (
    <Paper sx={{ maxWidth: '600px' }}>
      {notifications.isLoading && <SimpleLoader />}
      {notifications.isSuccess && (
        <Div>
          <Grid container spacing={3}>
            {notifications.data.length === 0 && (
              <Grid item xs={12}>
                <Alert
                  icon=" "
                  severity="info"
                  sx={{
                    fontFamily: 'Helvetica Neue,HelveticaNeue,Helvetica',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '1rem',
                  }}
                >
                  No Pending Task!
                </Alert>
              </Grid>
            )}
            {notifications.data.map((notification) => (
              <Grid item xs={12} key={notification.id}>
                <Link
                  to={notification.link}
                  className="hiring-motion_link"
                  onClick={() => handleClose()}
                >
                  <Alert severity="info">{notification.message}</Alert>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Div>
      )}
    </Paper>
  );
}
