import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, REFERRALS_ENDPOINT } from '../../constants';

export class Referrals {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Referrals(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = REFERRALS_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getAllReferrals(req) {
    const queryString = queryBuilder(req);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }

  async postReferral(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }
}

export const createReferrals = () => Referrals.getInstance();
