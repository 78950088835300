import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, FILTERS_ENDPOINT } from '../../constants';

export class Filters {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Filters(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = FILTERS_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getAllFilteredData(req) {
    const queryString = queryBuilder(req);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
}
export const createFilters = () => Filters.getInstance();
