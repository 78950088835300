import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, REPORTS_ENDPOINT } from '../../constants';

export class Reports {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Reports(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = REPORTS_ENDPOINT;
    this.fetcher = fetcher;
  }
  /**
   * this function is to get Reports
   * @function getReports
   ** @param {reqParam} default parameter
   */

  async getReports(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
}

export const createReports = () => Reports.getInstance();
