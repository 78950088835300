import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DURATION_TIME } from '../../constants';

import { createTasks } from '../../Repositories';

const taskReq = createTasks();

/**
 * this function(query) is to get All Tasks
 * @function useTasksGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useTasksGetQuery(reqParams, options) {
  return useQuery(['taskGetQuery', reqParams], () => taskReq.getAllTasks(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    refetchInterval: DURATION_TIME,
    refetchIntervalInBackground: true,
    ...options,
  });
}

/**
 * this function(query) is to put task by Id
 * @function useTaskPutQueryById
 * @returns api response
 */
export function useTaskPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['taskPutQueryById'], (args) => taskReq.putTaskById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['interviewsGetQueryById']);
      queryClient.invalidateQueries(['taskGetQuery']);
    },
  });
}
