import { Children, cloneElement, useEffect } from 'react';

import { useApplicationsPutQueryById } from '../../Common/Queries/applicationsQueries';

import { htmlToMD, IntlFormatedDate } from '../../utils';

export const formateData = (values, customNotice) => {
  return {
    ...values,
    reason: htmlToMD(values?.reason),
    phone: `+91${values.phone}`,
    ...((customNotice === 'Immediate' || customNotice === 'Serving') && {
      noticePeriod: `${customNotice} (${IntlFormatedDate(values.noticePeriod)})`,
    }),
    links: values.links?.filter((link) => link.type !== '' && link.value !== ''),
    isUpload: values?.isUpload,
  };
};

export function EditApplicationContainer(props) {
  const { applicationData: data, applicationId: id, setOpen, children } = props;
  const putToApplication = useApplicationsPutQueryById();

  const handleSubmit = (finalValues, customNotice) => {
    const reqBody = formateData(finalValues, customNotice);
    const reqParams = { reqBody, reqId: id };
    putToApplication.mutate(reqParams);
  };

  useEffect(() => {
    if (putToApplication.isSuccess) {
      setOpen(false);
    }
  }, [putToApplication.isSuccess]);

  return Children.only(cloneElement(children, { data, putToApplication, handleSubmit }));
}
