import { useQuery } from '@tanstack/react-query';

import { createHRReports } from '../../Repositories';

const hrReportReq = createHRReports();

/**
 * this function(query) is to get All HR Reports
 * @function useHRReportsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useHRReportsGetQuery(reqParams, options) {
  return useQuery(['hrReportsGetQuery', reqParams], () => hrReportReq.getAllHRReports(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}
