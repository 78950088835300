import { useQuery } from '@tanstack/react-query';

import { createFilters } from '../../Repositories';

const filterReq = createFilters();

/**
 * this function(query) is to get All filters data
 * @function useFiltersGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useFiltersGetQuery(reqParams, options) {
  return useQuery(['filtersGetQuery', reqParams], () => filterReq.getAllFilteredData(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}
