// @ts-check

/**
 * @function isValidMobileNumber
 * @param {string} mobileNumber
 * @returns whether all the mobile numbers are valid or not
 */

export function isValidMobileNumber(mobileNumber) {
  // if you are changing the regex please reach out to backend team so that both have
  // same regex and validation criteria is synced across the system
  // const mobileNumberRegexString =
  //     '^[+][1-9][0-9]?[0-9]?[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$'

  // TODO: Remove this validation after supporting the country code check on ui
  const mobileRegexStringWithoutCountryCode =
    '^[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$';
  // const mobileNumberRegex = new RegExp(mobileNumberRegexString)
  const mobileNumberRegexWithoutCountryCode = new RegExp(mobileRegexStringWithoutCountryCode);
  return (
    // mobileNumberRegex.test(mobileNumber) ||
    mobileNumberRegexWithoutCountryCode.test(mobileNumber)
  );
}
