import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { debounce } from '../../utils';

import './style.css';

export default function SearchWithGroupItem({
  options = [],
  isLoading,
  setState,
  initialState,
  property,
  isObject,
  setSearchValue,
  suggestionText,
  placeholder = 'Select',
  required,
  error,
  helperText,
}) {
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    setValue(initialState);
  }, [initialState]);

  const handleInputValueChange = (event) => {
    if (setSearchValue) {
      const typedValue = event.target.value;
      if (typedValue.trim().length >= 2) {
        setSearchValue(typedValue.trim());
      }
    }
  };

  const handleSearch = (_e, val) => {
    if (isObject) {
      if (val && val[property]) {
        setValue({ id: val.id, [property]: val[property] });
        setState({ id: val.id, [property]: val[property] });
      } else {
        setValue({ id: 0, [property]: '' });
        setState({ id: 0, [property]: '' });
      }
    } else if (val && val[property]) {
      setValue(val[property]);
      setState(val[property]);
    } else {
      setValue('');
      setState('');
    }
  };

  const isNotClearable =
    typeof value === 'string' ? value.length === 0 : value.id === undefined || value.id === 0;

  // Doing Sort based on position title as well as stream name
  const finalSortedOptions = options
    .sort((a, b) => a.title.localeCompare(b.title))
    .sort((a, b) => a.stream.name.localeCompare(b.stream.name))
    .sort((a, b) => a.status.localeCompare(b.status));

  return (
    <Autocomplete
      loading={isLoading}
      value={value}
      disableClearable={isNotClearable}
      name="search-suggestions"
      fullWidth
      sx={{ margin: '5px 0px 0px', backgroundColor: '#fff' }}
      data-testid="testSearch"
      onChange={handleSearch}
      options={finalSortedOptions}
      groupBy={(option) => `${option.stream.name} - ${option.status}`}
      isOptionEqualToValue={(option, val) => {
        if (typeof val === 'string') return option[property] === val;
        return option.id === val.id;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option[property] ? option[property] : '';
      }}
      noOptionsText={suggestionText || 'No Options'}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          name="search-suggestions"
          fullWidth
          sx={{ margin: '0px' }}
          margin="normal"
          variant="outlined"
          onChange={debounce((e) => handleInputValueChange(e))}
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}
