import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, PANEL_REPORTS_ENDPOINT } from '../../constants';

export class PanelReports {
  static getInstance() {
    if (!this.instance) {
      this.instance = new PanelReports(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = PANEL_REPORTS_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getAllPanelReports(req) {
    const queryString = queryBuilder(req);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }
}

export const createPanelReports = () => PanelReports.getInstance();
