import { Children, cloneElement } from 'react';

import { useApplicationsGetQueryById } from '../../Common/Queries/applicationsQueries';

export const EventsContainer = ({ children, id }) => {
  const applicationData = useApplicationsGetQueryById(id, {});
  const child = Children.only(children);

  return cloneElement(child, { applicationData });
};
