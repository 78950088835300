// @ts-check

import { isHttpError } from '../Common';

import { UPSTREAM_INVALID_TOKEN_MESSAGE } from '../constants';

/**
 * @function isInvalidTokenError
 * @param {Error} error
 * @returns Invalid Token Message
 */

export const isInvalidTokenError = (error) => {
  return isHttpError(error) && error.message === UPSTREAM_INVALID_TOKEN_MESSAGE;
};

/**
 * @function isPresent
 * @param {*} thing
 * @returns whether thing is not null and not undefined
 */

export const isPresent = (thing) => thing !== null && thing !== undefined;

/**
 * @function filterErrorWithCustomFilter
 * @param {*} errorFilter
 * @returns
 */

export const filterErrorWithCustomFilter = (errorFilter) => (/** @type {any[]} */ errors) => {
  if ((!Array.isArray(errors) || errors.length === 0) && errors !== undefined) {
    return undefined;
  }

  /*
      Handling invalid token error early to redirect user to login page
    */
  const invalidTokenError = errors?.filter(isInvalidTokenError);
  if (invalidTokenError?.length) return invalidTokenError[0];

  if (typeof errorFilter !== 'function') return undefined;

  const filteredError = errors?.filter(errorFilter);

  if (filteredError?.length) return filteredError[0];
  return undefined;
};

/**
 * @function filter4xxError
 * @returns error message
 */

export const filter4xxError = filterErrorWithCustomFilter(
  (/** @type {{ status: number; message: String; }} */ error) =>
    isPresent(error) && error.status < 500 && isPresent(error.message)
);
/**
 * @function filter4xxAnd503Error
 * @returns error message
 */

export const filter4xxAnd503Error = filterErrorWithCustomFilter(
  (/** @type {{ status: number; message: String; }} */ error) =>
    isPresent(error) && (error.status < 500 || error.status === 503) && isPresent(error.message)
);
