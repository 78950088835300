import { Children, cloneElement, useContext, useEffect, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import { useApplicationsGetQuery } from '../../Common/Queries/applicationsQueries';

import { isValidDate, isValidID } from '../../utils';

import { AppContext, AuthContext, ROUND_RESPONSE } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = [
    'status',
    'positionID',
    'recruiterID',
    'agencyIDs',
    'noticePeriod',
    'roundStatus',
    'rounds',
    'streams',
    'searchValue',
    'page',
    'order',
    'orderBy',
    'createdAt',
    'updatedAt',
    'IDs',
  ];
  return keyArray.every((key) => {
    if (
      key === keyArray[0] ||
      key === keyArray[1] ||
      key === keyArray[2] ||
      key === keyArray[3] ||
      key === keyArray[4] ||
      key === keyArray[5] ||
      key === keyArray[6]
    ) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[7]) {
      return (
        typeof filterObject[key] === 'string' ||
        (filterObject[key] !== undefined &&
          typeof filterObject[key] === 'number' &&
          filterObject[key] > 0)
      );
    }
    if (key === keyArray[8]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[9]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[10]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    if (key === keyArray[11]) {
      return (
        filterObject[key] === '' ||
        filterObject[key] === 'name' ||
        filterObject[key] === 'positionTitle' ||
        filterObject[key] === 'updatedAt' ||
        filterObject[key] === 'createdAt'
      );
    }
    if (key === keyArray[14]) {
      return filterObject[key] === '';
    }
    return (
      typeof filterObject[key] === 'object' &&
      Object.keys(filterObject[key]).length === 3 &&
      Object.keys(filterObject[key]).every((k) => {
        if (k === 'fromDate' || k === 'toDate')
          return filterObject[key][k] === '' || isValidDate(filterObject[key][k]);
        if (k === 'includeDate') return typeof filterObject[key][k] === 'boolean';
        return false;
      })
    );
  });
};

const initialValues = {
  status: [],
  streams: '',
  recruiterID: [],
  agencyIDs: [],
  noticePeriod: [],
  roundStatus: [],
  rounds: [],
  order: '',
  orderBy: '',
  searchValue: '',
  page: 0,
  positionID: [],
  IDs: '',
};

export const ApplicationListContainer = ({ children }) => {
  const { appData } = useContext(AppContext);
  const { userInfo } = useContext(AuthContext);

  const urlQuery = useURLQuery();
  const positionData = appData.POSITION_DATA || [];
  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-application`);
  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);

  const initialPositionID = isValidID(urlQuery.get('positionID')) && urlQuery.get('positionID');
  const initialStreamID = isValidID(urlQuery.get('streamIDs')) && urlQuery.get('streamIDs');
  const initialRecruiterID = isValidID(urlQuery.get('recruiterID')) && urlQuery.get('recruiterID');
  const initialAgencyId = isValidID(urlQuery.get('agencyIDs')) && urlQuery.get('agencyIDs');

  let selectedPositionIDs = positionData
    .filter((position) => position.stream.id === Number(initialStreamID))
    .map((position) => {
      return position.id;
    });

  if (selectedPositionIDs.length === 0 && filterValuesObj && validateFilterObject(filterValuesObj))
    selectedPositionIDs = filterValuesObj.positionID;

  const initialStatus = urlQuery.get('status');
  const initialRound = urlQuery.get('rounds');
  const initialRoundStatus = urlQuery.get('roundStatus');
  const initialNoticePeriod = urlQuery.get('noticePeriod');
  const initialSearch = urlQuery.get('searchParam');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialIDs = urlQuery.get('IDs');

  const initialFilterValuesToApply = {
    ...initialValues,
    order: 'desc',
    orderBy: 'updatedAt',
    page: initialPage ? Number(initialPage - 1) : 0,
    searchValue: initialSearch || '',
    positionID: initialPositionID
      ? initialPositionID.split(',').map((item) => Number(item))
      : selectedPositionIDs,
    ...(filterValuesObj &&
    validateFilterObject(filterValuesObj) &&
    !initialPositionID &&
    !initialStreamID &&
    !initialStatus &&
    !initialRound &&
    !initialRoundStatus &&
    !initialRecruiterID &&
    !initialNoticePeriod &&
    !initialAgencyId &&
    !initialSearch
      ? {
          status: filterValuesObj.status,
          createdAt: filterValuesObj.createdAt,
          updatedAt: filterValuesObj.updatedAt,
          streams: filterValuesObj.streams,
          recruiterID: filterValuesObj.recruiterID,
          agencyIDs: filterValuesObj.agencyIDs,
          noticePeriod: filterValuesObj.noticePeriod,
          rounds: filterValuesObj.rounds,
          roundStatus: filterValuesObj.roundStatus,
        }
      : {
          status: initialStatus ? initialStatus.split(',') : [],
          createdAt: { fromDate: '', toDate: '', includeDate: true },
          updatedAt: { fromDate: '', toDate: '', includeDate: true },
          streams: initialStreamID || '',
          recruiterID: initialRecruiterID
            ? initialRecruiterID.split(',').map((item) => Number(item))
            : [],
          agencyIDs: initialAgencyId ? initialAgencyId.split(',').map((item) => Number(item)) : [],
          noticePeriod: initialNoticePeriod ? initialNoticePeriod.split(',') : [],
          rounds: initialRound ? initialRound.split(',') : [],
          roundStatus: initialRoundStatus ? initialRoundStatus.split(',') : [],
        }),
  };
  const [filters, updateFilters] = useState(initialFilterValuesToApply);
  const [allData, setAllData] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [page, setPage] = useState(1);
  const getQueryParameters = {
    'createdAtRange.fromDate': filters.createdAt?.fromDate || '',
    'createdAtRange.toDate': filters.createdAt?.toDate || '',
    'createdAtRange.includeDate': filters.createdAt?.includeDate,
    'updatedAtRange.fromDate': filters.updatedAt?.fromDate || '',
    'updatedAtRange.toDate': filters.updatedAt?.toDate || '',
    'updatedAtRange.includeDate': filters.updatedAt?.includeDate,
    positionID: filters.positionID.join(','),
    streamIDs: filters.streams,
    recruiterID: filters.recruiterID.join(','),
    agencyIDs: filters.agencyIDs.join(','),
    noticePeriod: filters.noticePeriod.join(','),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    updatedStatus: filters.status.join(','),
    roundStatus: filters.roundStatus.join(','),
    interviewRound: filters.rounds.map((item) => ROUND_RESPONSE[item] || item).join(','),
    q: filters.searchValue,
    IDs: initialIDs,
  };

  const applications = useApplicationsGetQuery({
    ...getQueryParameters,
    page: Number(filters.page + 1),
    limit: 20,
  });

  const applicationsAllData = useApplicationsGetQuery(
    { ...getQueryParameters, limit: 100, page },
    { enabled }
  );

  useEffect(() => {
    if (applicationsAllData.isSuccess && enabled)
      setAllData([...allData, ...(applicationsAllData.data?.data || [])]);
    if (applicationsAllData.data?.meta?.total > page * 100) setPage(page + 1);
  }, [applicationsAllData.isSuccess]);

  useEffect(() => {
    if (allData.length > 0) {
      setAllData([]);
      setPage(1);
    }
  }, [filters]);

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, {
    applications,
    filterHandler: handleOnFilterChange,
    filters,
    enabled,
    setEnabled,
    allData,
    applicationsAllData,
  });
};
