import { Children, cloneElement, useEffect } from 'react';

import { useUpdateMasterInviteTemplateQuery } from '../../../Common/Queries/masterInviteTemplateQueries';

export const formateData = (values, isOnline) => {
  return {
    ...values,
    ...(isOnline
      ? { onlineInviteTemplate: values.onlineInviteTemplate }
      : { offlineInviteTemplate: values.offlineInviteTemplate }),
  };
};

export function EditMasterInviteTemplateContainer({ setEdit, isOnline, ...props }) {
  const putToMasterInviteTemplate = useUpdateMasterInviteTemplateQuery();

  function handleOnSubmit(body) {
    const reqParams = formateData(body, isOnline);
    putToMasterInviteTemplate.mutate(reqParams);
  }

  useEffect(() => {
    if (putToMasterInviteTemplate.isSuccess)
      setEdit(isOnline ? 'onlineEdit' : 'offlineEdit', false);
  }, [putToMasterInviteTemplate.isSuccess]);

  const children = Children.only(props.children);
  return cloneElement(children, {
    putToMasterInviteTemplate,
    handleOnSubmit,
    isOnline,
    setEdit,
    ...props,
  });
}
