import { useMutation } from '@tanstack/react-query';

import { createOrg } from '../../Repositories';

const orgReq = createOrg();

/**
 * this function(query) is to post refresh
 * @function usePostRefreshQuery
 * @returns api response
 */
export function usePostOrganizationQuery() {
  return useMutation(['orgPostQuery'], (args) => orgReq.postOrganization(args), {
    retry: false,
  });
}

export function useRefreshCall(options) {
  return useMutation((args) => orgReq.postRefreshCall(args), { ...options });
}
