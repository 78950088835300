import React, { Children, cloneElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import { usePositionsKanbanGetQueryById } from '../../Common/Queries/positionsQueries';

import { isValidID } from '../../utils';

import { MY_OPEN_POSITIONS_ENDPOINT } from '../../constants';

export function ApplicationStatsContainer({ children }) {
  const child = Children.only(children);
  const urlQuery = useURLQuery();
  const positionID = isValidID(urlQuery.get('positionID')) && urlQuery.get('positionID');
  // If positionID is not present redirecting to My Open Positions page
  if (!positionID) {
    return <Navigate to={MY_OPEN_POSITIONS_ENDPOINT} />;
  }
  const applications = usePositionsKanbanGetQueryById({ reqId: positionID });

  return cloneElement(child, { applications });
}
