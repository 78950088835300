import { ACTIONS } from './constant';

export function formateObject(name, value, i) {
  if (name === 'position') {
    return { type: ACTIONS.VALIDATE_POSITION, payload: { value } };
  }
  if (name === 'agency') {
    return { type: ACTIONS.VALIDATE_AGENCY, payload: { value } };
  }
  if (name === 'name') {
    return { type: ACTIONS.VALIDATE_NAME, payload: { value } };
  }
  if (name === 'email') {
    return { type: ACTIONS.VALIDATE_EMAIL, payload: { value } };
  }
  if (name === 'phone-number') {
    return { type: ACTIONS.VALIDATE_PHONE, payload: { value } };
  }
  if (name === 'link-type') {
    return { type: ACTIONS.VALIDATE_LINK_TYPE, payload: { value, index: i, type: 'select' } };
  }
  if (name === 'link-type-field') {
    return { type: ACTIONS.VALIDATE_LINK_TYPE, payload: { value, index: i, type: 'field' } };
  }
  if (name === 'link-value') {
    return { type: ACTIONS.VALIDATE_LINK, payload: { value, index: i } };
  }
  if (name === 'skill-name') {
    return { type: ACTIONS.VALIDATE_SKILL, payload: { value, index: i } };
  }
  if (name === 'skill-level') {
    return { type: ACTIONS.VALIDATE_SKILL_LEVEL, payload: { value, index: i } };
  }
  if (name === 'notice-period') {
    return { type: ACTIONS.NOTICE_PERIOD, payload: { value } };
  }
  if (name === 'current-salary') {
    return { type: ACTIONS.CURRENT_SALARY, payload: { value: Number(value) } };
  }
  if (name === 'expected-salary') {
    return { type: ACTIONS.EXPECTED_SALARY, payload: { value: Number(value) } };
  }
  if (name === 'current-location') {
    return { type: ACTIONS.CURRENT_LOCATION, payload: { value } };
  }
  if (name === 'is-upload') {
    return { type: ACTIONS.IS_UPLOAD, payload: { value } };
  }
  return {};
}
