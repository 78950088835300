import { Children, cloneElement } from 'react';

import { useApplicantPutQueryById } from '../../Common/Queries/applicantQueries';

const formatReqData = (values) => {
  const formattedPhone = values.phone.startsWith('+91') ? values.phone : `+91${values.phone}`;
  return {
    phone: formattedPhone,
    email: values.email,
    name: values.name,
    status: values.status,
    createdBy: values.createdBy,
    links: values.links,
  };
};
export function EditApplicantContainer(props) {
  const { data, children } = props;
  const putToApplicant = useApplicantPutQueryById();

  const handleSubmit = (finalValues) => {
    const reqBody = formatReqData(finalValues);
    const reqParams = { reqBody, reqId: finalValues?.id };
    putToApplicant.mutate(reqParams);
  };

  return Children.only(cloneElement(children, { data, putToApplicant, handleSubmit }));
}
