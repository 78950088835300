import { Children, cloneElement, useState } from 'react';

import { useApplicationsPutQueryById } from '../../Common/Queries/applicationsQueries';
import {
  useInterviewsGetQueryById,
  useInterviewsPutQueryById,
} from '../../Common/Queries/interviewsQueries';

export const formateData = (value, reason, status, teamID) => {
  if (reason.replace(/(<([^>]+)>)|\n|&nbsp;/gi, '') === '') {
    return { ...value, reason: '', status, teamID };
  }
  return { ...value, reason, status, teamID };
};

export const formateInterviewData = (data, text, status, mentionedUsers = []) => {
  let message = '';
  if (text.replace(/(<([^>]+)>)|\n|&nbsp;/gi, '') !== '') {
    message = text;
  }
  return {
    application: data?.application,
    round: data?.round,
    status: status || data?.status,
    location: data?.location || 'ONLINE',
    versionKey: data?.versionKey,
    duration: data?.duration,
    message: data?.message || '',
    customLink: data?.customLink || '',
    interviewer: data?.interviewer,
    startTime: data?.startTime,
    text: message,
    timeZone: data?.timeZone || 'Asia/Kolkata',
    userIDs: Array.from(new Set(mentionedUsers)),
  };
};

export function ActionsContainer({ children, applicationData, linkID, ...props }) {
  const child = Children.only(children);
  const { id, allowedOperations } = applicationData?.interview || 0;

  const [open, setOpen] = useState(false);

  const handleOpen = (openValue) => {
    setOpen(openValue);
  };

  const putToApplication = useApplicationsPutQueryById();
  const interview = useInterviewsGetQueryById(
    linkID === applicationData?.id &&
      open &&
      (allowedOperations?.includes('PASS') ||
        (applicationData?.interview?.round === 'PRE-SCREENING' &&
          applicationData?.interview?.status === 'PENDING')) &&
      !['NEW', 'PENDING OFFER', 'OFFERED', 'DUPLICATE'].includes(applicationData?.status) &&
      id,
    {}
  );
  const { data: interviewData } = interview;
  const putToInterview = useInterviewsPutQueryById();

  const handlePutApplication = (status, reason, teamID) => {
    const reqBody = formateData(applicationData, reason, status, teamID);
    const reqParams = {
      reqBody,
      reqId: applicationData.id,
    };
    putToApplication.mutate(reqParams);
  };

  const handlePutInterview = (status, reason, mentionedUsers) => {
    const reqBody = formateInterviewData(interviewData, reason, status, mentionedUsers);
    putToInterview.mutate({ reqBody, reqId: id });
  };

  return cloneElement(child, {
    handlePutApplication,
    handlePutInterview,
    applicationData,
    putToApplication,
    putToInterview,
    interview,
    handleOpen,
    ...props,
  });
}
