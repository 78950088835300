import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createInterviews } from '../../Repositories';

const interviewsReq = createInterviews();

/**
 * this function(query) is to get All interview
 * @function useInterviewsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useInterviewsGetQuery(reqParams, options) {
  return useQuery(
    ['interviewsGetQuery', reqParams],
    () => interviewsReq.getAllInterviews(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

/**
 * this function(query) is to get interview by Id
 * @function useInterviewsGetQueryById
 * @param {Number} reqId  params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useInterviewsGetQueryById(reqId, options) {
  return useQuery(['interviewsGetQueryById', reqId], () => interviewsReq.getInterviewById(reqId), {
    enabled: !!reqId,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post interview
 * @function useInterviewsPostQuery
 * @returns api response
 */

export function useInterviewsPostQuery() {
  const queryClient = useQueryClient();

  return useMutation(['interviewsPostQuery'], (args) => interviewsReq.postInterview(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['applicationsGetQueryById']);
      queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
    },
  });
}

/**
 * this function(query) is to put interview by id
 * @function useInterviewsPutQueryById
 * @returns api response
 */
export function useInterviewsPutQueryById() {
  const queryClient = useQueryClient();

  return useMutation(['interviewsPutQueryById'], (args) => interviewsReq.putInterviewById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['applicationsGetQueryById']);
      queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
      queryClient.invalidateQueries(['interviewsGetQueryById']);
    },
  });
}
/**
 * this function(query) is to delete interview by id
 * @function useInterviewsDeleteQueryById
 * @return api response
 */

export function useInterviewsDeleteQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['interviewsDeleteQueryById'],
    (args) => interviewsReq.deleteInterviewById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['applicationsGetQueryById']);
        queryClient.invalidateQueries(['interviewsGetQueryById']);
        queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
      },
    }
  );
}
