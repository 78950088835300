import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createTeams } from '../../Repositories';

const teamReq = createTeams();

/**
 * this function(query) is to get All Teams
 * @function useTeamsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useTeamsGetQuery(reqParams, options) {
  return useQuery(['teamGetQuery', reqParams], () => teamReq.getAllTeam(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to get Teams by Id
 * @function useTeamGetQueryById
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useTeamGetQueryById(reqParams, options) {
  return useQuery(['teamGetQueryById', reqParams], () => teamReq.getTeamById(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post team
 * @function useTeamPostQuery
 * @returns api response
 */
export function useTeamPostQuery() {
  const queryClient = useQueryClient();
  return useMutation(['teamPostQuery'], (args) => teamReq.postTeam(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['teamGetQuery']);
    },
  });
}

/**
 * this function(query) is to put team by Id
 * @function useTeamPutQueryById
 * @returns api response
 */
export function useTeamPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['teamPutQueryById'], (args) => teamReq.putTeamById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['teamGetQuery']);
    },
  });
}

/**
 * this function(query) is to deletes team by Id
 * @function useTeamDeleteQueryById
 * @returns api response
 */
export function useTeamDeleteQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['teamDeleteQueryById'], (args) => teamReq.deleteTeamById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['teamGetQuery']);
    },
  });
}

/**
 * this function(query) is to get All Team requirements
 * @function useGetTeamRequirementsQuery
 * @param {Object} req params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useGetTeamRequirementsQuery(req, options) {
  return useQuery(['getTeamRequirementsList', req], () => teamReq.getTeamRequirements(req), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to add team requirement
 * @function useAddTeamRequirementsQuery
 * @returns api response
 */
export function useAddTeamRequirementsQuery() {
  const queryClient = useQueryClient();
  return useMutation(['addTeamRequirements'], (req) => teamReq.addTeamRequirement(req), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['getTeamRequirementsList']);
      queryClient.invalidateQueries(['positionsGetQueryById']);
    },
  });
}

/**
 * this function(query) is to put team requirement
 * @function useUpdateTeamRequirementsQuery
 * @returns api response
 */
export function useUpdateTeamRequirementsQuery() {
  const queryClient = useQueryClient();
  return useMutation(['updateTeamRequirements'], (req) => teamReq.updateTeamRequirement(req), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['getTeamRequirementsList']);
    },
  });
}

/**
 * this function(query) is to delete team requirement by Id
 * @function useDeleteTeamRequirementsByIdQuery
 * @returns api response
 */
export function useDeleteTeamRequirementsByIdQuery() {
  const queryClient = useQueryClient();
  return useMutation(
    ['deleteTeamRequirementsQuery'],
    (req) => teamReq.deleteTeamRequirementById(req),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['getTeamRequirementsList']);
      },
    }
  );
}
