import { createFetcher } from '../../Common';

import { APPLICANT_ENDPOINT, BASE_URL } from '../../constants';

export class Applicant {
  static instance;

  static getInstance() {
    if (!this.instance) {
      this.instance = new Applicant(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.fetcher = fetcher;
    this.base_url = BASE_URL;
    this.endpoint = APPLICANT_ENDPOINT;
  }

  async getApplicantById(reqId) {
    const url = `${this.base_url + this.endpoint}/${reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  async putApplicantById(reqParam) {
    const url = `${this.base_url + this.endpoint}/${reqParam.reqId}`;
    const response = await this.fetcher.putData(url, reqParam.reqBody);
    return response?.data;
  }
}

export const createApplicant = () => Applicant.getInstance();
