import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, DASHBOARD_ENDPOINT } from '../../constants';

export class Dashboard {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Dashboard(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = DASHBOARD_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getDashboardDetails(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
}

export const createDashboard = () => Dashboard.getInstance();
