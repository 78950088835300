import { useQuery } from '@tanstack/react-query';

import { createPanelReports } from '../../Repositories';

const panelReportReq = createPanelReports();

/**
 * this function(query) is to get All Panel Reports
 * @function usePanelReportsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function usePanelReportsGetQuery(reqParams, options) {
  return useQuery(
    ['panelReportsGetQuery', reqParams],
    () => panelReportReq.getAllPanelReports(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}
