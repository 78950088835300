import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createDepartments } from '../../Repositories';

const departmentReq = createDepartments();

/**
 * this function(query) is to get All department
 * @function useDepartmentGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useDepartmentGetQuery(reqParams, options) {
  return useQuery(
    ['departmentGetQuery', reqParams],
    () => departmentReq.getAllDepartment(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}
/**
 * this function(query) is to get All department
 * @function useDepartmentPostQuery
 * @returns api response
 */
export function useDepartmentPostQuery() {
  const queryClient = useQueryClient();
  return useMutation(['departmentPostQuery'], (req) => departmentReq.postDepartment(req), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['departmentGetQuery']);
    },
  });
}

/**
 * this function(query) is to edit deparment by id
 * @function useUpdateDepartmentQueryById
 * @returns api response
 */
export function useUpdateDepartmentQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['departmentPutQueryById'], (args) => departmentReq.putDepartmentById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['departmentGetQuery']);
    },
  });
}
/**
 * this function(query) is to delete department by id
 * @function useDeleteDepartmentQueryById
 * @returns api response
 */
export function useDeleteDepartmentQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['departmentDeleteQueryById'],
    (args) => departmentReq.deleteDepartmentById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['departmentGetQuery']);
      },
    }
  );
}
