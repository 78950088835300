import { createFetcher } from '../../Common';

import { BASE_URL, ORGANIZATION_ENDPOINT, orgToken } from '../../constants';

export class Organization {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Organization(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = ORGANIZATION_ENDPOINT;
    this.fetcher = fetcher;
    this.token = orgToken;
  }

  async postOrganization(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, { token: this.token, ...reqBody });
    return response?.data;
  }

  async postRefreshCall(reqBody) {
    const response = await this.fetcher.refreshToken(reqBody);
    return response;
  }
}

export const createOrg = () => Organization.getInstance();
