import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createApplications } from '../../Repositories';

const applicationsReq = createApplications();

/**
 * this function(query) is to get All application
 * @function useApplicationsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useApplicationsGetQuery(reqParams, options) {
  return useQuery(
    ['applicationsGetQuery', reqParams],
    () => applicationsReq.getAllApplications(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

/**
 * this function(query) is to get application by Id
 * @function useApplicationsGetQueryById
 * @param {Number} reqId Id pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useApplicationsGetQueryById(reqId, options) {
  return useQuery(
    ['applicationsGetQueryById', reqId],
    () => applicationsReq.getApplicationById(reqId),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

export function useApplicationsResumeGetQuery(reqId, options) {
  return useQuery(
    ['applicationsResumeGetQuery', reqId],
    () => applicationsReq.getApplicationResume(reqId),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
      enabled: false,
    }
  );
}
export function useFileUploadGetQuery() {
  return useQuery(['fileUploadGetQuery'], () => applicationsReq.getFileUploadURL(), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: false,
  });
}

/**
 * this function(query) is to put application by id
 * @function useApplicationsPutQueryById
 * @returns api response
 */
export function useApplicationsPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['applicationsPutQueryById'],
    (args) => applicationsReq.putApplicationById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['applicationsGetQuery']);
        queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
        queryClient.invalidateQueries(['applicationsGetQueryById']);
      },
    }
  );
}

/**
 * this function(query) is to put application reopen by id
 * @function useApplicationsReopenQueryById
 * @returns api response
 */
export function useApplicationsReopenQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['applicationsReopenQueryById'],
    (args) => applicationsReq.putApplicationReopenById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['applicationsGetQueryById']);
      },
    }
  );
}

/**
 * this function(query) is to put application screening by id
 * @function useApplicationsScreeningPutQueryById
 * @returns api response
 */
export function useApplicationsScreeningPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['applicationsScreeningPutQueryById'],
    (args) => applicationsReq.putApplicationScreeningById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
      },
    }
  );
}

/**
 * this function(query) is to get recaptcha
 * @function useCaptchaGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useCaptchaGetQuery(reqParams, options) {
  return useQuery(['captchaGetQuery', reqParams], () => applicationsReq.getCaptcha(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post application
 * @function useApplicationsPostQuery
 * @returns api response
 */
export function useApplicationsPostQuery() {
  const queryClient = useQueryClient();
  return useMutation(['applicationsPostQuery'], (args) => applicationsReq.postApplication(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardGetQuery']);
      queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
    },
  });
}

/**
 * this function(query) is to get application offer by id
 * @function useApplicationsOfferGetQueryById
 * @param {Number} reqId Id pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useApplicationsOfferGetQueryById(reqId, options) {
  return useQuery(
    ['applicationsOfferGetQueryById', reqId],
    () => applicationsReq.getApplicationOfferById(reqId),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

/**
 * this function(query) is to post application offer
 * @function useApplicationsOfferPostQuery
 * @returns api response
 */
export function useApplicationsOfferPostQuery() {
  const queryClient = useQueryClient();
  return useMutation(
    ['applicationsOfferPostQuery'],
    (args) => applicationsReq.postApplicationOffer(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['applicationsGetQueryById']);
        queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
      },
    }
  );
}

/**
 * this function(query) is to put application offer by id
 * @function useApplicationsOfferPutQueryById
 * @returns api response
 */
export function useApplicationsOfferPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['applicationsOfferPutQueryById'],
    (args) => applicationsReq.putApplicationOfferById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['applicationsGetQueryById']);
        queryClient.invalidateQueries(['positionsKanbanGetQueryById']);
        queryClient.invalidateQueries(['applicationsOfferGetQueryById']);
      },
    }
  );
}

/**
 * this function(query) is to post duplicate check
 * @function useDuplicateCheckQuery
 * @returns api response
 */

export function useDuplicateCheckQuery() {
  return useMutation(['duplicateCheckQuery'], (args) => applicationsReq.postDuplicateCheck(args), {
    retry: false,
  });
}
