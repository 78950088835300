import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createLocations } from '../../Repositories';

const locationRequest = createLocations();

/**
 * this function(query) is to get all location
 * @function useLocationGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useLocationGetQuery(reqParams, options) {
  return useQuery(
    ['locationGetQuery', reqParams],
    () => locationRequest.getAllLocation(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}
/**
 * this function(query) is to add location
 * @function useAddLocationQuery
 * @returns api response
 */
export function useAddLocationQuery() {
  const queryClient = useQueryClient();
  return useMutation(['locationPostQuery'], (args) => locationRequest.postLocation(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['locationGetQuery']);
    },
  });
}
/**
 * this function(query) is to edit location by id
 * @function useUpdateLocationQueryById
 * @returns api response
 */
export function useUpdateLocationQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['locationPutQueryById'], (args) => locationRequest.putLocationById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['locationGetQuery']);
    },
  });
}
/**
 * this function(query) is to delete location by id
 * @function useDeleteLocationQueryById
 * @returns api response
 */
export function useDeleteLocationQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['locationDeleteQueryById'],
    (args) => locationRequest.deleteLocationById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['locationGetQuery']);
      },
    }
  );
}
