import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, LOCATIONS_ENDPOINT } from '../../constants';

export class Locations {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Locations(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = LOCATIONS_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getAllLocation(req) {
    const queryString = queryBuilder(req);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }

  async postLocation(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }

  async putLocationById(req) {
    const url = `${this.base_url}${this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.putData(url, req.reqBody);
    return response?.data;
  }

  async deleteLocationById(req) {
    const url = `${this.base_url}${this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.deleteData(url, req.reqBody);
    return response;
  }
}

export const createLocations = () => Locations.getInstance();
