import { Children, cloneElement, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import { useReportsGetQuery } from '../../Common/Queries/reportsQueries';

import { isValidDate } from '../../utils';

const initialValues = { fromDate: '', toDate: '', selectedStatus: 'month', showRange: false };

export const ReportsContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const initialFromDate = isValidDate(urlQuery.get('from')) && urlQuery.get('from');
  const initialToDate = isValidDate(urlQuery.get('to')) && urlQuery.get('to');
  const initialFilterValuesToApply = {
    ...initialValues,
    ...(initialFromDate &&
      initialToDate && {
        fromDate: initialFromDate,
        toDate: initialToDate,
        selectedStatus: initialFromDate === initialToDate ? 'select' : 'dateRange',
        showRange: initialFromDate !== initialToDate,
      }),
  };
  const [filters, updateFilters] = useState(initialFilterValuesToApply);
  const reports = useReportsGetQuery({
    'dateRange.fromDate': filters.fromDate,
    'dateRange.toDate': filters.toDate,
    'dateRange.includeDate': true,
  });

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, { reports, filters, filterHandler: handleOnFilterChange });
};
