import React from 'react';
import { Popover } from '@mui/material';

function CustomPopover(props) {
  const { children, anchorEl, setAnchorEl } = props;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
    >
      {children}
    </Popover>
  );
}

export default CustomPopover;
