// @ts-check

/**
 *@function convertToISO
 * @param {string} str
 * @returns Date in required format
 */

// utility function for convert date in required format
export const convertToISO = (str) => {
  const date = new Date(str);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return [date.getFullYear(), month, day].join('-');
};
