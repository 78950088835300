import { alpha, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Helvetica Neue', 'HelveticaNeue', 'Helvetica'].join(','),
    button: {
      textTransform: 'none',
    },
  },

  breakpoints: {
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          top: 0,
          left: 'auto',
          backgroundColor: '#FFFFFF',
        },
        root: {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          padding: '0.5rem 1.25rem',
          maxWidth: '13rem',
          minWidth: '10rem',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          fontSize: '14px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: { fontSize: '14px', wordBreak: ' break-word' },
        h2: { fontWeight: 700, fontSize: '35px' },
        h3: { fontWeight: 600, fontSize: '30px' },
        h6: { fontWeight: 500, fontSize: '14px' },
        h4: { fontWeight: 500, fontSize: '20px' },
        h5: { fontWeight: 400, fontSize: '20px' },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: { color: '#231F20' },
        colorSecondary: {
          '&$checked': { color: '#00B7A4' },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: { color: '#231F20' },
        colorSecondary: {
          '&$checked': { color: '#00B7A4' },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          color: 'rgb(13, 60, 97)',
          backgroundColor: 'rgb(232, 244, 253)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: { color: 'rgba(0, 0, 0, 0.87)', backgroundColor: '#EBEBEB' },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingLeft: '10px',
          paddingRight: 0,
          paddingBottom: '5px',
        },
        root: {
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid',
            borderColor: '#52bbab',
          },
          '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: 'red',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              border: '1px solid',
              borderColor: '#F3F7F7',
            },
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            border: '1px solid',
            borderColor: '#F3F7F7',
          },
        },
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        input: {
          '&:-webkit-autofill': {
            boxShadow: 'none !important',
            textFillColor: 'black !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'white',
            borderColor: 'brown',
          },
          '&:before': { borderColor: 'orange' },
          '&:after': { borderColor: 'green' },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        anchorOriginTopRightRectangle: {
          backgroundColor: 'red',
          color: '#FFFFFF',
        },
        badge: {
          backgroundColor: 'red !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          boxShadow: 'none',
        },
        root: {
          '&-MuiDialog-paper': {
            display: 'flex',
            flex: '0,0,auto',
            padding: '8px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          opacity: '0.38',
          backgroundColor: '#000',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-root.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
          '&.MuiButton-root.OpenButton-textButton': {
            border: '1px solid rgba(82, 187, 171, 0.5)',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          padding: '8px 12px',
          lineHeight: '20px',
          textTransform: 'none',
          fontSize: 13.5,
          fontWeight: 500,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
        invisible: {
          backgroundColor: 'unset',
        },
      },
    },
  },

  palette: {
    color: {
      red: '#FF0000',
      default: '#000000',
      white: '#FFFFFF',
      whiteSmoke: '#FAFAFA',
      status: '#00765A',
      chip: '#203A4F',
      level: '#8A2BE2',
      stream: '#BE4830',
      skill: '#DC6390',
      createdAt: '#284FF0',
      updatedAt: '#854442',
      darkgray: '#1D1E1F',
      gray: '#EFEFEF',
      rounds: '#5A5AE2',
      roundStatus: '#37b4EE',
      agencyId: '#00B3B3',
    },
    background: {
      default: '#F3F7F7',
      main: '#CCF1ED',
      white: '#FFFFFF',
      dragBtnHover: '#ECECEC',
    },
    border: {
      menu: '1px solid #D3D4D5',
      donutRootDiv: '0.6px solid #7D7D7D',
      welcomeSection: '0.6px solid #7D7D7D',
      searchSuggestion: '1px solid #E0E0E0',
    },
    text: { primary: '#262B4B', secondary: '#262A4C' },
    primary: { main: '#52BBAB' },

    lightHighlight: { main: '#F0F0FD' },
    footer: { main: '#17173F', contrastText: 'whitesmoke' },
    quizHeader: { main: '#26225E', contrastText: 'whitesmoke' },
    errorColor: { main: 'crimson' },
    successColor: { main: 'forestgreen' },
    partialSuccessColor: { main: 'goldenrod' },

    primarySelection: { main: '#00B7A4', contrastText: '#FFFFFF' },

    loader: { main: '#00B7A4' },
    header: { main: '#262B4B', contrastText: '#FFFFFF' },

    darkTextColor: { main: '#262B4B' },
    lightTextColor: { main: '#FFFFFF' },
    grayTextColor: { main: '#8E8E8E' },

    required: { main: '#FF0000' },
    labelName: { main: '#1D1E1F' },

    lightBackground: { main: '#FFFFFF', contrastText: '#262B4B' },
    darkBackground: { main: '#F3F7F7', contrastText: '#262B4B' },

    borders: { main: '#D4D5DB' },
    dividerLines: { main: '#E6E6E6' },
    lightDividerLines: { main: '#EDEFF0' },
    headerDividerLines: { main: '#383D5A' },

    highlight: { main: 'rgba(0, 183, 164, 0.1)' },
    inputValidation: { main: 'crimson' },
    quizNotPublished: {
      text: '#FF0000',
      backgroundColor: '#FFEAEA',
    },

    primaryButton: {
      main: '#FFFFFF',
      contrastText: '#FFFFFF',
      backgroundColor: '#52BBAB',
      '&:hover': {
        backgroundColor: '#52ABAB',
      },
      '&:hover:disabled': {
        backgroundColor: alpha('#52BBAB', 0.4),
      },
      '&:active': {
        backgroundColor: '#52BBAB',
      },
      '&:disabled': {
        backgroundColor: alpha('#52BBAB', 0.4),
        cursor: 'not-allowed',
      },
    },

    secondaryButton: {
      main: '#1D1E1F',
      backgroundColor: '#EFF0F1',
      '&:hover': {
        backgroundColor: '#EFF0F1',
      },
      '&:active': {
        backgroundColor: '#EFF0F1',
      },
    },

    warningButton: {
      main: '#FFFFFF',
      backgroundColor: '#FFA000',
      '&:hover': {
        backgroundColor: '#FF8000',
      },
      '&:active': {
        backgroundColor: '#FFA500',
      },
    },

    deleteButton: {
      main: '#FFFFFF',
      backgroundColor: '#FF6C54',
      '&:hover': {
        backgroundColor: '#FF4C54',
      },
      '&:hover:disabled': {
        backgroundColor: alpha('#FF6C54', 0.4),
      },
      '&:active': {
        backgroundColor: '#FF6C54',
      },
      '&:disabled': {
        backgroundColor: alpha('#FF6C54', 0.4),
        color: '#FFF',
        cursor: 'not-allowed',
      },
    },

    confirmationButton: {
      main: '#FFFFFF',
      backgroundColor: '#00B7A4',
      fontSize: '14px',
      fontFamily: 'Helvetica Neue,HelveticaNeue,Helvetica',
      fontWeight: 500,
      borderRadius: '4px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#009685',
        color: '#FFFFFF',
      },
    },
  },
});

export default theme;
