import { AppBar, styled } from '@mui/material';

/**
 * style Required input text
 */
export const Required = styled('span')(({ theme }) => ({ color: theme.palette.required.main }));

/**
 * style Label-Name text in form
 */
export const LabelName = styled('span')(({ theme }) => ({ color: theme.palette.labelName.main }));

/**
 * style root div for Form component
 */
export const RootDiv = styled('div')({ padding: '10px', flexGrow: 1 });

/**
 * style div in Edit and Add form
 */
export const StyledDiv = styled('div')({
  minHeight: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

/**
 * style submit button div
 */
export const SubmitDiv = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '5px',
});

/**
 * style grid div for add form
 */
export const GridDiv = styled('div')({
  flexGrow: 1,
});

/**
 * common style for appBar in interview
 */
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
}));

/**
 * common style for Title in interview
 */
export const title = {
  marginLeft: '1rem',
  flex: 1,
  fontSize: '1.875rem',
  fontWeight: 600,
};
