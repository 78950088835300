import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import { useInterviewsGetQuery } from '../../Common/Queries/interviewsQueries';

import { isValidID } from '../../utils';

import { AuthContext } from '../../constants';

export const MyInterviewsContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const status = urlQuery.get('status');
  const skipRounds = urlQuery.get('skipRounds');
  const exemptAppStatus = urlQuery.get('exemptAppStatus');
  const userId =
    (isValidID(urlQuery.get('userID')) && urlQuery.get('userID')) || userInfo?.entityID;
  const initialUpcomingPage =
    isValidID(urlQuery.get('upcoming-interviews-page')) && urlQuery.get('upcoming-interviews-page');
  const initialPastPage =
    isValidID(urlQuery.get('past-interviews-page')) && urlQuery.get('past-interviews-page');
  const initialFilterValuesToApply = {
    upcomingInterviewsPage: initialUpcomingPage ? initialUpcomingPage - 1 : 0,
    pastInterviewsPage: initialPastPage ? initialPastPage - 1 : 0,
  };
  const [filters, updateFilters] = useState(initialFilterValuesToApply);
  const handleOnUpcomingFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, upcomingInterviewsPage: updatedFilters.page }));
  };
  const handleOnPastFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, pastInterviewsPage: updatedFilters.page }));
  };
  const upcomingInterviews =
    initialUpcomingPage &&
    useInterviewsGetQuery({
      page: Number(filters.upcomingInterviewsPage + 1),
      limit: 20,
      interviewerID: userId,
      skipRounds,
      status,
    });
  const pastInterviews =
    initialPastPage &&
    useInterviewsGetQuery({
      page: Number(filters.pastInterviewsPage + 1),
      limit: 20,
      interviewerID: userId,
      skipRounds,
      exemptAppStatus,
      status,
    });
  const child = Children.only(children);

  return cloneElement(child, {
    upcomingInterviews,
    pastInterviews,
    filters,
    filterHandlerUpcoming: handleOnUpcomingFilterChange,
    filterHandlerPast: handleOnPastFilterChange,
  });
};
