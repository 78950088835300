import React, { lazy, Suspense, useContext } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { useAuth } from '@zopsmart/zs-components';

import CompleteLoader from '../Components/Loader/CompletePageLoader';

import useWindowSize from '../Common/Hook/useWindowSize';

import { componentLazyLoader, isLogin } from '../utils';

import {
  ACCESS_TOKEN_ENDPOINT,
  AGENCIES_ENDPOINT,
  ALLOWED_RETRY_LIMIT,
  APPLICANT_DETAILS_ENDPOINT,
  APPLICATION_OVERVIEW_ENDPOINT,
  APPLICATIONS_BETA_ENDPOINT,
  APPLICATIONS_DETAILS_ENDPOINT,
  APPLICATIONS_ENDPOINT,
  AuthContext,
  BASE_URL,
  CALENDAR_ENDPOINT,
  DASHBOARD_ENDPOINT,
  drawerWidth,
  HR_REPORTS_ENDPOINT,
  INTERVIEWS_ENDPOINT,
  LEVELS_ENDPOINT,
  LOGGED_IN_USER_INFO,
  LOGIN_ENDPOINT,
  MOBILE_VIEW_WIDTH,
  MY_OPEN_POSITIONS_ENDPOINT,
  ORGANIZATION_ENDPOINT,
  OtherContext,
  PANEL_REPORTS_ENDPOINT,
  POSITIONS_ENDPOINT,
  PRE_SCREENING,
  PREVIOUS_INTERVIEWS_ENDPOINT,
  REFERRALS_ENDPOINT,
  RESUME_SCREENING,
  ROLES,
  SETTINGS_ENDPOINT,
  SKILLS_ENDPOINT,
  STREAMS_ENDPOINT,
  TABULAR_EVENTS_ENDPOINT,
  TEAM_REQUIREMENTS_END_POINT,
  TEAMS_ENDPOINT,
  USER_LOGOUT_ENDPOINT,
  USERS_ENDPOINT,
} from '../constants';

import PrivateRoute from '../PrivateRoutes';

import RouterComponentUI from './RouterComponentUI';

import '../App.css';
import 'quill-mention/dist/quill.mention.min.css';

const MainContent = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  width: '100%',
  height: '100vh',
  position: 'relative',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isMobile || !isLogin() ? 0 : `-${drawerWidth}rem`,
  ...(open && {
    width: `calc(100% - ${drawerWidth}rem)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.spacing(0),
  }),
}));

const HeaderDiv = styled('div')``;
const RootDiv = styled('div')({ display: 'flex' });

const HRReports = lazy(() =>
  componentLazyLoader(() => import('../Pages/Reports'), ALLOWED_RETRY_LIMIT)
);

const PanelReports = lazy(() =>
  componentLazyLoader(() => import('../Pages/PanelReports'), ALLOWED_RETRY_LIMIT)
);

const LoginPage = lazy(() =>
  componentLazyLoader(() => import('../Pages/Login'), ALLOWED_RETRY_LIMIT)
);

const LogoutPage = lazy(() =>
  componentLazyLoader(
    () => import('../Components/LogInAndLogout/Logout/logout'),
    ALLOWED_RETRY_LIMIT
  )
);
const ViewPosition = lazy(() =>
  componentLazyLoader(() => import('../Pages/Position/View'), ALLOWED_RETRY_LIMIT)
);
const Positions = lazy(() =>
  componentLazyLoader(() => import('../Pages/Position/List'), ALLOWED_RETRY_LIMIT)
);
const Referrals = lazy(() =>
  componentLazyLoader(() => import('../Pages/Referrals/List'), ALLOWED_RETRY_LIMIT)
);
const Applications = lazy(() =>
  componentLazyLoader(() => import('../Pages/Applications/List'), ALLOWED_RETRY_LIMIT)
);
const ApplicationsBeta = lazy(() =>
  componentLazyLoader(() => import('../Pages/ApplicationsBeta/List'), ALLOWED_RETRY_LIMIT)
);
const ApplicationOverview = lazy(() =>
  componentLazyLoader(() => import('../Pages/PivotTable'), ALLOWED_RETRY_LIMIT)
);
const Dashboard = lazy(() =>
  componentLazyLoader(() => import('../Pages/Dashboard'), ALLOWED_RETRY_LIMIT)
);
const MyInterviews = lazy(() =>
  componentLazyLoader(() => import('../Pages/MyInterviews'), ALLOWED_RETRY_LIMIT)
);
const PreviousInterviews = lazy(() =>
  componentLazyLoader(() => import('../Pages/MyInterviews/previousInterviews'), ALLOWED_RETRY_LIMIT)
);
const Interview = lazy(() =>
  componentLazyLoader(() => import('../Pages/Interview'), ALLOWED_RETRY_LIMIT)
);
const GoogleCalender = lazy(() =>
  componentLazyLoader(() => import('../Pages/Interview/GoogleCalender'), ALLOWED_RETRY_LIMIT)
);
const TableView = lazy(() =>
  componentLazyLoader(() => import('../Components/Calendar/tableView'), ALLOWED_RETRY_LIMIT)
);
const ResumeScreening = lazy(() =>
  componentLazyLoader(() => import('../Pages/ResumeScreening'), ALLOWED_RETRY_LIMIT)
);
const PreScreening = lazy(() =>
  componentLazyLoader(() => import('../Pages/PreScreening'), ALLOWED_RETRY_LIMIT)
);
const Users = lazy(() =>
  componentLazyLoader(() => import('../Pages/Users/List'), ALLOWED_RETRY_LIMIT)
);
const Levels = lazy(() =>
  componentLazyLoader(() => import('../Pages/Level/List'), ALLOWED_RETRY_LIMIT)
);
const Skills = lazy(() =>
  componentLazyLoader(() => import('../Pages/Skill/List'), ALLOWED_RETRY_LIMIT)
);
const Streams = lazy(() =>
  componentLazyLoader(() => import('../Pages/Stream/List'), ALLOWED_RETRY_LIMIT)
);
const Teams = lazy(() =>
  componentLazyLoader(() => import('../Pages/Team/List'), ALLOWED_RETRY_LIMIT)
);
const TeamRequirements = lazy(() =>
  componentLazyLoader(() => import('../Pages/TeamRequirement/List'), ALLOWED_RETRY_LIMIT)
);
const Agencies = lazy(() =>
  componentLazyLoader(() => import('../Pages/Agency/List'), ALLOWED_RETRY_LIMIT)
);
const Page404 = lazy(() =>
  componentLazyLoader(() => import('../Pages/404/Page404'), ALLOWED_RETRY_LIMIT)
);
const OpenPositions = lazy(() =>
  componentLazyLoader(() => import('../Pages/MyOpenPositions'), ALLOWED_RETRY_LIMIT)
);
const ApplicationsDetails = lazy(() =>
  componentLazyLoader(() => import('../Pages/ApplicationDetails'), ALLOWED_RETRY_LIMIT)
);
const Settings = lazy(() =>
  componentLazyLoader(() => import('../Pages/Settings'), ALLOWED_RETRY_LIMIT)
);
const ApplicantDetails = lazy(() =>
  componentLazyLoader(() => import('../Pages/ApplicantDetails'), ALLOWED_RETRY_LIMIT)
);
const Organization = lazy(() =>
  componentLazyLoader(() => import('../Pages/Organization'), ALLOWED_RETRY_LIMIT)
);

export default function Router() {
  const theme = useTheme();
  const location = useLocation();
  const windowSize = useWindowSize();

  const { isFullScreen } = useContext(OtherContext);
  const { userInfo } = useContext(AuthContext);

  const role = userInfo?.roles && userInfo?.roles[0];

  const providers = [];
  useAuth(LOGGED_IN_USER_INFO, BASE_URL, LOGIN_ENDPOINT, ACCESS_TOKEN_ENDPOINT, providers);
  const isOrganizationRoute = location.pathname === ORGANIZATION_ENDPOINT;

  return (
    <RootDiv>
      <HelmetProvider>
        {!isOrganizationRoute && <RouterComponentUI />}
        <MainContent open={isFullScreen} isMobile={windowSize.width <= MOBILE_VIEW_WIDTH}>
          {!isOrganizationRoute && (
            <HeaderDiv
              sx={{
                ...(isLogin() && {
                  display: 'flex',
                  alignItems: 'center',
                  padding: theme.spacing(0, 1),
                  ...theme.mixins.toolbar,
                  justifyContent: 'flex-end',
                }),
              }}
            />
          )}
          <Suspense fallback={<CompleteLoader />}>
            <Routes>
              <Route exact path={ORGANIZATION_ENDPOINT} element={<PrivateRoute />}>
                <Route path={ORGANIZATION_ENDPOINT} element={<Organization />} />
              </Route>

              <Route exact path={DASHBOARD_ENDPOINT} element={<PrivateRoute />}>
                <Route exact path={DASHBOARD_ENDPOINT} element={<Dashboard />} />
              </Route>

              <Route exact path={POSITIONS_ENDPOINT} element={<PrivateRoute />}>
                <Route exact path={POSITIONS_ENDPOINT} element={<Positions />} />
              </Route>

              <Route exact path={INTERVIEWS_ENDPOINT} element={<PrivateRoute />}>
                <Route exact path={INTERVIEWS_ENDPOINT} element={<MyInterviews />} />
              </Route>
              <Route exact path={PREVIOUS_INTERVIEWS_ENDPOINT} element={<PrivateRoute />}>
                <Route exact path={PREVIOUS_INTERVIEWS_ENDPOINT} element={<PreviousInterviews />} />
              </Route>

              <Route path={POSITIONS_ENDPOINT} element={<PrivateRoute />}>
                <Route path=":id" element={<ViewPosition />} />
              </Route>

              {role !== ROLES.AGENCY && (
                <Route exact path={APPLICATIONS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={APPLICATIONS_ENDPOINT} element={<Applications />} />
                </Route>
              )}
              {role !== ROLES.AGENCY && (
                <Route exact path={APPLICATIONS_BETA_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={APPLICATIONS_BETA_ENDPOINT} element={<ApplicationsBeta />} />
                </Route>
              )}
              {role !== ROLES.AGENCY && (
                <Route path={APPLICATIONS_ENDPOINT} element={<PrivateRoute />}>
                  <Route path=":id" element={<Interview />} />
                </Route>
              )}
              {role !== ROLES.AGENCY && (
                <Route path={APPLICATIONS_BETA_ENDPOINT} element={<PrivateRoute />}>
                  <Route path=":id" element={<Interview />} />
                </Route>
              )}
              {role !== ROLES.USER && role !== ROLES.AGENCY && (
                <Route exact path={APPLICATION_OVERVIEW_ENDPOINT} element={<PrivateRoute />}>
                  <Route
                    exact
                    path={APPLICATION_OVERVIEW_ENDPOINT}
                    element={<ApplicationOverview />}
                  />
                </Route>
              )}

              <Route exact path={REFERRALS_ENDPOINT} element={<PrivateRoute />}>
                <Route exact path={REFERRALS_ENDPOINT} element={<Referrals />} />
              </Route>

              {role !== ROLES.AGENCY && (
                <Route exact path={CALENDAR_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={CALENDAR_ENDPOINT} element={<GoogleCalender />} />
                </Route>
              )}
              {role !== ROLES.AGENCY && (
                <Route exact path={TABULAR_EVENTS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={TABULAR_EVENTS_ENDPOINT} element={<TableView />} />
                </Route>
              )}

              {(role === ROLES.ADMIN ||
                role === ROLES.HIRING_MANAGER ||
                role === ROLES.SUPER_ADMIN) && (
                <Route exact path={RESUME_SCREENING} element={<PrivateRoute />}>
                  <Route exact path={RESUME_SCREENING} element={<ResumeScreening />} />
                </Route>
              )}

              {(role === ROLES.ADMIN || role === ROLES.HR || role === ROLES.SUPER_ADMIN) && (
                <Route exact path={PRE_SCREENING} element={<PrivateRoute />}>
                  <Route exact path={PRE_SCREENING} element={<PreScreening />} />
                </Route>
              )}
              {role !== ROLES.AGENCY && role !== ROLES.USER && (
                <Route exact path={TEAMS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={TEAMS_ENDPOINT} element={<Teams />} />
                </Route>
              )}
              {role !== ROLES.AGENCY && role !== ROLES.USER && (
                <Route exact path={TEAM_REQUIREMENTS_END_POINT} element={<PrivateRoute />}>
                  <Route exact path={TEAM_REQUIREMENTS_END_POINT} element={<TeamRequirements />} />
                </Route>
              )}

              {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) && (
                <Route exact path={USERS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={USERS_ENDPOINT} element={<Users />} />
                </Route>
              )}

              {role !== ROLES.AGENCY && role !== ROLES.USER && (
                <Route exact path={LEVELS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={LEVELS_ENDPOINT} element={<Levels />} />
                </Route>
              )}

              {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) && (
                <Route exact path={SKILLS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={SKILLS_ENDPOINT} element={<Skills />} />
                </Route>
              )}

              {role !== ROLES.AGENCY && role !== ROLES.USER && (
                <Route exact path={STREAMS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={STREAMS_ENDPOINT} element={<Streams />} />
                </Route>
              )}

              {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) && (
                <Route exact path={AGENCIES_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={AGENCIES_ENDPOINT} element={<Agencies />} />
                </Route>
              )}

              {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) && (
                <Route exact path={HR_REPORTS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={HR_REPORTS_ENDPOINT} element={<HRReports />} />
                </Route>
              )}

              {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN || role === ROLES.HR) && (
                <Route exact path={PANEL_REPORTS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={PANEL_REPORTS_ENDPOINT} element={<PanelReports />} />
                </Route>
              )}

              {(role === ROLES.HIRING_MANAGER ||
                role === ROLES.HR ||
                role === ROLES.ADMIN ||
                role === ROLES.SUPER_ADMIN) && (
                <Route exact path={MY_OPEN_POSITIONS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={MY_OPEN_POSITIONS_ENDPOINT} element={<OpenPositions />} />
                </Route>
              )}

              {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN || role === ROLES.HR) && (
                <Route exact path={SETTINGS_ENDPOINT} element={<PrivateRoute />}>
                  <Route exact path={SETTINGS_ENDPOINT} element={<Settings />} />
                </Route>
              )}
              {(role === ROLES.HIRING_MANAGER ||
                role === ROLES.ADMIN ||
                role === ROLES.SUPER_ADMIN ||
                role === ROLES.HR) && (
                <Route exact path={APPLICATIONS_DETAILS_ENDPOINT} element={<PrivateRoute />}>
                  <Route
                    exact
                    path={APPLICATIONS_DETAILS_ENDPOINT}
                    element={<ApplicationsDetails />}
                  />
                </Route>
              )}

              <Route path={USER_LOGOUT_ENDPOINT} element={<LogoutPage />} />

              <Route path={LOGIN_ENDPOINT} element={<LoginPage />} />

              {role !== ROLES.AGENCY && (
                <Route path={APPLICANT_DETAILS_ENDPOINT} element={<PrivateRoute />}>
                  <Route path=":id" element={<ApplicantDetails />} />
                </Route>
              )}
              <Route exact path="/" element={<Navigate to={DASHBOARD_ENDPOINT} replace />} />

              <Route path="*" element={<PrivateRoute />}>
                <Route path="*" element={<Page404 />} />
              </Route>
            </Routes>
          </Suspense>
        </MainContent>
      </HelmetProvider>
    </RootDiv>
  );
}
