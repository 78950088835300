import { Children, cloneElement, useEffect } from 'react';

import { useDuplicateCheckQuery } from '../../Common/Queries/applicationsQueries';
import { useAddReferralQuery } from '../../Common/Queries/referralsQueries';

import { htmlToMD, IntlFormatedDate } from '../../utils';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (state, noticeValue) => {
  const markdown = htmlToMD(state.additionalInfo);
  return {
    name: state.name,
    phone: `+91${state.phone}`,
    email: state.email,
    links: state.links.filter((link) => link.type !== '' || link.value !== ''),
    resume: state.resume,
    skills: state.skills.filter((skill) => skill.name !== '' || skill.level !== ''),
    noticePeriod:
      noticeValue === 'Immediate' || noticeValue === 'Serving'
        ? `${noticeValue} (${IntlFormatedDate(state.noticePeriod)})`
        : state.noticePeriod,
    additionalInfo: markdown,
    currentSalary: Number(state.currentSalary),
    expectedSalary: Number(state.expectedSalary),
    currentLocation: state.currentLocation,
    position: state.position,
    referrerRelation: state.relationship,
  };
};

export const duplicateData = (state) => {
  return {
    phone: `+91${state.phone}`,
    email: state.email,
    position: state.position,
    isReferral: state.isReferral,
  };
};

export function AddReferralContainer({ setOpen, children }) {
  const postToReferral = useAddReferralQuery();

  const postDuplicateCheck = useDuplicateCheckQuery();

  function handleSubmitForAddReferral(values, noticeValue) {
    const reqBody = formateData(values, noticeValue);
    postToReferral.mutate(reqBody);
  }

  function handleDuplicateCheck(values) {
    const reqBody = duplicateData(values);
    postDuplicateCheck.mutate(reqBody);
  }

  useEffect(() => {
    if (postToReferral.isSuccess) {
      setOpen(false);
    }
  }, [postToReferral.isSuccess]);

  return Children.only(
    cloneElement(children, {
      postToReferral,
      handleSubmitForAddReferral,
      handleDuplicateCheck,
      postDuplicateCheck,
    })
  );
}
