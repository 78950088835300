import { createFetcher } from '../../Common';

import {
  BASE_URL,
  SERVICE_CONFIGURATIONS_REFRESH_ENDPOINT,
  SERVICES_AUTH_TOKEN_ENDPOINT,
} from '../../constants';

class Services {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Services(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.generate_ath_tokens = SERVICES_AUTH_TOKEN_ENDPOINT;
    this.serviceConfigurationsRefreshEndpoint = SERVICE_CONFIGURATIONS_REFRESH_ENDPOINT;
    this.fetcher = fetcher;
  }

  async postGenerateAuthTokens(reqBody) {
    const url = `${this.base_url}${this.generate_ath_tokens}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }

  async getServiceRefreshTokens() {
    const url = `${this.base_url}${this.serviceConfigurationsRefreshEndpoint}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  async putGenerateAuthTokens(reqBody) {
    const url = `${this.base_url}${this.serviceConfigurationsRefreshEndpoint}`;
    const response = await this.fetcher.putData(url, reqBody);
    return response?.data;
  }
}

export const createServices = () => Services.getInstance();
