import { LOGGED_IN_USER_INFO } from '../../constants';

import { getParsedValueFromLocalStorage } from '../../services/localStorage';

export const requestHeaders = () => {
  const userInfo = getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO);
  const headerWithAllKeys = {
    'True-Client-Ip': '127.0.0.1',
    'X-Correlation-ID': 1,
    'X-Zopsmart-Tenant': 'Zopsmart',
    Authorization: `Bearer ${userInfo?.accessToken}`,
  };
  return headerWithAllKeys;
};
