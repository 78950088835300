import { Children, cloneElement, useEffect } from 'react';

import { useApplicationsOfferPutQueryById } from '../../../Common/Queries/applicationsQueries';

/**
 * this function is utility function for formate the data in required formate
 * @param {*} values of offer form
 * @returns values in required formate
 */
export const formateData = (values) => {
  return {
    ...values,
    offeredSalary: Number(values.offeredSalary),
    joiningDate: values.joiningDate,
    text: values.text,
    versionKey: values.versionKey,
    link: values.link,
  };
};

export function EditOfferContainer({ children, applicationId, setOpen, ...props }) {
  const putToOffer = useApplicationsOfferPutQueryById();

  function handleSubmitForEditOffer(finalValues) {
    const reqBody = formateData(finalValues);
    const reqParam = { reqBody, reqId: applicationId };
    putToOffer.mutate(reqParam);
  }

  // closing popover after submitting form
  useEffect(() => {
    if (putToOffer.isSuccess) {
      setOpen(false);
    }
  }, [putToOffer.isSuccess]);

  return Children.only(cloneElement(children, { putToOffer, handleSubmitForEditOffer, ...props }));
}
