import { Children, cloneElement, useEffect, useState } from 'react';

import { useInterviewsPostQuery } from '../../Common/Queries/interviewsQueries';
import { usePositionsGetQueryById } from '../../Common/Queries/positionsQueries';
import { useUsersGetQuery } from '../../Common/Queries/usersQueries';

import { NOIDA_OFFICE, SECTOR_1, SECTOR_2 } from '../../constants';

const fullAddress = { sector_1: SECTOR_1, sector_2: SECTOR_2, noida: NOIDA_OFFICE };
/**
 * this function is utility function for formate the data in required formate
 * @param {*} values interview form values
 * @param {*} applicationId id of application
 * @returns values in required formate
 */
export const formateData = (values, applicationId) => {
  return {
    startTime: Math.floor(new Date(values.startTime) / 1000),
    timeZone: 'Asia/Kolkata',
    interviewer: values.interviewer,
    application: { id: Number(applicationId) },
    duration: Number(values?.duration),
    text: values?.text,
    location: fullAddress[values?.location] || 'ONLINE',
  };
};

export function AddInterviewContainer({
  children,
  positionDetail,
  applicationId,
  setOpen,
  ...props
}) {
  const [search, setSearch] = useState('');
  const interviewerList = useUsersGetQuery(
    { q: search },
    { enabled: search.length >= 2 } // enable query only if user search something
  );
  const postToInterviews = useInterviewsPostQuery();

  const positionData = usePositionsGetQueryById({ reqId: positionDetail?.id });

  function handleSubmitForAddInterview(finalValues) {
    const reqBody = formateData(finalValues, applicationId);
    postToInterviews.mutate(reqBody);
  }

  const handleClosePopup = () => setOpen(false);

  /**
   * function to handle search data for Interviewer
   * @param {String} value search input
   */
  const handleSearch = (value) => setSearch(value);

  useEffect(() => {
    if (postToInterviews.isSuccess) {
      setOpen(false);
    }
  }, [postToInterviews.isSuccess]);

  return Children.only(
    cloneElement(children, {
      interviewerList,
      handleSearch,
      postToInterviews,
      positionData,
      handleSubmitForAddInterview,
      handleCloseInterview: handleClosePopup,
      ...props,
    })
  );
}
