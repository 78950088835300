import React from 'react';
import { Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

import './completeLoader.css';

/**
 * this function is to display loader when query is fetching
 * @returns loader
 */
export default function CompleteLoader(props) {
  return (
    <div className="center-complete-loader" data-testid="testCompleteLoader">
      <Box>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) => theme.palette.grey[200],
          }}
          size={60}
          thickness={3}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#52bbab',
            animationDuration: '1500ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          thickness={3}
          size={60}
          {...props}
        />
      </Box>
    </div>
  );
}
