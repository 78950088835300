import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, styled } from '@mui/material';

import { InputText } from '../Input';

const Div = styled('div')({ display: 'flex', justifyContent: 'flex-start' });

export default function SearchMobileView({
  filterValues,
  setFilterValues,
  placeholder = 'Search',
}) {
  const [values, setValues] = useState(filterValues);
  useEffect(() => {
    setValues(filterValues);
  }, [filterValues]);

  const handleChange = (event) => {
    const searchValue = event.target.value;
    setValues((prevValues) => {
      return { ...prevValues, searchValue };
    });
    if (filterValues?.searchValue?.length > 0 && searchValue.length === 0) {
      setFilterValues({ ...values, searchValue: '' });
    }
  };

  const handelSearch = () => {
    if (
      values?.searchValue?.trim().length >= 2 ||
      (!values?.searchValue?.length && filterValues?.searchValue?.length > 0)
    ) {
      setFilterValues({ ...values, searchValue: values?.searchValue?.trim() });
    }
  };

  const handleKeyPress = (event) => {
    if (
      event.key === 'Enter' &&
      (values?.searchValue?.trim().length >= 2 ||
        (!values?.searchValue?.length && filterValues?.searchValue?.length > 0))
    ) {
      setFilterValues({ ...values, searchValue: values?.searchValue?.trim() });
    }
  };

  useEffect(() => {
    document.getElementById('application-mobileSearch').focus();
  }, []);

  return (
    <Div>
      <InputText
        placeholder={placeholder}
        value={values.searchValue}
        onChange={handleChange}
        sx={{ width: '100%', backgroundColor: 'white' }}
        onKeyPress={handleKeyPress}
        id="application-mobileSearch"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handelSearch}
                data-testid="testSearchLens"
                className="global-search-icon"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Div>
  );
}
