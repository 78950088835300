import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import useWindowSize from '../../Common/Hook/useWindowSize';

import { MOBILE_VIEW_WIDTH } from '../../constants';

import AlertSuccess from '../Alert/Success';
import Confirmation from '../ConfirmationBox';
import { PrimaryCustomButton } from '../CustomButton';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: { width: '100vw' },
  [theme.breakpoints.up('md')]: { width: '900px' },
  [theme.breakpoints.up('lg')]: { width: '1200px' },
}));

export function FullScreenOverlay({
  title,
  isEdit,
  isForm,
  isView,
  buttonTitle,
  RenderComponent,
  disabled,
  isConfirmBeforeClose,
  ...rest
}) {
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  const [save, setSave] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    if (isConfirmBeforeClose) setSave(true);
    if (!isConfirmBeforeClose) setOpen(false);
  };

  const handleConfirmClose = () => setOpen(false);

  const handleSuccess = () => {
    setIsSuccess(true);
    setOpen(false);
  };

  const isMobileScreen = windowSize.width <= MOBILE_VIEW_WIDTH;

  return (
    <>
      {isSuccess && <AlertSuccess handleClose={setIsSuccess} />}
      {save && (
        <Confirmation
          handler={handleConfirmClose}
          message="Are you sure to close this form before submit?"
          setSave={setSave}
        />
      )}
      {isForm && (
        <>
          {buttonTitle && isEdit && !isMobileScreen && (
            <PrimaryCustomButton
              disabled={disabled}
              onClick={handleClickOpen}
              data-testid="testEditForm"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {buttonTitle}
            </PrimaryCustomButton>
          )}
          {buttonTitle && isEdit && isMobileScreen && (
            <IconButton
              disabled={disabled}
              onClick={handleClickOpen}
              sx={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                color: '#52bbab',
                padding: '0 0.5rem',
              }}
            >
              {buttonTitle}
            </IconButton>
          )}
          {!buttonTitle && isEdit && (
            <IconButton onClick={handleClickOpen} disabled={disabled}>
              <EditIcon />
            </IconButton>
          )}
          {!isEdit && buttonTitle && (
            <PrimaryCustomButton
              disabled={disabled}
              onClick={handleClickOpen}
              data-testid="testAddForm"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {buttonTitle}
            </PrimaryCustomButton>
          )}
          {!isEdit && !buttonTitle && (
            <PrimaryCustomButton
              disabled={disabled}
              onClick={handleClickOpen}
              data-testid="testAddForm"
              sx={{ display: 'flex', height: '25px' }}
            >
              <AddIcon />
            </PrimaryCustomButton>
          )}
        </>
      )}

      {isView && (
        <PrimaryCustomButton
          disabled={disabled}
          onClick={handleClickOpen}
          data-testid="testViewDetails"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {buttonTitle}
        </PrimaryCustomButton>
      )}
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <StyledBox role="presentation">
          <AppBar
            sx={{
              position: 'relative',
              backgroundColor: '#52bbab',
              ...(isMobileScreen && { position: 'sticky', top: 0 }),
            }}
          >
            <Toolbar>
              <Typography
                sx={{
                  flex: 1,
                  fontSize: '1.875rem',
                  fontWeight: 600,
                  color: 'black',
                }}
              >
                {title}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                data-testid="testClose"
                aria-label="close"
              >
                <CloseIcon sx={{ color: 'black' }} />
              </IconButton>
            </Toolbar>
          </AppBar>

          <RenderComponent {...rest} setOpen={handleSuccess} />
        </StyledBox>
      </Drawer>
    </>
  );
}
