import { Children, cloneElement } from 'react';
import { useLocation } from 'react-router-dom';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import { useTeamGetQueryById } from '../../Common/Queries/teamQueries';
import { useUserGetQueryById } from '../../Common/Queries/usersQueries';

import { isValidID } from '../../utils';

import { INTERVIEWS_ENDPOINT, TEAM_REQUIREMENTS_END_POINT } from '../../constants';

export function ToolbarContainer({ children }) {
  const urlQuery = useURLQuery();
  const location = useLocation().pathname;
  const { teamName, userName } = useLocation().state ?? {};
  const teamId =
    location === TEAM_REQUIREMENTS_END_POINT &&
    isValidID(urlQuery.get('teamId')) &&
    urlQuery.get('teamId');
  const userId =
    location === INTERVIEWS_ENDPOINT &&
    isValidID(urlQuery.get('userID')) &&
    Number(urlQuery.get('userID'));

  const teamData = useTeamGetQueryById({ reqId: teamId }, { enabled: !teamName && teamId > 0 });
  const userData = useUserGetQueryById(userId, { enabled: !userName && userId > 0 });

  const child = Children.only(children);
  return cloneElement(child, { teamData, userData });
}
