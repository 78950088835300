import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import {
  APPLICATIONS_ENDPOINT,
  BASE_URL,
  CAPTCHA_ENDPOINT,
  DUPLICATE_CHECK_ENDPOINT,
  FILE_UPLOAD,
} from '../../constants';

/**
 * Creates a new Application.
 * @Applications
 */
export class Applications {
  static instance;

  static getInstance() {
    if (!this.instance) {
      this.instance = new Applications(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.fetcher = fetcher;
    this.base_url = BASE_URL;
    this.endpoint = APPLICATIONS_ENDPOINT;
    this.captcha_endpoint = CAPTCHA_ENDPOINT;
    this.duplicate_check = DUPLICATE_CHECK_ENDPOINT;
    this.file_upload_endpoint = FILE_UPLOAD;
  }

  /**
   * this function is to get all applications
   * @function getAllApplications
   ** @param {reqParam} default parameter
   */

  async getAllApplications(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }

  /**
   * this function is to get application by Id
   * @function getApplicationById
   ** @param {reqId} default parameter
   */

  async getApplicationById(reqId) {
    const url = `${this.base_url + this.endpoint}/${reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
  /**
   * this function is to get resume of a application
   * @function getApplicationResume
   ** @param {reqId} default parameter
   */

  async getApplicationResume(reqId) {
    const url = `${this.base_url + this.endpoint}/${reqId}/resume`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  async getFileUploadURL() {
    const url = `${this.base_url + this.file_upload_endpoint}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
  /**
   * this function is to update application by Id
   * @function putApplicationById
   ** @param {reqParams} default parameter
   */

  async putApplicationById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}`;
    const response = await this.fetcher.putData(url, reqParams.reqBody);
    return response?.data;
  }

  /**
   * this function is to update application by Id
   * @function putApplicationReopenById
   ** @param {reqParams} default parameter
   */

  async putApplicationReopenById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}/reopen`;
    const response = await this.fetcher.putData(url, reqParams.reqBody);
    return response?.data;
  }

  /**
   * this function is to update application screening by Id
   * @function putApplicationScreeningById
   ** @param {reqParams} default parameter
   */

  async putApplicationScreeningById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}/pre-screening`;
    const response = await this.fetcher.putData(url, reqParams.reqBody);
    return response?.data;
  }

  /**
   * this function is to get recaptcha for uploading resume
   * @function getCaptcha
   ** @param {reqId} default parameter
   */

  async getCaptcha() {
    const url = `${this.base_url + this.captcha_endpoint}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  /**
   * this function is to post application
   * @function postApplication
   ** @param {reqBody} default parameter
   */

  async postApplication(reqBody) {
    const url = `${this.base_url + this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }

  /**
   * this function is to get application by Id
   * @function getApplicationById
   ** @param {reqId} default parameter
   */

  async getApplicationOfferById(reqId) {
    const url = `${this.base_url + this.endpoint}/${reqId}/offers`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  /**
   * this function is to post application
   * @function postApplicationOffer
   ** @param {reqParam} default parameter
   */

  async postApplicationOffer(reqParam) {
    const url = `${this.base_url + this.endpoint}/${reqParam.reqId}/offers`;
    const response = await this.fetcher.postData(url, reqParam.reqBody);
    return response?.data;
  }

  /**
   * this function is to put application
   * @function putApplicationOfferById
   ** @param {reqParam} default parameter
   */

  async putApplicationOfferById(reqParam) {
    const url = `${this.base_url + this.endpoint}/${reqParam.reqId}/offers`;
    const response = await this.fetcher.putData(url, reqParam.reqBody);
    return response?.data;
  }

  /**
   * this function is to check duplicate application
   * @function postDuplicateCheck
   ** @param {reqParam} default parameter
   */

  async postDuplicateCheck(reqBody) {
    const url = `${this.base_url}${this.duplicate_check}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }
}

export const createApplications = () => Applications.getInstance();
