import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Divider, Drawer, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import SideBarUIWrapper from '../Components/SideBar';
import ToolBar from '../Components/ToolBar';

import useWindowSize from '../Common/Hook/useWindowSize';

import { decodeJwt, isLogin } from '../utils';

import {
  DASHBOARD_ENDPOINT,
  drawerWidth,
  LOGGED_IN_USER_INFO,
  MOBILE_VIEW_WIDTH,
  OtherContext,
} from '../constants';

import { getParsedValueFromLocalStorage } from '../services/localStorage';

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobileScreen',
})(({ theme, open, isMobileScreen }) => ({
  backgroundColor: theme.palette.color.white,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    padding: '0 !important',
    width: `calc(100% - ${drawerWidth}rem)`,
    marginLeft: `${drawerWidth}rem`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(isMobileScreen && { marginLeft: theme.spacing(0), width: '100%' }),
}));
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(1),
  position: 'sticky',
  top: theme.spacing(0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  backgroundColor: theme.palette.color.white,
}));
const HeaderText = styled('span')(({ theme }) => ({
  color: theme.palette.color.default,
  fontWeight: '700',
  fontSize: theme.spacing(3),
  lineHeight: '30px',
}));
const HeaderTextSide = styled('span')(({ theme }) => ({
  color: theme.palette.color.white,
}));

export default function RouterComponentUI() {
  const windowSize = useWindowSize();
  const { isFullScreen, handleDrawerClose, handleDrawerOpen } = useContext(OtherContext);
  const isMobileScreen = windowSize.width <= MOBILE_VIEW_WIDTH;
  const storedUserInfo = getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO);
  const claims = decodeJwt(storedUserInfo?.accessToken);
  const tenantName = claims?.['tenant-name'] || '';
  useEffect(() => {
    if (isMobileScreen) {
      handleDrawerClose();
    }
  }, [isMobileScreen]);
  return (
    <>
      {isLogin() ? (
        <>
          <StyledAppBar position="fixed" open={isFullScreen} isMobileScreen={isMobileScreen}>
            <Toolbar
              sx={{
                width: '100%',
                ...(!isMobileScreen && { padding: '0rem 2rem !important' }),
                ...(isMobileScreen && { padding: '0rem 1rem !important' }),
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ position: 'fixed', mr: 2, ...(isFullScreen && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <ToolBar />
            </Toolbar>
          </StyledAppBar>
          <Drawer
            sx={{
              width: `${drawerWidth}rem`,
              flexShrink: 0,
              '& .MuiDrawer-paper': { width: `${drawerWidth}rem`, boxSizing: 'border-box' },
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            variant={isMobileScreen ? 'temporary' : 'persistent'}
            anchor="left"
            open={isFullScreen}
            onClose={handleDrawerClose}
          >
            <DrawerHeader>
              <Link
                to={DASHBOARD_ENDPOINT}
                className="link"
                onClick={isMobileScreen ? handleDrawerClose : () => null}
                id="hiring-motion-menu"
              >
                <span className="hr-name">Hiring</span>
                <span className="dashboard-name">&nbsp;Motion</span>
                {storedUserInfo?.tenants?.length > 1 && (
                  <span className="org-name">{tenantName}</span>
                )}
              </Link>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider sx={{ position: 'sticky', top: 64 }} />
            <SideBarUIWrapper handleDrawerClose={isMobileScreen ? handleDrawerClose : () => null} />
          </Drawer>
        </>
      ) : (
        <AppBar position="fixed" sx={{ boxShadow: 'none', padding: '20px' }}>
          <HeaderText>
            Hiring
            <HeaderTextSide>&nbsp;Motion</HeaderTextSide>
          </HeaderText>
        </AppBar>
      )}
    </>
  );
}
