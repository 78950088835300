import React, { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

const blockInvalidChar = (e) => {
  return ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
};

export default function InputNumber({
  value = '',
  handleChange = () => null,
  isRequired = false,
  isError = false,
  errorMessage = '',
  placeholder = 'Enter value here',
  name = 'input',
  className = '',
  isDisabled = false,
  inputProps = { 'data-testid': 'testNumber', min: 0 },
  label = '',
}) {
  const numberRef = useRef();
  // to avoid mouse wheel effect
  useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    numberRef?.current?.addEventListener('wheel', handleWheel);
    return () => {
      numberRef?.current?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <TextField
      sx={{ marginTop: '5px' }}
      type="number"
      value={value || ''}
      name={name}
      label={label}
      onChange={handleChange}
      onKeyDown={blockInvalidChar}
      onBlur={handleChange}
      className={className}
      fullWidth
      variant="outlined"
      ref={numberRef}
      disabled={isDisabled}
      required={isRequired}
      helperText={errorMessage}
      error={isError}
      placeholder={placeholder}
      inputProps={inputProps}
    />
  );
}
