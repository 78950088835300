import { Children, cloneElement } from 'react';

import { useInterviewsPutQueryById } from '../../../Common/Queries/interviewsQueries';

/**
 * this function is utility function for formate the data in required formate
 * @param {*} values previous values
 * @param {*} editedValue changed values
 * @returns values in required formate
 */
export const formateData = (values, editedValue) => {
  return {
    application: values.application,
    round: values.round,
    status: values.status,
    versionKey: values.versionKey,
    startTime: values.startTime,
    duration: values.duration,
    message: values.message || '',
    customLink: values.customLink || '',
    interviewer: editedValue.interviewer,
    text: editedValue.reason,
    timeZone: values.timeZone || 'Asia/Kolkata',
    location: values.location || 'ONLINE',
  };
};

export function UpdateInterviewerContainer({ children, interview, id }) {
  const putToInterview = useInterviewsPutQueryById();

  const handleUpdate = (finalValues) => {
    const reqBody = formateData(interview, finalValues);
    const reqParams = { reqBody, reqId: id };
    putToInterview.mutate(reqParams);
  };

  return Children.only(
    cloneElement(children, { putToInterview, interview, handleUpdateInterviewer: handleUpdate })
  );
}
