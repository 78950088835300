import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createApplicant } from '../../Repositories';

const applicantReq = createApplicant();
export function useApplicantGetQueryById(reqId, options) {
  return useQuery(['applicantGetQueryById', reqId], () => applicantReq.getApplicantById(reqId), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

export function useApplicantPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['useApplicantPutQueryById'], (args) => applicantReq.putApplicantById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['applicantGetQueryById']);
    },
  });
}
