import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createSkills } from '../../Repositories';

const skillReq = createSkills();

/**
 * this function(query) is to get All skills
 * @function useSkillsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useSkillsGetQuery(reqParams, options) {
  return useQuery(['skillGetQuery', reqParams], () => skillReq.getAllSkill(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to get skills by ids
 * @function useSkillGetQueryById
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useSkillGetQueryById(reqParams, options) {
  return useQuery(['skillGetQueryById', reqParams], () => skillReq.getSkillById(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post skill
 * @function useAddSkillQuery
 * @returns api response
 */
export function useAddSkillQuery() {
  const queryClient = useQueryClient();
  return useMutation(['skillPostQuery'], (args) => skillReq.postSkill(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['skillGetQuery']);
    },
  });
}

/**
 * this function(query) is to put skill by Id
 * @function useUpdateSkillQueryById
 * @returns api response
 */
export function useUpdateSkillQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['skillPutQueryById'], (args) => skillReq.putSkillById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['skillGetQuery']);
    },
  });
}

/**
 * this function(query) is to delete skill by Id
 * @function useDeleteSkillQueryById
 * @returns api response
 */
export function useDeleteSkillQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['skillDeleteQueryById'], (args) => skillReq.deleteSkillById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['skillGetQuery']);
    },
  });
}
