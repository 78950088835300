import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import {
  useUsersDeleteQueryById,
  useUsersGetQuery,
  useUsersPutQueryById,
} from '../../Common/Queries/usersQueries';

import { isValidID } from '../../utils';

import { AuthContext } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = ['status', 'roles', 'searchValue', 'page', 'order', 'orderBy'];
  return keyArray.every((key) => {
    if (key === keyArray[0] || key === keyArray[1]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[2]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[3]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[4]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return (
      filterObject[key] === '' || filterObject[key] === 'name' || filterObject[key] === 'createdAt'
    );
  });
};

const initialValues = {
  status: [],
  roles: [],
  searchValue: '',
  order: 'desc',
  orderBy: 'createdAt',
  page: 0,
};

export const UserListContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const initialStatus = urlQuery.get('status');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialSearch = urlQuery.get('search');
  const initialRole = urlQuery.get('role');

  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-user`);

  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);

  const initialFilterValuesToApply = {
    ...initialValues,
    page: initialPage ? Number(initialPage - 1) : 0,
    ...(filterValuesObj && validateFilterObject(filterValuesObj)
      ? {
          status: initialStatus ? initialStatus.split(',') : filterValuesObj.status,
          roles: initialRole ? initialRole.split(',') : filterValuesObj.roles,
          searchValue: initialSearch || filterValuesObj.searchValue,
        }
      : {
          status: initialStatus ? initialStatus.split(',') : [],
          roles: initialRole ? initialRole.split(',') : [],
          searchValue: initialSearch || '',
        }),
  };

  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  const users = useUsersGetQuery({
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    status: filters.status.join(','),
    role: filters.roles.join(','),
    q: filters.searchValue,
    limit: 20,
  });

  const putToUser = useUsersPutQueryById();
  const deleteToUser = useUsersDeleteQueryById();

  const handleOnPut = (id, status) => {
    const versionKeyArray = users.data.data.filter((item) => item.id === id);
    const reqParams = { reqBody: { ...versionKeyArray[0], status }, reqId: id };
    putToUser.mutate(reqParams);
  };

  const handleOnDelete = (id) => () => {
    const versionKeyArray = users.data.data.filter((item) => item.id === id);
    const reqParams = { reqBody: { ...versionKeyArray[0] }, reqId: id };
    deleteToUser.mutate(reqParams);
  };

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, {
    users,
    putToUser,
    deleteToUser,
    handleOnPutUser: handleOnPut,
    handleOnDeleteUser: handleOnDelete,
    filterHandler: handleOnFilterChange,
    filters,
  });
};
