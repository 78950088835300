import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, LEVELS_ENDPOINT } from '../../constants';

export class Levels {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Levels(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = LEVELS_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getAllLevel(req) {
    const queryString = queryBuilder(req);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }

  async getLevelById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  async putLevelById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.putData(url, req.reqBody);
    return response?.data;
  }

  async postLevel(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }

  async deleteLevelById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.deleteData(url, req.reqBody);
    return response;
  }
}

export const createLevels = () => Levels.getInstance();
