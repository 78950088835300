import React from 'react';
import { FormControl, FormHelperText, Grid, Select } from '@mui/material';

import { InputText } from '../../Input';
import { GridDiv, Required } from '../../utility';

const skillsTypeOptions = [
  { name: 'Beginner', value: 'beginner', key: '1' },
  { name: 'Intermediate', value: 'intermediate', key: '2' },
  { name: 'Advance', value: 'advance', key: '3' },
];

export default function Skills({
  state,
  handleSkillsValidation,
  isTouch,
  handleFormFields,
  handleAddAndRemoveSection,
}) {
  return (
    <div className="applicant-same-row-border">
      <span className="inputName-applicant">Skills</span>
      {state.skills.map((skill, i) => {
        const { name, level } = state.invalidFields.skills[i];
        return (
          <div
            key={`skill-${i + 1}`}
            className="applicant-same-row-flex"
            onBlur={() => handleSkillsValidation(state.skills)}
          >
            <GridDiv>
              <Grid container spacing={2}>
                <Grid item md={9} xs={12}>
                  <div className="application-same-row-wrapper">
                    <InputText
                      placeholder="Enter skill"
                      name="skill-name"
                      required
                      value={skill.name}
                      sx={{ margin: '0px' }}
                      id={`skill-${i}`}
                      error={name.valid && isTouch.skillsTouch}
                      helperText={name.errorMsg}
                      inputProps={{ 'data-testid': 'testSkillText' }}
                      onChange={(e) => handleFormFields(e, i)}
                      onBlur={(e) => handleFormFields(e, i)}
                    />
                    <Required>*</Required>
                  </div>
                </Grid>
                <Grid item md={2} xs={9}>
                  <div className="application-same-row-wrapper">
                    <FormControl
                      required
                      variant="outlined"
                      error={level.valid && isTouch.skillsTouch}
                      fullWidth
                      sx={{ margin: '0px' }}
                    >
                      <Select
                        fullWidth
                        sx={{ height: '40px' }}
                        value={skill.level}
                        required
                        native
                        onChange={(e) => handleFormFields(e, i)}
                        onBlur={(e) => handleFormFields(e, i)}
                        inputProps={{
                          'data-testid': 'testSkillLevel',
                          name: 'skill-level',
                          id: `skill-level-${i}`,
                        }}
                      >
                        <option aria-label="None" value="" disabled>
                          Select
                        </option>
                        {skillsTypeOptions.map(({ key, name: skillName, value }) => (
                          <option key={key} value={value}>
                            {skillName}
                          </option>
                        ))}
                      </Select>
                      <FormHelperText>{level.errorMsg}</FormHelperText>
                    </FormControl>
                    <Required> *</Required>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="remove-button">
                    <span
                      data-testid="testRemoveSkillButton"
                      className="remove-another-section-text"
                      id={`remove-skill-button-${i}`}
                      role="button"
                      onClick={() => {
                        handleAddAndRemoveSection('skill', i);
                      }}
                      tabIndex={0}
                      onKeyDown={() => {}}
                    >
                      -
                    </span>
                  </div>
                </Grid>
              </Grid>
            </GridDiv>
          </div>
        );
      })}
      <div className="add-remove-button-container_application_form">
        <span
          data-testid="testAddSkillButton"
          className="add-another-section-text_application_form"
          id="skill-add-button"
          role="button"
          onClick={() => {
            handleAddAndRemoveSection('skill');
          }}
          tabIndex={0}
          onKeyDown={() => {}}
        >
          Add
        </span>
      </div>
    </div>
  );
}
