import { Children, cloneElement, useEffect, useState } from 'react';

import { useUpdateTeamRequirementsQuery } from '../../Common/Queries/teamQueries';

export const formateData = (values) => {
  return { ...values, openings: Number(values.openings), deadline: values.deadline.slice(0, 10) };
};
export function EditTeamRequirementContainer({ setOpen, children, formData }) {
  const updateTeamRequirements = useUpdateTeamRequirementsQuery();
  const [values, setValues] = useState(formData);

  useEffect(() => setValues(formData), [formData]);

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   */
  function handleOnSubmit(rBody) {
    const body = formateData(rBody);
    const req = {
      body: { ...formData, ...body },
      params: { requirementId: Number(formData.id) },
    };
    updateTeamRequirements.mutate(req);
  }

  useEffect(() => {
    if (updateTeamRequirements.isSuccess) setOpen(false);
  }, [updateTeamRequirements.isSuccess]);

  const child = Children.only(children);
  return cloneElement(child, { values: values || {}, updateTeamRequirements, handleOnSubmit });
}
