import { Children, cloneElement } from 'react';

import {
  useApplicationsGetQueryById,
  useApplicationsPutQueryById,
  useApplicationsReopenQueryById,
} from '../../../Common/Queries/applicationsQueries';

export function ApplicationDetailContainer({ children, id, props }) {
  const applicant = useApplicationsGetQueryById(id, {});
  const putToApplication = useApplicationsPutQueryById();
  const reopenApplication = useApplicationsReopenQueryById();

  return Children.only(
    cloneElement(children, { applicant, putToApplication, reopenApplication, id, ...props })
  );
}
