// @ts-check

/**
 * @function isValidID
 * @param {String} ids
 * @returns whether all the ids should be integer
 */

export function isValidID(ids) {
  if (!ids || (typeof ids !== 'string' && typeof ids !== 'number')) return false;
  if (typeof ids === 'number') return ids > 0;
  const idsArr = ids.split(',');
  return idsArr.every((id) => Number.isInteger(Number(id)) && Number(id) > 0);
}
