import { Children, cloneElement } from 'react';

import { useApplicantStatsCountGetQuery } from '../../Common/Queries/positionsQueries';

export function ApplicantStatsCountContainer({ children, positionID }) {
  const child = Children.only(children);

  const statsCount = useApplicantStatsCountGetQuery({ reqId: positionID });

  return cloneElement(child, { statsCount });
}
