import { Children, cloneElement, useContext } from 'react';

import { usePositionsGetQuery } from '../../Common/Queries/positionsQueries';

import { AuthContext } from '../../constants';

export function MyOpenPositionsContainer({ children }) {
  const { userInfo } = useContext(AuthContext);
  const child = Children.only(children);

  const positions = usePositionsGetQuery({
    userID: userInfo?.entityID,
    status: 'ACTIVE',
    limit: 0,
  });

  return cloneElement(child, { positions });
}
