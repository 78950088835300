import { Children, cloneElement, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import { usePanelReportsGetQuery } from '../../Common/Queries/panelReportsQueries';

import { isValidDate, isValidID } from '../../utils';

const initialValues = {
  order: 'asc',
  orderBy: 'name',
  searchValue: '',
  page: 0,
  fromDate: '',
  toDate: '',
  selectedStatus: 'month',
  showRange: false,
};

export const PanelReportsListContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const initialSearch = urlQuery.get('search');
  const initialFromDate = isValidDate(urlQuery.get('from')) && urlQuery.get('from');
  const initialToDate = isValidDate(urlQuery.get('to')) && urlQuery.get('to');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialFilterValuesToApply = {
    ...initialValues,
    searchValue: initialSearch || '',
    page: initialPage ? initialPage - 1 : 0,
    ...(initialFromDate &&
      initialToDate && {
        fromDate: initialFromDate,
        toDate: initialToDate,
        selectedStatus: 'dateRange',
        showRange: true,
      }),
    ...(initialFromDate &&
      initialToDate &&
      initialFromDate === initialToDate && {
        fromDate: initialFromDate,
        toDate: initialToDate,
        selectedStatus: 'select',
        showRange: false,
      }),
  };

  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  const panelReports = usePanelReportsGetQuery({
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    'dateRange.fromDate': filters.fromDate,
    'dateRange.toDate': filters.toDate,
    'dateRange.includeDate': true,
    q: filters.searchValue.length >= 2 ? filters.searchValue : '',
    limit: 20,
  });

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, { panelReports, filters, filterHandler: handleOnFilterChange });
};
