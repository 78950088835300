import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, INTERVIEWS_ENDPOINT } from '../../constants';

/**
 * Creates a new Interview.
 * @Interviews
 */
export class Interviews {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Interviews(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = INTERVIEWS_ENDPOINT;
    this.fetcher = fetcher;
  }
  /**
   * this function is to get all interviews
   * @function getAllInterviews
   ** @param {reqParam} default parameter
   */

  getAllInterviews = async (reqParam) => {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);

    return { data: response?.data, meta: response?.meta };
  };
  /**
   * this function is to get interview by Id
   * @function getInterviewById
   ** @param {reqId} default parameter
   */

  getInterviewById = async (reqId) => {
    const url = `${this.base_url + this.endpoint}/${reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  };
  /**
   * this function is to post interview
   * @function postInterview
   ** @param {reqBody} default parameter
   */

  postInterview = async (reqBody) => {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  };

  /**
   * this function is to delete interview by Id
   * @function deleteInterviewById
   ** @param {reqId} default parameter
   */

  deleteInterviewById = async (reqParams) => {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}`;
    const response = await this.fetcher.deleteData(url, reqParams.reqBody);
    return response;
  };
  /**
   * this function is to update Interview by Id
   * @function putInterviewById
   ** @param {reqParams} default parameter
   */

  putInterviewById = async (reqParams) => {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}`;
    const response = await this.fetcher.putData(url, reqParams.reqBody);
    return response?.data;
  };
  /**
   * this function is to get interview by Id
   * @function getFeedbackById
   ** @param {reqId} default parameter
   */

  getInterviewerById = async (reqId) => {
    const url = `${this.base_url}/interviewers/${reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  };

  /**
   * this function is to get interview by Id
   * @function getFeedbackById
   ** @param {reqId} default parameter
   */

  getFeedbackById = async (reqId) => {
    const url = `${this.base_url + this.endpoint}/${reqId}/feedback`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  };

  /**
   * this function is to post feedback by Id
   * @function postFeedbackById
   ** @param {reqBody} default parameter
   */

  postFeedbackById = async (reqParam) => {
    const url = `${this.base_url + this.endpoint}/${reqParam.id}/feedback`;
    const response = await this.fetcher.postData(url, reqParam.reqBody);
    return response?.data;
  };
}

export const createInterviews = () => Interviews.getInstance();
