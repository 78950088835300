import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createPositions } from '../../Repositories';

const positionsReq = createPositions();

/**
 * this function(query) is to get All position
 * @function usePositionsGetQuery
 * @param {object} reqParams params pass along with the api call
 * @param {object} options to be passed as query handling
 * @returns api response
 */
export function usePositionsGetQuery(reqParams, options) {
  return useQuery(['positionsGetQuery', reqParams], () => positionsReq.getAllPosition(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}
/**
 * this function(query) is to get All ApplicantStatsCount
 * @function useApplicantStatsCountGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useApplicantStatsCountGetQuery(reqParams, options) {
  return useQuery(
    ['applicantStatsGetQuery', reqParams],
    () => positionsReq.getAllApplicantStatsCount(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}
/**
 * this function(query) is to get position by Id
 * @function usePositionsGetQueryById
 * @param {object} reqParams params pass along with the api call
 * @param {object} options to be passed as query handling
 * @returns api response
 */
export function usePositionsGetQueryById(reqParams, options) {
  return useQuery(
    ['positionsGetQueryById', reqParams],
    () => positionsReq.getPositionById(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

/**
 * this function(query) is to get applications related to position by Id
 * @function usePositionsKanbanGetQueryById
 * @param {object} reqParams params pass along with the api call
 * @param {object} options to be passed as query handling
 * @returns api response
 */
export function usePositionsKanbanGetQueryById(reqParams, options) {
  return useQuery(
    ['positionsKanbanGetQueryById', reqParams],
    () => positionsReq.getPositionKanbanById(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

/**
 * this function(query) is to post position
 * @function usePositionsPostQuery
 * @returns api response
 */

export function usePositionsPostQuery() {
  const queryClient = useQueryClient();
  return useMutation(['positionsPostQuery'], (args) => positionsReq.postPosition(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['positionsGetQuery']);
      queryClient.invalidateQueries(['dashboardGetQuery']);
    },
  });
}

/**
 * this function(query) is to put position by id
 * @function usePositionsPutQueryById
 * @returns api response
 */
export function usePositionsPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['positionsPutQueryById'], (args) => positionsReq.putPositionById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['positionsGetQuery']);
      queryClient.invalidateQueries(['positionsGetQueryById']);
    },
  });
}

/**
 * this function(query) is to put position status by id
 * @function usePositionsPutStatusQueryById
 * @returns api response
 */
export function usePositionsPutStatusQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['positionsPutStatusQueryById'],
    (args) => positionsReq.putPositionStatusById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['positionsGetQuery']);
        queryClient.invalidateQueries(['positionsGetQueryById']);
      },
    }
  );
}

/**
 * this function(query) is to delete position by id
 * @function usePositionsDeleteQueryById
 * @returns api response
 */

export function usePositionsDeleteQueryById() {
  const queryClient = useQueryClient();
  return useMutation(
    ['positionsDeleteQueryById'],
    (args) => positionsReq.deletePositionById(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['positionsGetQuery']);
        queryClient.invalidateQueries(['positionsGetQueryById']);
      },
    }
  );
}
