import { Children, cloneElement, useEffect } from 'react';

import { useAddTeamRequirementsQuery, useTeamsGetQuery } from '../../Common/Queries/teamQueries';

import { ALL_DATA_LIMIT } from '../../constants';

export const initialValues = {
  name: '',
  details: '',
  deadline: '',
  openings: 0,
  position: '',
  team: {},
};
export function AddTeamRequirementsContainer({ setOpen, children, teamId, positionID, fromWhere }) {
  const addTeamRequirements = useAddTeamRequirementsQuery();
  const teams = useTeamsGetQuery({ limit: ALL_DATA_LIMIT }, { enabled: !teamId });

  function handleOnSubmit(req) {
    const {
      deadline,
      details,
      openings,
      position: { id },
      team,
    } = req;
    addTeamRequirements.mutate({
      body: {
        deadline: deadline.slice(0, 10),
        details,
        openings: Number(openings),
        position: { id: id || Number(positionID) },
        team: { id: Number(teamId) || Number(team.id) },
      },
    });
  }

  useEffect(() => {
    if (addTeamRequirements.isSuccess) setOpen(false);
  }, [addTeamRequirements.isSuccess]);

  return Children.only(
    cloneElement(children, {
      addTeamRequirements,
      positionID,
      teams,
      fromWhere,
      handleOnSubmit,
      initialValues,
      initialValid: {},
    })
  );
}
