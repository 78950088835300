import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createLevels } from '../../Repositories';

const levelReq = createLevels();

/**
 * this function(query) is to get All level
 * @function useLevelsGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useLevelsGetQuery(reqParams, options) {
  return useQuery(['levelGetQuery', reqParams], () => levelReq.getAllLevel(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}
/**
 * this function(query) is to get level by Id
 * @function useLevelGetQueryById
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useLevelGetQueryById(reqParams, options) {
  return useQuery(['levelGetQueryById', reqParams], () => levelReq.getLevelById(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post level
 * @function useAddLevelQuery
 * @returns api response
 */
export function useAddLevelQuery() {
  const queryClient = useQueryClient();
  return useMutation(['levelPostQuery'], (args) => levelReq.postLevel(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['levelGetQuery']);
    },
  });
}

/**
 * this function(query) is to put level by Id
 * @function useUpdateLevelQueryById
 * @returns api response
 */
export function useUpdateLevelQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['levelPutQueryById'], (args) => levelReq.putLevelById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['levelGetQuery']);
    },
  });
}

/**
 * this function(query) is to delete level by Id
 * @function useDeleteLevelQueryById
 * @returns api response
 */
export function useDeleteLevelQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['levelDeleteQueryById'], (args) => levelReq.deleteLevelById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['levelGetQuery']);
    },
  });
}
