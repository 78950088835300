import { faro } from '@grafana/faro-web-sdk';

import { decodeJwt } from '../../utils';

import { ACCESS_TOKEN_ENDPOINT, BASE_URL, LOGGED_IN_USER_INFO } from '../../constants';

import {
  getParsedValueFromLocalStorage,
  removeFromLocalStorage,
  setValueToLocalStorage,
} from '../../services/localStorage';

import { HttpErrors } from './httpErrors';
import { requestHeaders } from './requestHeaders';

export class Fetcher {
  static instance;

  static isRefreshing = false;

  static getInstance() {
    if (this.instance !== undefined) return this.instance;
    this.instance = new Fetcher();
    return this.instance;
  }

  refreshToken = async (payload = '') => {
    if (!getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO)?.refreshToken) {
      return null;
    }
    if (this.isRefreshing) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.isRefreshing) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    }

    this.isRefreshing = true;

    const userData = getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO);
    const claims = decodeJwt(userData?.accessToken);
    const fetchPayload = () => {
      if (payload !== '') return payload;
      return {
        tenantID: claims?.['tenant-id'] || '',
        refreshToken: getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO)?.refreshToken,
      };
    };
    const refreshOptions = {
      method: 'POST',
      headers: requestHeaders(),
      body: JSON.stringify(fetchPayload()),
    };
    let tokens = {};
    try {
      const newToken = await fetch(`${BASE_URL}${ACCESS_TOKEN_ENDPOINT}`, refreshOptions);
      tokens = await newToken.json();
      if (newToken.status >= 400) {
        const [error] = tokens.errors;
        throw new HttpErrors(error, newToken.status);
      }
      const refreshToken = await getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO)?.refreshToken;
      const tenants = await getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO)?.tenants;

      setValueToLocalStorage(
        LOGGED_IN_USER_INFO,
        JSON.stringify({
          accessToken: tokens?.data?.accessToken,
          refreshToken,
          tenants,
        })
      );

      this.isRefreshing = false;
    } catch (error) {
      if (faro && faro.api && faro.api.pushError) {
        faro.api.pushError(error);
      }
      this.isRefreshing = false;
      removeFromLocalStorage(LOGGED_IN_USER_INFO);
    }
    return null;
  };

  sendRequest = async (url, options, count = 0) => {
    /**
     * Call global/window fetch
     */
    let response;
    const latestOptions = { ...options, headers: requestHeaders() };
    try {
      response = await fetch(url, latestOptions);
    } catch (e) {
      // If response is failed to load
      // Then we have connectivity problem
      throw new Error('Unable to connect to the internet. Please check your internet connection.');
    }
    /**
     * extract results from response by calling json()
     */
    let results = {};
    if (response && response.status !== 204) {
      /**
       * 204 means success response with no content
       */
      try {
        results = await response.json();
      } catch (error) {
        throw new HttpErrors(response, response.status);
      }
    }

    if (response?.status >= 400) {
      const [error] = results.errors;
      if (response.status === 401 && count === 0) {
        try {
          await this.refreshToken();
          return this.sendRequest(url, options, count + 1);
        } catch (refreshError) {
          throw new Error('Token refresh failed.');
        }
      }
      if (response.status === 401) {
        removeFromLocalStorage(LOGGED_IN_USER_INFO);
        window.location.reload(false);
      }
      throw new HttpErrors(error, response.status);
    }

    /**
     * if successful response then return data with status
     */
    return { data: results.data, meta: results.meta, status: response.status };
  };

  /**
   * @description Makes get call for the provided URL and returns data if resolved successfully
   * @param {String} url
   */
  getData = async (url) => {
    const options = {
      headers: requestHeaders(),
    };
    const results = await this.sendRequest(url, options);
    return results;
  };

  /**
   * @description makes PUT call with the body and url provided
   * @param {String} url
   * @param {Object} body
   */
  putData = async (url, body) => {
    const options = {
      method: 'PUT',
      headers: requestHeaders(),
      body: JSON.stringify(body),
    };
    const results = await this.sendRequest(url, options);
    return results;
  };

  /**
   * @description makes POST call with the body and url provided
   * @param {String} url
   * @param {Object} body
   */
  postData = async (url, body) => {
    const options = {
      method: 'POST',
      headers: requestHeaders(),
      body: JSON.stringify(body),
    };
    const results = await this.sendRequest(url, options);
    return results;
  };

  /**
   * @description makes DELETE call with the body and url provided
   * @param {String} url
   * @param {Object} body
   */
  deleteData = async (url, body) => {
    const options = {
      method: 'DELETE',
      headers: requestHeaders(),
      body: JSON.stringify(body),
    };
    const results = await this.sendRequest(url, options);
    return results;
  };
}

export const createFetcher = () => Fetcher.getInstance();
