// required module
import React, { useState } from 'react';
import { Slide, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

/**
 * to display alert message
 * @param {*} props
 * @returns Alert
 */
export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Transition(props) {
  return <Slide {...props} direction="right" />;
}

export default function AlertSuccess({ handleClose = () => {} }) {
  // initial states
  const [snackOpen, setSnackOpen] = useState(true);

  /**
   * for closing alert message
   * @function handleSnackClose
   */
  const handleSnackClose = () => {
    setSnackOpen(false);
    handleClose(false);
  };

  // render ui component
  return (
    <Snackbar
      open={snackOpen}
      autoHideDuration={5000}
      onClose={handleSnackClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={Transition}
    >
      <Alert onClose={handleSnackClose} severity="success" sx={{ width: '250px', margin: '1rem' }}>
        Success!
      </Alert>
    </Snackbar>
  );
}
