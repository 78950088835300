import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  BusinessOutlined,
  DescriptionOutlined,
  FindInPageOutlined,
  GroupOutlined,
  PermContactCalendarOutlined,
} from '@mui/icons-material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjectsOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import Collapse from '@mui/material/Collapse';

import { SideBarContainer } from '../../Container';

import { filter4xxError } from '../../utils';

import {
  AGENCIES_ENDPOINT,
  AppContext,
  APPLICATIONS_BETA_ENDPOINT,
  APPLICATIONS_ENDPOINT,
  AuthContext,
  CALENDAR_ENDPOINT,
  DASHBOARD_ENDPOINT,
  HR_REPORTS_ENDPOINT,
  LEVELS_ENDPOINT,
  PANEL_REPORTS_ENDPOINT,
  POSITIONS_ENDPOINT,
  PRE_SCREENING,
  REFERRALS_ENDPOINT,
  RESUME_SCREENING,
  ROLES,
  SETTINGS_ENDPOINT,
  SKILLS_ENDPOINT,
  STREAMS_ENDPOINT,
  TEAM_REQUIREMENTS_END_POINT,
  TEAMS_ENDPOINT,
  TITLES,
  USERS_ENDPOINT,
} from '../../constants';

import { AlertError } from '../Error';

import './sideBar.css';

function SideBarLink({ endpoint, activeCondition, title, MenuIcon }) {
  return (
    <div className="side-bar-active-area">
      <Link to={endpoint} className="side-bar-link-button">
        <div className={`side-bar-button ${activeCondition ? '-active' : ''}`}>
          <div className="side-bar-icon">
            <MenuIcon className="side-bar-icon__size" />
          </div>
          <div className="side-bar-text">{title}</div>
        </div>
      </Link>
    </div>
  );
}

export function SideBar({
  handleDrawerClose,
  positionList,
  statusList,
  departmentList,
  streamList,
  skillList,
  levelList,
  userList,
  agencyList,
}) {
  const location = useLocation();

  const { setAppData } = useContext(AppContext);
  const { userInfo } = useContext(AuthContext);
  const containerRef = useRef(null);
  const role = userInfo?.roles && userInfo?.roles[0];

  const [open, setOpen] = useState(false);
  const handleClickDropDown = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open && containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [open]);

  const handleAppData = (entity, values) =>
    setAppData((prevValues) => ({ ...prevValues, [entity]: values.data }));

  useEffect(() => {
    if (positionList.isSuccess) handleAppData('POSITION_DATA', positionList.data);
    if (userList.isSuccess) handleAppData('USER_DATA', userList.data);
    if (statusList.isSuccess) handleAppData('STATUS_DATA', statusList);
    if (departmentList.isSuccess) handleAppData('DEPARTMENT_DATA', departmentList.data);
    if (streamList.isSuccess) handleAppData('STREAM_DATA', streamList.data);
    if (skillList.isSuccess) handleAppData('SKILL_DATA', skillList.data);
    if (levelList.isSuccess) handleAppData('LEVEL_DATA', levelList.data);
    if (agencyList.isSuccess) handleAppData('AGENCY_DATA', agencyList.data);
  }, [
    positionList.isFetching,
    statusList.isFetching,
    departmentList.isFetching,
    streamList.isFetching,
    skillList.isFetching,
    levelList.isFetching,
    userList.isFetching,
    agencyList.isFetching,
  ]);
  return (
    <div className="side-bar-container">
      {(positionList.isError ||
        userList.isError ||
        statusList.isError ||
        departmentList.isError ||
        streamList.isError ||
        skillList.isError ||
        levelList.isError ||
        agencyList.isError) && (
        <AlertError
          errors={[
            positionList.error,
            userList.error,
            statusList.error,
            departmentList.error,
            streamList.error,
            skillList.error,
            levelList.error,
            agencyList.error,
          ]}
          errorMapper={filter4xxError}
        />
      )}
      <div
        className="side-bar-with-button"
        onClick={handleDrawerClose}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <div className="side-bar-buttons">
          <SideBarLink
            endpoint={DASHBOARD_ENDPOINT}
            title={TITLES.DASHBOARD}
            MenuIcon={DashboardIcon}
            activeCondition={location.pathname === DASHBOARD_ENDPOINT}
          />
          <SideBarLink
            endpoint={POSITIONS_ENDPOINT}
            title={TITLES.POSITIONS}
            MenuIcon={EmojiObjectsIcon}
            activeCondition={
              location.pathname === POSITIONS_ENDPOINT ||
              location.pathname.slice(0, 11) === `${POSITIONS_ENDPOINT}/`
            }
          />
          {role !== ROLES.AGENCY && (
            <SideBarLink
              endpoint={APPLICATIONS_ENDPOINT}
              title={TITLES.APPLICATIONS}
              MenuIcon={DescriptionOutlined}
              activeCondition={
                location.pathname === APPLICATIONS_ENDPOINT ||
                location.pathname.slice(0, 14) === `${APPLICATIONS_ENDPOINT}/`
              }
            />
          )}
          {role !== ROLES.AGENCY && (
            <SideBarLink
              endpoint={APPLICATIONS_BETA_ENDPOINT}
              title={TITLES.APPLICATIONS_BETA}
              MenuIcon={DescriptionOutlined}
              activeCondition={
                location.pathname === APPLICATIONS_BETA_ENDPOINT ||
                location.pathname.slice(0, 19) === `${APPLICATIONS_BETA_ENDPOINT}/`
              }
            />
          )}
          <SideBarLink
            endpoint={REFERRALS_ENDPOINT}
            title={TITLES.REFERRALS}
            MenuIcon={DescriptionOutlined}
            activeCondition={location.pathname === REFERRALS_ENDPOINT}
          />
          {role !== ROLES.AGENCY && (
            <SideBarLink
              endpoint={CALENDAR_ENDPOINT}
              title={TITLES.CALENDAR}
              MenuIcon={PermContactCalendarOutlined}
              activeCondition={location.pathname === CALENDAR_ENDPOINT}
            />
          )}
          {(role === ROLES.ADMIN ||
            role === ROLES.SUPER_ADMIN ||
            role === ROLES.HIRING_MANAGER) && (
            <SideBarLink
              endpoint={RESUME_SCREENING}
              title={TITLES.RESUME_SCREENING}
              MenuIcon={FindInPageOutlined}
              activeCondition={location.pathname === RESUME_SCREENING}
            />
          )}
          {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN || role === ROLES.HR) && (
            <SideBarLink
              endpoint={PRE_SCREENING}
              title={TITLES.PRE_SCREENING}
              MenuIcon={FindInPageOutlined}
              activeCondition={location.pathname === PRE_SCREENING}
            />
          )}
          {(role === ROLES.HR || role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) && (
            <SideBarLink
              endpoint={PANEL_REPORTS_ENDPOINT}
              title={TITLES.PANEL_REPORTS}
              MenuIcon={AdminPanelSettingsOutlinedIcon}
              activeCondition={location.pathname === PANEL_REPORTS_ENDPOINT}
            />
          )}
          {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) && (
            <SideBarLink
              endpoint={HR_REPORTS_ENDPOINT}
              title={TITLES.HR_REPORTS}
              MenuIcon={AssignmentIndOutlinedIcon}
              activeCondition={location.pathname === HR_REPORTS_ENDPOINT}
            />
          )}
        </div>
      </div>
      {role !== ROLES.AGENCY && role !== ROLES.USER && (
        <div className="side-bar-buttons-items" ref={containerRef}>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className="setting-title-container"
            onClick={handleClickDropDown}
            data-testid="testSettings"
          >
            <div className="setting-title">
              <SettingsIcon />
              <div className="settings-side-bar-text">{TITLES.SETTINGS}</div>
            </div>
            <div className="side-bar-expand-icon">{open ? <ExpandLess /> : <ExpandMore />}</div>
          </div>
          {open && (
            <div
              className="side-bar-with-button settings"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={handleDrawerClose}
            >
              <Collapse in={open} className="side-bar-buttons-items">
                <SideBarLink
                  endpoint={TEAMS_ENDPOINT}
                  title={TITLES.TEAMS}
                  MenuIcon={GroupOutlined}
                  activeCondition={
                    location.pathname === TEAMS_ENDPOINT ||
                    location.pathname === TEAM_REQUIREMENTS_END_POINT
                  }
                />
                <SideBarLink
                  endpoint={LEVELS_ENDPOINT}
                  title={TITLES.LEVELS}
                  MenuIcon={BarChartOutlinedIcon}
                  activeCondition={location.pathname === LEVELS_ENDPOINT}
                />
                <SideBarLink
                  endpoint={STREAMS_ENDPOINT}
                  title={TITLES.STREAMS}
                  MenuIcon={WebOutlinedIcon}
                  activeCondition={location.pathname === STREAMS_ENDPOINT}
                />
                {(role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) && (
                  <div>
                    <SideBarLink
                      endpoint={USERS_ENDPOINT}
                      title={TITLES.USERS}
                      MenuIcon={PeopleAltOutlinedIcon}
                      activeCondition={location.pathname === USERS_ENDPOINT}
                    />

                    <SideBarLink
                      endpoint={SKILLS_ENDPOINT}
                      title={TITLES.SKILLS}
                      MenuIcon={SettingsIcon}
                      activeCondition={location.pathname === SKILLS_ENDPOINT}
                    />
                    <SideBarLink
                      endpoint={AGENCIES_ENDPOINT}
                      title={TITLES.AGENCIES}
                      MenuIcon={BusinessOutlined}
                      activeCondition={location.pathname === AGENCIES_ENDPOINT}
                    />
                  </div>
                )}
                {(role === ROLES.ADMIN || role === ROLES.HR || role === ROLES.SUPER_ADMIN) && (
                  <SideBarLink
                    endpoint={SETTINGS_ENDPOINT}
                    title={TITLES.GENERAL_SETTINGS}
                    MenuIcon={SettingsIcon}
                    activeCondition={location.pathname === SETTINGS_ENDPOINT}
                  />
                )}
              </Collapse>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default function SideBarUIWrapper(props) {
  return (
    <SideBarContainer {...props}>
      <SideBar />
    </SideBarContainer>
  );
}
