import { Children, cloneElement } from 'react';

import { useApplicantGetQueryById } from '../../Common/Queries/applicantQueries';

const applicantId = window.location.pathname.split('/').pop();
export function ApplicantContainer({ children, ...props }) {
  const applicantData = useApplicantGetQueryById(applicantId);

  return Children.only(cloneElement(children, { applicantData, ...props }));
}
