import { useMutation } from '@tanstack/react-query';

import { createRefresh } from '../../Repositories';

const refreshReq = createRefresh();

/**
 * this function(query) is to post refresh
 * @function usePostRefreshQuery
 * @returns api response
 */
export function usePostRefreshQuery() {
  return useMutation(['refreshPostQuery'], (args) => refreshReq.postRefresh(args), {
    retry: false,
  });
}
