import { Children, cloneElement } from 'react';

import {
  useConfigGetQuery,
  useConfigPostQuery,
  useConfigPutQuery,
} from '../../Common/Queries/configQueries';
import { useDepartmentGetQuery } from '../../Common/Queries/departmentQueries';
import {
  useDeleteLocationQueryById,
  useLocationGetQuery,
} from '../../Common/Queries/locationQueries';
import { useMasterInviteTemplateGetQuery } from '../../Common/Queries/masterInviteTemplateQueries';
import {
  useServicePostQuery,
  useServicePutRefreshTokensQuery,
} from '../../Common/Queries/serviceQueries';

export function SettingsContainer({ children }) {
  const child = Children.only(children);
  const inviteTemplate = useMasterInviteTemplateGetQuery();
  const departmentData = useDepartmentGetQuery();
  const locationData = useLocationGetQuery({ limit: 0 });
  const deleteLocationData = useDeleteLocationQueryById();
  const putConfig = useConfigPutQuery();
  const postConfig = useConfigPostQuery();
  const postServices = useServicePostQuery();
  const putRefrehToken = useServicePutRefreshTokensQuery();
  const configData = useConfigGetQuery('', {
    onSuccess: (successData) => {
      if (successData === null) {
        postConfig.mutate({});
      }
    },
  });

  function handleDeleteLocationData(id) {
    const reqBody = locationData?.data?.data?.filter((location) => location.id === id);
    const reqParams = { reqBody, reqId: id };
    deleteLocationData.mutate(reqParams);
  }
  function handleDeletePositionScreenTime(key) {
    const updatedOverrides = configData?.data?.automaticScreeningTime?.overrides?.filter(
      ({ positionID }) => positionID !== key
    );

    const reqParams = {
      ...configData?.data,
      automaticScreeningTime: {
        ...configData?.data?.automaticScreeningTime,
        overrides: updatedOverrides,
      },
    };
    putConfig.mutate(reqParams);
  }

  function handleApplicationPutStatus(status) {
    const reqParams = { ...configData?.data, autoCloseDuplicateApplication: status };
    putConfig.mutate(reqParams);
  }

  function handleInterviewPutStatus(status) {
    const reqParams = {
      ...configData?.data,
      interviewConfig: {
        ...configData?.data?.interviewConfig,
        postStartUpdate: { ...configData?.data?.interviewConfig?.postStartUpdate, enabled: status },
      },
    };
    putConfig.mutate(reqParams);
  }

  function handlePutServices(refreshTokenData) {
    const reqParams = {
      ...configData?.data,
      refreshToken: refreshTokenData,
    };
    putConfig.mutate(reqParams);
  }

  function handlePutCalendarID(value) {
    const reqParams = {
      ...configData?.data,
      calendarID: value.name,
    };
    putConfig.mutate(reqParams);
  }

  return cloneElement(child, {
    inviteTemplate,
    departmentData,
    locationData,
    deleteLocationData,
    handleDeleteLocationData,
    handleDeletePositionScreenTime,
    configData,
    handleApplicationPutStatus,
    handleInterviewPutStatus,
    putConfig,
    handlePutCalendarID,
    postServices,
    handlePutServices,
    putRefrehToken,
    postConfig,
  });
}
