import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, HR_REPORTS_ENDPOINT } from '../../constants';

export class HRReports {
  static getInstance() {
    if (!this.instance) {
      this.instance = new HRReports(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = HR_REPORTS_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getAllHRReports(req) {
    const queryString = queryBuilder(req);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }

  async getHRReportById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
}

export const createHRReports = () => HRReports.getInstance();
