import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, TASKS_ENDPOINT } from '../../constants';

export class Tasks {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Tasks(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = TASKS_ENDPOINT;
    this.fetcher = fetcher;
  }

  async getAllTasks(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  async putTaskById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.putData(url, req.reqBody);
    return response?.data;
  }
}

export const createTasks = () => Tasks.getInstance();
