import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import {
  usePositionsDeleteQueryById,
  usePositionsGetQuery,
  usePositionsPutStatusQueryById,
} from '../../Common/Queries/positionsQueries';

import { isValidID } from '../../utils';

import { AuthContext } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = [
    'status',
    'streams',
    'skills',
    'levels',
    'searchValue',
    'page',
    'order',
    'orderBy',
  ];
  return keyArray.every((key) => {
    if (key === keyArray[0] || key === keyArray[1] || key === keyArray[2] || key === keyArray[3]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[4]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[5]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[6]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return (
      filterObject[key] === '' ||
      filterObject[key] === 'noOfOpenings' ||
      filterObject[key] === 'title' ||
      filterObject[key] === 'createdAt'
    );
  });
};

const initialValues = { streams: [], skills: [], levels: [], order: 'desc', orderBy: 'createdAt' };

export const ListPositionsContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialStream = isValidID(urlQuery.get('streamIDs')) && urlQuery.get('streamIDs');
  const initialSkill = isValidID(urlQuery.get('skillIDs')) && urlQuery.get('skillIDs');
  const initialLevel = isValidID(urlQuery.get('levelIDs')) && urlQuery.get('levelIDs');
  const initialStatus = urlQuery.get('status');
  const initialSearch = urlQuery.get('search');
  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-position`);
  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);

  const initialAppliedStatus =
    filterValuesObj?.status?.length > 0 ? filterValuesObj.status : ['ACTIVE'];

  const initialFilterValuesToApply = {
    ...initialValues,
    page: initialPage ? initialPage - 1 : 0,
    ...(!filterValuesObj || !validateFilterObject(filterValuesObj)
      ? {
          streams: initialStream ? initialStream.split(',').map((item) => Number(item)) : [],
          skills: initialSkill ? initialSkill.split(',').map((item) => Number(item)) : [],
          levels: initialLevel ? initialLevel.split(',').map((item) => Number(item)) : [],
          status: initialStatus ? initialStatus.split(',') : ['ACTIVE'],
          searchValue: initialSearch || '',
        }
      : {
          streams: initialStream
            ? initialStream.split(',').map((item) => Number(item))
            : filterValuesObj.streams,
          skills: initialSkill
            ? initialSkill.split(',').map((item) => Number(item))
            : filterValuesObj.skills,
          levels: initialLevel
            ? initialLevel.split(',').map((item) => Number(item))
            : filterValuesObj.levels,
          status: initialStatus ? initialStatus.split(',') : initialAppliedStatus,
          searchValue: initialSearch || filterValuesObj.searchValue,
        }),
  };

  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  const positions = usePositionsGetQuery({
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    status: filters.status.join(','),
    streamIDs: filters.streams.join(','),
    skillIDs: filters.skills.join(','),
    levelIDs: filters.levels.join(','),
    q: filters.searchValue.length >= 2 ? filters.searchValue : '',
    limit: 20,
  });

  const putPosition = usePositionsPutStatusQueryById();

  const deletePosition = usePositionsDeleteQueryById();

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const handleOnPutStatus = (id, status) => {
    const versionKeyArray = positions.data.data.filter((item) => {
      return item.id === id;
    });
    const reqParams = { reqBody: { ...versionKeyArray[0], status }, reqId: id };
    putPosition.mutate(reqParams);
  };

  const handleOnDeletePosition = (id) => () => {
    const versionKeyArray = positions.data.data.filter((item) => {
      return item.id === id;
    });
    const reqParams = { reqBody: { ...versionKeyArray[0] }, reqId: id };
    deletePosition.mutate(reqParams);
  };

  const child = Children.only(children);
  return cloneElement(child, {
    putPosition,
    deletePosition,
    positions,
    filterHandler: handleOnFilterChange,
    handleOnPutStatus,
    handleOnDeletePosition,
    filters,
  });
};
