import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import {
  useTeamDeleteQueryById,
  useTeamPutQueryById,
  useTeamsGetQuery,
} from '../../Common/Queries/teamQueries';

import { isValidID } from '../../utils';

import { AuthContext } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = ['status', 'positionID', 'searchValue', 'page', 'order', 'orderBy'];
  return keyArray.every((key) => {
    if (key === keyArray[0] || key === keyArray[1]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[2]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[3]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[4]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return (
      filterObject[key] === '' || filterObject[key] === 'name' || filterObject[key] === 'createdAt'
    );
  });
};

const initialValues = {
  status: [],
  positionID: '',
  searchValue: '',
  order: 'desc',
  orderBy: 'createdAt',
  page: 0,
};

export const TeamListContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const initialStatus = urlQuery.get('status');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialSearch = urlQuery.get('search');
  const initialPositionID = isValidID(urlQuery.get('positionID')) && urlQuery.get('positionID');
  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-team`);

  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);

  const initialFilterValuesToApply = {
    ...initialValues,
    page: initialPage ? Number(initialPage - 1) : 0,
    ...(filterValuesObj && validateFilterObject(filterValuesObj) && !initialPositionID
      ? {
          status: initialStatus ? initialStatus.split(',') : filterValuesObj.status,
          positionID: filterValuesObj.positionID,
          searchValue: initialSearch || filterValuesObj.searchValue,
        }
      : {
          status: initialStatus ? initialStatus.split(',') : [],
          positionID: initialPositionID
            ? initialPositionID.split(',').map((item) => Number(item))
            : [],
          searchValue: initialSearch || '',
        }),
  };

  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  const teams = useTeamsGetQuery({
    positionID: filters.positionID.join(','),
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    status: filters.status.join(','),
    q: filters.searchValue,
    limit: 20,
  });

  const deleteTeam = useTeamDeleteQueryById();
  const putToTeam = useTeamPutQueryById();

  const handleOnDelete = (id) => () => {
    const deletedRecord = teams.data.data.filter((requirement) => requirement.id === id)[0];
    const reqBody = { versionKey: deletedRecord.versionKey };
    const reqParams = { reqBody, reqId: id };
    deleteTeam.mutate(reqParams);
  };

  const handleOnPut = (id, status) => {
    const versionKeyArray = teams.data.data.filter((item) => item.id === id);
    const reqParams = { reqBody: { ...versionKeyArray[0], status }, reqId: id };
    putToTeam.mutate(reqParams);
  };

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, {
    teams,
    deleteTeam,
    putToTeam,
    handleOnDeleteTeam: handleOnDelete,
    handleOnPutTeam: handleOnPut,
    filterHandler: handleOnFilterChange,
    filters,
  });
};
