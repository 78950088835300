import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';

import { markdownToHTML } from '../../utils';

import 'quill-mention';

import { createUsers } from '../../Repositories';

import 'react-quill/dist/quill.snow.css';
import './editor.css';

export default function QuillEditorWithoutCounter({
  handleMentionedUserList,
  description,
  onChangeSelection,
  placeholder,
  quillRef,
  isMentionAllowed,
}) {
  const modules = useMemo(() => {
    return {
      toolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
      ],
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        minChars: 2,
        defaultMenuOrientation: 'top',
        positioningStrategy: 'fixed',
        onSelect: (item, insertItem) => {
          const { id, value } = item;
          insertItem(item);
          handleMentionedUserList({ id, value });
        },
        renderLoading: () => '<div>Loading...</div>',
        async source(searchTerm, renderItem) {
          const usersReq = createUsers();
          const matchedPeople = await usersReq.getAllUsers({
            q: searchTerm,
          });

          const myPeople = matchedPeople.data.map((user) => ({
            id: user.id,
            value: user.name,
          }));
          renderItem(myPeople);
        },
      },
    };
  }, [isMentionAllowed]);
  const formats = ['header', 'bold', 'italic', 'list', 'bullet', 'link', 'mention'];

  const MarkedDescription = markdownToHTML(description);
  const mod = { ...modules };
  if (!isMentionAllowed) {
    delete mod.mention;
  }

  return (
    <ReactQuill
      theme="snow"
      modules={mod}
      formats={formats}
      value={MarkedDescription}
      onChange={onChangeSelection}
      bounds={'[data-text-editor="name"]'}
      placeholder={placeholder}
      ref={quillRef}
    />
  );
}
