import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, TEAM_REQUIREMENTS_END_POINT, TEAMS_ENDPOINT } from '../../constants';

export class Teams {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Teams(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = TEAMS_ENDPOINT;
    this.requirement_endpoint = TEAM_REQUIREMENTS_END_POINT;
    this.fetcher = fetcher;
  }

  async getAllTeam(req) {
    const queryString = queryBuilder(req);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }

  async getTeamById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  async putTeamById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.putData(url, req.reqBody);
    return response?.data;
  }

  async postTeam(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }

  async deleteTeamById(req) {
    const url = `${this.base_url + this.endpoint}/${req.reqId}`;
    const response = await this.fetcher.deleteData(url, req.reqBody);
    return response;
  }

  getTeamRequirements = async (req) => {
    const queryString = queryBuilder(req.queryParams);
    const url = `${this.base_url}${this.requirement_endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response;
  };

  updateTeamRequirement = async (req) => {
    const url = `${this.base_url}${this.requirement_endpoint}/${req.params.requirementId}`;
    const response = await this.fetcher.putData(url, req.body);

    return response;
  };

  addTeamRequirement = async (req) => {
    const url = `${this.base_url}${this.requirement_endpoint}`;
    const response = await this.fetcher.postData(url, req.body);
    return response;
  };

  deleteTeamRequirementById = async (req) => {
    const url = `${this.base_url}${this.requirement_endpoint}/${req.params.id}`;
    const response = await this.fetcher.deleteData(url, req.body);
    return response;
  };
}

export const createTeams = () => Teams.getInstance();
