import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, USERS_ENDPOINT } from '../../constants';

/**
 * Creates a new User.
 * @Users
 */
export class Users {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Users(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = USERS_ENDPOINT;
    this.fetcher = fetcher;
  }
  /**
   * this function is to get all Users
   * @function getAllUsers
   ** @param {reqParam} default parameter
   */

  async getAllUsers(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }

  /**
   * this function is to get user by Id
   * @function getUserById
   ** @param {reqId} default parameter
   */

  async getUserById(reqId) {
    const url = `${this.base_url}/users/${reqId}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  /**
   * this function is to post user
   * @function postUser
   ** @param {Object} reqBody
   */

  async postUser(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }

  /**
   * this function is to update user by Id
   * @function putUserById
   ** @param {Object} reqParams parameter
   */

  async putUserById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}`;
    const response = await this.fetcher.putData(url, reqParams.reqBody);
    return response?.data;
  }

  /**
   * this function is to delete user by Id
   * @function deleteUserById
   ** @param {Object} reqParams parameter
   */

  async deleteUserById(reqParams) {
    const url = `${this.base_url + this.endpoint}/${reqParams.reqId}`;
    const response = await this.fetcher.deleteData(url, reqParams.reqBody);
    return response?.data;
  }
}

export const createUsers = () => Users.getInstance();
