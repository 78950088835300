import { Children, cloneElement, React, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import {
  useDeleteTeamRequirementsByIdQuery,
  useGetTeamRequirementsQuery,
  useTeamGetQueryById,
} from '../../Common/Queries/teamQueries';

import { isValidID } from '../../utils';

import { TEAMS_ENDPOINT } from '../../constants';

const ACTIVE_STATUS = 'OPEN';

export function TeamRequirementsListContainer({ children }) {
  const urlQuery = useURLQuery();
  const { teamName } = useLocation().state ?? {};
  const teamId = isValidID(urlQuery.get('teamId')) && urlQuery.get('teamId');

  // If teamId is not present redirecting to team page
  if (!teamId) {
    return <Navigate to={TEAMS_ENDPOINT} />;
  }
  const initialStatus = urlQuery.get('status');
  const initialPositionID = isValidID(urlQuery.get('positionID')) && urlQuery.get('positionID');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');

  const [filters, updateFilters] = useState({
    status: initialStatus ? initialStatus.split(',') : [ACTIVE_STATUS],
    positionID: initialPositionID ? initialPositionID.split(',').map((item) => Number(item)) : [],
    order: 'desc',
    orderBy: 'createdAt',
    page: initialPage ? Number(initialPage - 1) : 0,
    limit: 20,
  });

  const teamRequirements = useGetTeamRequirementsQuery({
    queryParams: {
      teamID: teamId,
      positionID: filters.positionID.join(','),
      status: filters.status.join(','),
      sort: filters.order.toUpperCase(),
      orderBy: filters.orderBy,
      page: Number(filters.page + 1),
      limit: filters.limit,
    },
  });

  const teamData = useTeamGetQueryById({ reqId: teamId }, { enabled: !teamName });

  const deleteTeamRequirement = useDeleteTeamRequirementsByIdQuery();

  const handleOnDelete = (id) => () => {
    const deletedRecord = teamRequirements.data.data.filter(
      (requirement) => requirement.id === id
    )[0];
    deleteTeamRequirement.mutate({
      body: { versionKey: deletedRecord.versionKey },
      params: { id: Number(id) },
    });
  };

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, {
    teamRequirements,
    deleteTeamRequirement,
    teamId,
    teamData,
    handleOnDeleteTeamRequirements: handleOnDelete,
    filterHandler: handleOnFilterChange,
    filters,
  });
}
