import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AuthContext, LOGGED_IN_USER_INFO, USER_LOGOUT_ENDPOINT } from '../../../constants';

import { getParsedValueFromLocalStorage } from '../../../services/localStorage';
import OrganisationModal from '../../Organization/OrganisationModal';

import './logout.css';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    border: theme.palette.border.menu,
    boxShadow: '0 4px 8px 3px rgba(0,0,0,.15), 0 1px 3px rgba(0,0,0,.3)',
  },
}));

const StyledMenuItem = styled(MenuItem)({ fontSize: '14px' });

export default function LogoutWrapper({ anchorEl, handleClose }) {
  const [modalState, setModalState] = useState(false);
  const [tenantList, setTenantList] = useState(getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO));
  const { userInfo } = useContext(AuthContext);
  const profileName = userInfo?.name;
  const email = userInfo?.sub;

  const handleModalState = () => {
    setTenantList(getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO) ?? {});
    setModalState(true);
  };
  const handleCloseOrgModal = () => {
    handleClose();
    setModalState(false);
  };
  return (
    <>
      <OrganisationModal
        modalState={modalState}
        handleCloseOrgModal={handleCloseOrgModal}
        tenantsData={tenantList}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="profile-profile">
          <div className="profile-user" type="button">
            {profileName ? profileName[0] : null}
          </div>
          <div>
            <p className="profile-name">{profileName}</p>
            <p className="profile-email">{email}</p>
          </div>
        </div>
        <Divider sx={{ mt: '8px' }} />
        <StyledMenuItem
          sx={{ marginTop: '8px' }}
          style={{ paddingLeft: '22px' }}
          onClick={handleModalState}
        >
          Organisation
        </StyledMenuItem>
        <Divider sx={{ mt: '8px' }} />
        <Link className="link" to={USER_LOGOUT_ENDPOINT}>
          <StyledMenuItem sx={{ marginTop: '8px' }}>
            <ListItemIcon className="icon">
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </StyledMenuItem>
        </Link>
      </StyledMenu>
    </>
  );
}
