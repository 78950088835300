import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, INVITE_TEMPLATE_ENDPOINT } from '../../constants';

export class MasterInviteTemplate {
  static getInstance() {
    if (!this.instance) {
      this.instance = new MasterInviteTemplate(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = INVITE_TEMPLATE_ENDPOINT;
    this.fetcher = fetcher;
  }

  /**
   * this function is to get master invite template
   * @function getMasterInviteTemplate
   ** @param {reqParam} default parameter
   */
  async getMasterInviteTemplate(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }

  /**
   * this function is to put master invite template
   * @function putMasterInviteTemplate
   ** @param {req} default parameter
   */
  async putMasterInviteTemplate(req) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.putData(url, req);
    return response?.data;
  }
}

export const createMasterInviteTemplate = () => MasterInviteTemplate.getInstance();
