import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createUsers } from '../../Repositories';

const usersReq = createUsers();

/**
 * this function(query) is to get All Users
 * @function useUsersGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useUsersGetQuery(reqParams, options) {
  return useQuery(['usersGetQuery', reqParams], () => usersReq.getAllUsers(reqParams), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to get user by Id
 * @function useUserGetQueryById
 * @param {Number} Id Id pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useUserGetQueryById(reqId, options) {
  return useQuery(['userGetQueryById', reqId], () => usersReq.getUserById(reqId), {
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}

/**
 * this function(query) is to post position
 * @function useUsersPostQuery
 * @returns api response
 */

export function useUsersPostQuery() {
  const queryClient = useQueryClient();
  return useMutation(['usersPostQuery'], (args) => usersReq.postUser(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['usersGetQuery']);
    },
  });
}

/**
 * this function(query) is to put user by id
 * @function useUsersPutQueryById
 * @returns api response
 */
export function useUsersPutQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['userPutQueryById'], (args) => usersReq.putUserById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['usersGetQuery']);
    },
  });
}

/**
 * this function(query) is to delete user by id
 * @function useUsersDeleteQueryById
 * @returns api response
 */
export function useUsersDeleteQueryById() {
  const queryClient = useQueryClient();
  return useMutation(['userPutQueryById'], (args) => usersReq.deleteUserById(args), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['usersGetQuery']);
    },
  });
}
