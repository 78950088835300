import { Children, cloneElement, useEffect } from 'react';

import { useAddLevelQuery } from '../../Common/Queries/levelQueries';
/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return { name: values.name };
};

export const initialValues = { name: '' };

export function AddLevelContainer({ setOpen, children }) {
  const postToLevel = useAddLevelQuery();

  function handleSubmitForAddLevel(values) {
    const reqBody = formateData(values);
    postToLevel.mutate(reqBody);
  }

  useEffect(() => {
    if (postToLevel.isSuccess) {
      setOpen(false);
    }
  }, [postToLevel.isSuccess]);

  return Children.only(
    cloneElement(children, { postToLevel, handleSubmitForAddLevel, initialValues })
  );
}
