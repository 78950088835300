export class HttpErrors {
  constructor(error, status) {
    this.isHttpError = true;
    this.message = error.reason || '';
    this.name = 'Http Error';
    this.status = status;
    this.details = error.detail || {};
  }
}

export const isHttpError = (resp) => resp && resp.isHttpError === true;
