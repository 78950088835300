import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, CALENDAR_EVENTS_ENDPOINT } from '../../constants';

/**
 * Creates a new Person.
 * @Positions
 */
export class CalendarEvents {
  static getInstance() {
    if (!this.instance) {
      this.instance = new CalendarEvents(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = CALENDAR_EVENTS_ENDPOINT;
    this.fetcher = fetcher;
  }
  /**
   * this function is to get All events of calendar
   * @function getAllEvents
   ** @param {reqParam} default parameter
   */

  async getAllEvents(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return response?.data;
  }
}

export const createCalenderEvents = () => CalendarEvents.getInstance();
