import { Children, cloneElement, useEffect } from 'react';

import { useUsersPostQuery } from '../../Common/Queries/usersQueries';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return { name: values.name, email: values.email, role: values.role };
};

export const initialValues = { name: '', email: '', role: '' };

export function AddUserContainer({ setOpen, children }) {
  const postToUser = useUsersPostQuery();

  function handleSubmitForAddUser(e, values) {
    e.preventDefault();
    const reqBody = formateData(values);
    postToUser.mutate(reqBody);
  }

  useEffect(() => {
    if (postToUser.isSuccess) setOpen(false);
  }, [postToUser.isSuccess]);

  return Children.only(
    cloneElement(children, { postToUser, handleSubmitForAddUser, initialValues })
  );
}
