// required module
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.palette.confirmationButton,
  ...{ color: theme.palette.confirmationButton.main },
}));

/**
 * utility function for render confirmation popover
 * @param {*} props
 * @returns confirmation popover
 */
export default function Confirmation({ handler, message, setSave, CustomComponent }) {
  // handler function for confirm with yes
  const handleClick = (e) => {
    e.preventDefault();
    setSave(false);
    handler();
  };

  // handler function for confirm with no
  const handleClose = () => {
    setSave(false);
  };

  // render ui component
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: '14px' }}>
        {message}
      </DialogTitle>
      <form onSubmit={handleClick}>
        <DialogContent sx={{ padding: '8px 24px' }}>{CustomComponent}</DialogContent>
        <DialogActions>
          <StyledButton onClick={handleClose} data-testid="testConfirmCancel">
            No
          </StyledButton>
          <StyledButton type="submit" data-testid="testConfirmSave">
            Yes
          </StyledButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
