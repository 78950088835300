// @ts-check

/**
 * @function isValidEmail
 * @param {String} emailValue
 * @returns whether all the emails is in valid format or not
 */

export function isValidEmail(emailValue) {
  // if you are changing the regex please reach out to backend team so that both have
  // same regex and validation criteria is synced across the system
  const emailRegexString =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailRegex = new RegExp(emailRegexString);
  return emailRegex.test(emailValue);
}
