import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// custom buttons
const PrimaryCustomButton = styled(MuiButton)(({ theme }) => ({
  ...theme.palette.primaryButton,
  ...{
    color: theme.palette.primaryButton.main,
    pointerEvents: 'auto !important',
    width: 'auto',
    maxHeight: theme.spacing(5),
    borderRadius: theme.spacing(1),
  },
}));

const SecondaryCustomButton = styled(MuiButton)(({ theme }) => ({
  ...theme.palette.secondaryButton,
  ...{
    color: theme.palette.secondaryButton.main,
    float: 'right',
    maxHeight: theme.spacing(5),
    borderRadius: theme.spacing(1),
  },
}));

const DeleteButton = styled(MuiButton)(({ theme }) => ({
  ...theme.palette.deleteButton,
  ...{
    color: theme.palette.deleteButton.main,
    width: 'auto',
    maxHeight: theme.spacing(5),
    borderRadius: theme.spacing(1),
  },
}));

const WarnCustomButton = styled(MuiButton)(({ theme }) => ({
  ...theme.palette.warningButton,
  ...{
    color: theme.palette.warningButton.main,
    float: 'right',
    maxHeight: theme.spacing(5),
    borderRadius: theme.spacing(1),
  },
}));
export { DeleteButton, PrimaryCustomButton, SecondaryCustomButton, WarnCustomButton };
