import { Children, cloneElement, useEffect, useState } from 'react';

import { useUsersPutQueryById } from '../../Common/Queries/usersQueries';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return { ...values, name: values.name, email: values.email, role: values.role };
};

export function EditUserContainer(props) {
  const putToUser = useUsersPutQueryById(['usersGetQuery']);
  const { formData } = props;
  const [values, setValues] = useState(formData);

  useEffect(() => setValues(formData), [formData]);

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   */
  function handleOnSubmit(e, body) {
    e.preventDefault();
    const reqBody = formateData(body);
    const reqParams = { reqBody, reqId: props.userId };
    putToUser.mutate(reqParams);
  }

  // for closing pop over after editing user
  useEffect(() => {
    if (putToUser.isSuccess) props.setOpen(false);
  }, [putToUser.isSuccess]);

  const children = Children.only(props.children);
  return cloneElement(children, { values: values || {}, putToUser, handleOnSubmit });
}
