import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import {
  useDeleteStreamQueryById,
  useStreamGetQuery,
  useUpdateStreamQueryById,
} from '../../Common/Queries/streamQueries';

import { isValidID } from '../../utils';

import { AuthContext } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = ['status', 'department', 'searchValue', 'page', 'order', 'orderBy'];
  return keyArray.every((key) => {
    if (key === keyArray[0] || key === keyArray[1]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[2]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[3]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[4]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return (
      filterObject[key] === '' || filterObject[key] === 'name' || filterObject[key] === 'createdAt'
    );
  });
};

const initialValues = {
  status: [],
  department: [],
  searchValue: '',
  order: 'desc',
  orderBy: 'createdAt',
  page: 0,
};

export const StreamListContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const initialDepartment = isValidID(urlQuery.get('departmentID')) && urlQuery.get('departmentID');
  const initialStatus = urlQuery.get('status');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialSearch = urlQuery.get('search');

  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-stream`);

  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);

  const initialFilterValuesToApply = {
    ...initialValues,
    page: initialPage ? Number(initialPage - 1) : 0,
    ...(filterValuesObj && validateFilterObject(filterValuesObj)
      ? {
          status: initialStatus ? initialStatus.split(',') : filterValuesObj.status,
          department: initialDepartment
            ? initialDepartment.split(',').map((item) => Number(item))
            : filterValuesObj.department,
          searchValue: initialSearch || filterValuesObj.searchValue,
        }
      : {
          status: initialStatus ? initialStatus.split(',') : [],
          department: initialDepartment
            ? initialDepartment.split(',').map((item) => Number(item))
            : [],
          searchValue: initialSearch || '',
        }),
  };

  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  const streams = useStreamGetQuery({
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    status: filters.status.join(','),
    departmentID: filters.department.join(','),
    q: filters.searchValue,
    limit: 20,
  });

  const putToStream = useUpdateStreamQueryById();

  const deleteStream = useDeleteStreamQueryById();

  const handleOnPut = (id, status) => {
    const versionKeyArray = streams?.data?.data?.filter((item) => item.id === id);
    const reqParams = { reqBody: { ...versionKeyArray[0], status }, reqId: id };
    putToStream.mutate(reqParams);
  };

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const handleOnDelete = (id) => () => {
    const deletedRecord = streams?.data?.data?.filter((stream) => stream.id === id)[0];
    const reqBody = { versionKey: deletedRecord.versionKey };
    const reqParams = { reqBody, reqId: id };
    deleteStream.mutate(reqParams);
  };

  const child = Children.only(children);

  return cloneElement(child, {
    streams,
    putToStream,
    deleteStream,
    handleOnPutStream: handleOnPut,
    handleOnDeleteStream: handleOnDelete,
    filterHandler: handleOnFilterChange,
    filters,
  });
};
