import { createFetcher } from '../../Common';

import { ACCESS_TOKEN_ENDPOINT, BASE_URL } from '../../constants';

export class Refresh {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Refresh(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.endpoint = ACCESS_TOKEN_ENDPOINT;
    this.fetcher = fetcher;
  }

  async postRefresh(reqBody) {
    const url = `${this.base_url}${this.endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }
}

export const createRefresh = () => Refresh.getInstance();
