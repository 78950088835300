import { Children, cloneElement, useEffect } from 'react';

import { useTeamPostQuery } from '../../Common/Queries/teamQueries';
/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => ({ name: values.name });

export const initialValues = { name: '' };

export function AddTeamContainer({ setOpen, children }) {
  const postToTeam = useTeamPostQuery();

  function handleSubmitForAddTeam(e, values) {
    e.preventDefault();
    const reqBody = formateData(values);
    postToTeam.mutate(reqBody);
  }

  useEffect(() => {
    if (postToTeam.isSuccess) setOpen(false);
  }, [postToTeam.isSuccess]);

  return Children.only(
    cloneElement(children, { postToTeam, handleSubmitForAddTeam, initialValues })
  );
}
