const convertToCSV = (arr) => {
  const array = [Object.keys(arr[0])].concat(arr);
  return array
    .map((row) => {
      return Object.values(row)
        .map((value) => {
          return typeof value === 'string' ? JSON.stringify(value) : value;
        })
        .toString();
    })
    .join('\n');
};

const downloadCSV = (csvContent, fileName, mimType) => {
  const content = convertToCSV(csvContent);
  const a = document.createElement('a');
  const mimeType = mimType || 'application/octet-stream';

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(new Blob([content], { type: mimeType }), fileName);
  } else if (URL && 'download' in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], { type: mimeType }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export { convertToCSV, downloadCSV };
