import { Children, cloneElement, useState } from 'react';

import {
  usePositionsGetQueryById,
  usePositionsPutStatusQueryById,
} from '../../Common/Queries/positionsQueries';

export const ViewPositionContainer = ({ children, positionID }) => {
  const [filterValues, setFilterValues] = useState({ applicationStart: '', applicationEnd: '' });
  const positionData = usePositionsGetQueryById(
    {
      reqId: positionID,
      applicationStart: filterValues.applicationStart,
      applicationEnd: filterValues.applicationEnd,
    },
    {}
  );
  const putPosition = usePositionsPutStatusQueryById();

  /**
   * this function is handler function for trigger put-status query
   * @function handlePutStatus
   */
  const handlePutStatus = (status) => {
    const reqParams = { reqBody: { ...positionData.data, status }, reqId: positionID };
    putPosition.mutate(reqParams);
  };

  const child = Children.only(children);
  return cloneElement(child, {
    positionID,
    positionData,
    putPosition,
    filterValues,
    setFilterValues,
    handlePutStatus,
  });
};
