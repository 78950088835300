import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createMasterInviteTemplate } from '../../Repositories';

const templateRequest = createMasterInviteTemplate();

/**
 * this function(query) is to get master invite template
 * @function useMasterInviteTemplateGetQuery
 * @returns api response
 */
export function useMasterInviteTemplateGetQuery(reqParams, options) {
  return useQuery(
    ['masterInviteTemplateGetQuery', reqParams],
    () => templateRequest.getMasterInviteTemplate(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

/**
 * this function(query) is to put master invite template
 * @function useUpdateMasterInviteTemplateQuery
 * @returns api response
 */
export function useUpdateMasterInviteTemplateQuery() {
  const queryClient = useQueryClient();
  return useMutation(
    ['masterInviteTemplatePutQuery'],
    (args) => templateRequest.putMasterInviteTemplate(args),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['masterInviteTemplateGetQuery']);
      },
    }
  );
}
