import { Children, cloneElement, useContext, useState } from 'react';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import {
  useAgenciesGetQuery,
  useDeleteAgencyQueryById,
  useUpdateAgencyQueryById,
} from '../../Common/Queries/agenciesQueries';

import { isValidID } from '../../utils';

import { AuthContext } from '../../constants';

import { getFromLocalStorage } from '../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = ['status', 'type', 'searchValue', 'page', 'order', 'orderBy'];
  return keyArray.every((key) => {
    if (key === keyArray[0] || key === keyArray[1]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[2]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[3]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[4]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return (
      filterObject[key] === '' || filterObject[key] === 'name' || filterObject[key] === 'createdAt'
    );
  });
};

const initialValues = {
  status: [],
  type: [],
  searchValue: '',
  order: 'desc',
  orderBy: 'createdAt',
  page: 0,
};

export const AgenciesListContainer = ({ children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const initialStatus = urlQuery.get('status');
  const initialType = urlQuery.get('type');
  const initialPage = isValidID(urlQuery.get('page')) && urlQuery.get('page');
  const initialSearch = urlQuery.get('search');

  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-agency`);

  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);

  const initialFilterValuesToApply = {
    ...initialValues,
    page: initialPage ? Number(initialPage - 1) : 0,
    ...(filterValuesObj && validateFilterObject(filterValuesObj)
      ? {
          status: initialStatus ? initialStatus.split(',') : filterValuesObj.status,
          type: initialType ? initialType.split(',') : filterValuesObj.type,
          searchValue: initialSearch || filterValuesObj.searchValue,
        }
      : {
          status: initialStatus ? initialStatus.split(',') : [],
          type: initialType ? initialType.split(',') : [],
          searchValue: initialSearch || '',
        }),
  };

  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  const agencies = useAgenciesGetQuery({
    page: Number(filters.page + 1),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    status: filters.status.join(','),
    type: filters.type.join(','),
    q: filters.searchValue,
    limit: 20,
  });

  const deleteAgency = useDeleteAgencyQueryById();
  const putToAgency = useUpdateAgencyQueryById();

  const handleOnDelete = (id) => () => {
    const deletedRecord = agencies.data.data.filter((requirement) => requirement.id === id)[0];
    const reqBody = { versionKey: deletedRecord.versionKey };
    const reqParams = { reqBody, reqId: id };
    deleteAgency.mutate(reqParams);
  };

  const handleOnPut = (id, status) => {
    const versionKeyArray = agencies?.data?.data?.filter((item) => {
      return item.id === id;
    });
    const reqParams = { reqBody: { ...versionKeyArray[0], status }, reqId: id };
    putToAgency.mutate(reqParams);
  };

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, {
    agencies,
    deleteAgency,
    putToAgency,
    handleOnDeleteAgency: handleOnDelete,
    handleOnPutAgency: handleOnPut,
    filterHandler: handleOnFilterChange,
    filters,
  });
};
