import { Children, cloneElement, useContext, useEffect, useState } from 'react';

import { useURLQuery } from '../../../Common/Hook/urlQuery';
import { useHRReportsGetQuery } from '../../../Common/Queries/hrReportsQueries';

import { isValidDate } from '../../../utils';

import { AuthContext } from '../../../constants';

import { getFromLocalStorage } from '../../../services/localStorage';

export const validateFilterObject = (filterObject) => {
  const keyArray = ['status', 'searchValue', 'page', 'order', 'orderBy'];
  return keyArray.every((key) => {
    if (key === keyArray[0]) {
      return filterObject[key] !== undefined && Array.isArray(filterObject[key]);
    }
    if (key === keyArray[1]) {
      return filterObject[key] !== undefined && typeof filterObject[key] === 'string';
    }
    if (key === keyArray[2]) {
      return (
        filterObject[key] !== undefined &&
        typeof filterObject[key] === 'number' &&
        filterObject[key] >= 0
      );
    }
    if (key === keyArray[3]) {
      return (
        filterObject[key] === '' || filterObject[key] === 'asc' || filterObject[key] === 'desc'
      );
    }
    return filterObject[key] === '' || filterObject[key] === 'name';
  });
};

const initialValues = {
  status: [],
  order: '',
  orderBy: '',
  searchValue: '',
  page: 0,
  fromDate: '',
  toDate: '',
};

export const HRReportListContainer = ({ filters: filterValues, children }) => {
  const urlQuery = useURLQuery();
  const { userInfo } = useContext(AuthContext);
  const initialSearch = urlQuery.get('search');
  const initialStatus = urlQuery.get('status');
  const initialFilterValues = getFromLocalStorage(`${userInfo?.sub}-hrReport`);
  const filterValuesObj = initialFilterValues && JSON.parse(initialFilterValues);
  const initialFromDate = isValidDate(urlQuery.get('from')) && urlQuery.get('from');
  const initialToDate = isValidDate(urlQuery.get('to')) && urlQuery.get('to');

  const initialFilterValuesToApply = {
    ...initialValues,
    searchValue: initialSearch || '',
    order: '',
    orderBy: '',
    page: 0,
    fromDate: initialFromDate || filterValues.fromDate,
    toDate: initialToDate || filterValues.toDate,
    ...(filterValuesObj && validateFilterObject(filterValuesObj)
      ? { status: initialStatus ? initialStatus.split(',') : filterValuesObj.status }
      : { status: initialStatus ? initialStatus.split(',') : [] }),
  };
  const [filters, updateFilters] = useState(initialFilterValuesToApply);

  useEffect(() => {
    updateFilters((prev) => ({
      ...prev,
      fromDate: filterValues.fromDate,
      toDate: filterValues.toDate,
    }));
  }, [filterValues]);

  const interviewers = useHRReportsGetQuery({
    page: Number(filters.page + 1),
    status: filters.status.join(','),
    sort: filters.order.toUpperCase(),
    orderBy: filters.orderBy,
    'dateRange.fromDate': filters.fromDate,
    'dateRange.toDate': filters.toDate,
    'dateRange.includeDate': true,
    q: filters.searchValue,
    limit: 20,
  });

  const handleOnFilterChange = (updatedFilters) => {
    updateFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const child = Children.only(children);

  return cloneElement(child, { interviewers, filterHandler: handleOnFilterChange, filters });
};
