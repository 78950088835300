import { Children, cloneElement, useEffect } from 'react';

import { useApplicationsOfferPostQuery } from '../../../Common/Queries/applicationsQueries';

/**
 * this function is utility function for formate the data in required formate
 * @param {*} values of offer form
 * @returns values in required formate
 */
export const formateData = (values) => {
  return {
    offeredSalary: Number(values.offeredSalary),
    joiningDate: values.joiningDate,
    text: values.text,
    link: values.link,
  };
};

export function AddOfferContainer({ children, applicationId, setOpen }) {
  const postToOffer = useApplicationsOfferPostQuery();

  function handleSubmitForAddOffer(finalValues) {
    const reqBody = formateData(finalValues);
    const reqParam = { reqBody, reqId: applicationId };
    postToOffer.mutate(reqParam);
  }

  // closing popover after submitting form
  useEffect(() => {
    if (postToOffer.isSuccess) {
      setOpen(false);
    }
  }, [postToOffer.isSuccess]);

  return Children.only(cloneElement(children, { postToOffer, handleSubmitForAddOffer }));
}
