import { Children, cloneElement, useEffect } from 'react';

import { useAddAgencyQuery } from '../../Common/Queries/agenciesQueries';
/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return {
    name: values.name,
    email: values.email,
    phone: `+91${values.phone}`,
    type: values.type,
    website: values.website,
  };
};

export const initialValues = {
  name: '',
  email: '',
  phone: '',
  type: '',
  website: '',
};

export function AddAgencyContainer({ setOpen, children }) {
  const postToAgency = useAddAgencyQuery();

  function handleSubmitForAddAgency(e, values) {
    e.preventDefault();
    const reqBody = formateData(values);
    postToAgency.mutate(reqBody);
  }

  useEffect(() => {
    if (postToAgency.isSuccess) {
      setOpen(false);
    }
  }, [postToAgency.isSuccess]);

  return Children.only(
    cloneElement(children, { postToAgency, handleSubmitForAddAgency, initialValues })
  );
}
