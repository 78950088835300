import React, { useState } from 'react';

import useWindowSize from '../Common/Hook/useWindowSize';

import { decodeJwt } from '../utils';

import {
  AppContext,
  ApplicationContext,
  AuthContext,
  LOGGED_IN_USER_INFO,
  MOBILE_VIEW_WIDTH,
  OtherContext,
} from '../constants';

import { getParsedValueFromLocalStorage } from './localStorage';

const getUserInfo = () => {
  const { accessToken, refreshToken, tenants } =
    getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO) || {};
  const decodedInfo = decodeJwt(accessToken) || {};
  decodedInfo.accessToken = accessToken;
  decodedInfo.refreshToken = refreshToken;
  decodedInfo.tenants = tenants;
  return decodedInfo;
};

function ContextProvider({ children }) {
  const windowSize = useWindowSize();

  const [search, setSearch] = useState({ searchValue: '' });
  const [open, setOpen] = useState(windowSize.width > MOBILE_VIEW_WIDTH);
  const [appData, setAppData] = useState({
    POSITION_DATA: [],
    USER_DATA: [],
    STATUS_DATA: [],
    DEPARTMENT_DATA: [],
    STREAM_DATA: [],
    SKILL_DATA: [],
    LEVEL_DATA: [],
    AGENCY_DATA: [],
  });
  const [userInfo, setUserInfo] = useState(getUserInfo());

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <OtherContext.Provider value={{ isFullScreen: open, handleDrawerOpen, handleDrawerClose }}>
      <ApplicationContext.Provider value={{ search, setSearch }}>
        <AppContext.Provider value={{ appData, setAppData }}>
          <AuthContext.Provider value={{ userInfo, setUserInfo }}>{children}</AuthContext.Provider>
        </AppContext.Provider>
      </ApplicationContext.Provider>
    </OtherContext.Provider>
  );
}

export default ContextProvider;
