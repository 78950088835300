import React, { useContext } from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

import { useRefreshCall } from '../../Common/Queries/organizationQueries';

import { decodeJwt } from '../../utils';

import { AuthContext, DASHBOARD_ENDPOINT, LOGGED_IN_USER_INFO } from '../../constants';

import {
  getParsedValueFromLocalStorage,
  setValueToLocalStorage,
} from '../../services/localStorage';

const DialogDiv = styled('div')(({ theme }) => ({
  minWidth: '25rem',
  minHeight: '35rem',
  [theme.breakpoints.down('sm')]: {
    minWidth: '16rem',
    minHeight: '30rem',
  },
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  padding: '0px !important',
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

const HeaderDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledListItemText = styled(ListItemText)(() => ({
  paddingLeft: '9px',
}));

export default function OrganisationModal({ modalState, handleCloseOrgModal, tenantsData }) {
  const { setUserInfo } = useContext(AuthContext);

  const refreshCall = useRefreshCall({
    onSuccess: () => {
      const storedUserInfo = getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO);
      const claims = decodeJwt(storedUserInfo?.accessToken);
      const currentTenant = { name: claims['tenant-name'], id: claims['tenant-id'] };
      const tenantsArray = Array.isArray(storedUserInfo?.tenants)
        ? storedUserInfo.tenants.filter((tenant) => tenant?.name !== currentTenant?.name)
        : [];
      setUserInfo(claims);
      tenantsArray.unshift(currentTenant);
      storedUserInfo.tenants = tenantsArray;
      setValueToLocalStorage(LOGGED_IN_USER_INFO, JSON.stringify(storedUserInfo));
      handleCloseOrgModal();
      window.location.href = DASHBOARD_ENDPOINT;
    },
    onError: (error) => {
      console.error('Error during refresh call:', error);
    },
  });

  const setOrganisation = async (tenant) => {
    try {
      const payload = {
        tenantID: tenant.id,
        refreshToken: tenantsData?.refreshToken,
      };

      await refreshCall.mutateAsync(payload);
    } catch (error) {
      console.error('Error in setOrganisation:', error);
    }
  };

  return (
    <Dialog
      onClose={handleCloseOrgModal}
      aria-labelledby="customized-dialog-title"
      open={modalState}
    >
      <DialogDiv>
        <DialogTitle disableTypography>
          <HeaderDiv>
            <Typography variant="h3">Choose Organisation</Typography>
            <IconButton aria-label="close" onClick={handleCloseOrgModal}>
              <CloseIcon />
            </IconButton>
          </HeaderDiv>
        </DialogTitle>
        <StyledDialogContent>
          {tenantsData?.tenants?.map((tenant, index) => {
            return (
              <div
                key={tenant.id}
                style={{
                  backgroundColor: index === 0 ? '#E0E0E0' : '',
                }}
              >
                <StyledListItemButton onClick={() => setOrganisation(tenant)}>
                  <StyledListItemText
                    primary={`${tenant.name}  ${index === 0 ? '  (Current)' : ''}`}
                  />
                </StyledListItemButton>
                <Divider />
              </div>
            );
          })}
        </StyledDialogContent>
      </DialogDiv>
    </Dialog>
  );
}
