import { useEffect, useState } from 'react';

import { debounce } from '../../utils';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      },
      200
    );

    // Add event listener
    window.addEventListener('resize', debouncedHandleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default useWindowSize;
