import { Children, cloneElement, useEffect } from 'react';

import { useApplicationsOfferGetQueryById } from '../../../Common/Queries/applicationsQueries';
import {
  useInterviewsDeleteQueryById,
  useInterviewsGetQueryById,
} from '../../../Common/Queries/interviewsQueries';

/**
 * this function is utility function for formate the data in required formate
 * @param {*} values remaining values
 * @param {*} editedValue edited values
 * @returns values in required formate
 */
export const formateData = (values, editedValue) => {
  return {
    ...values,
    text: editedValue.reason,
    timeZone: values.timeZone || 'Asia/Kolkata',
  };
};

export function ExpandedEventContainer({
  children,
  setIsFieldLoading,
  setExpanded,
  event,
  ...props
}) {
  const interview = useInterviewsGetQueryById(event.interviewId);
  const offer =
    event.type === 'LATEST OFFER' && useApplicationsOfferGetQueryById(event.applicationId, {});
  const deleteInterview = useInterviewsDeleteQueryById();

  const handleRefresh = () => {
    if (event.type === 'LATEST OFFER') {
      offer.refetch();
    } else {
      interview.refetch();
    }
  };

  const handleDelete = (finalValues) => {
    const reqBody = formateData(interview.data, finalValues);
    const reqParams = { reqBody, reqId: event.interviewId };
    deleteInterview.mutate(reqParams);
  };

  useEffect(() => {
    setIsFieldLoading(deleteInterview.isLoading);
  }, [deleteInterview.isLoading]);

  useEffect(() => {
    if (deleteInterview.isSuccess) setExpanded(false);
  }, [deleteInterview.isSuccess]);

  return Children.only(
    cloneElement(children, {
      interview,
      offer,
      deleteInterview,
      event,
      handleRefresh,
      handleDeleteInterview: handleDelete,
      ...props,
    })
  );
}
