import { useMutation } from '@tanstack/react-query';

import { createAuthentication } from '../../Repositories';

const authReq = createAuthentication();

/**
 * this function(query) is to post User Login
 * @function useUserPostLoginQuery
 * @returns api response
 */
export function useUserPostLoginQuery() {
  return useMutation(['userPostLoginQuery'], (args) => authReq.postUserLogin(args), {
    retry: false,
  });
}
