import React from 'react';
import { FormControl, FormHelperText, Grid, Select } from '@mui/material';

import { GITHUB_REGEX } from '../../../constants';

import { InputText } from '../../Input';
import { GridDiv, Required } from '../../utility';

const linksTypeOptions = [
  { name: 'Github', value: 'github', key: '1' },
  { name: 'Other', value: 'other', key: '3' },
];

const linkPattern = { github: GITHUB_REGEX };

export default function Links({
  state,
  isTouch,
  handleLinksValidation,
  handleFormFields,
  handleAddAndRemoveSection,
  disbaleTitle = false,
}) {
  const isLinkedIn = state?.links?.[0]?.type === 'linkedIn';
  return (
    <div
      className={
        disbaleTitle ? 'applicant-same-row-border-margin-less' : 'applicant-same-row-border'
      }
    >
      {disbaleTitle ? <></> : <span className="inputName-applicant">Links</span>}
      {state.links.slice(isLinkedIn ? 1 : 0).map((link, i) => {
        const index = isLinkedIn ? i + 1 : i;
        const { type, typeField, value } = state.invalidFields.links[index];
        const isOther = !link.type.match('github') && link.type;
        return (
          <div
            key={`link-${index}`}
            className="applicant-same-row-flex"
            onBlur={() => handleLinksValidation(state.links)}
          >
            <GridDiv>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <div className="application-same-row-wrapper">
                    <FormControl
                      variant="outlined"
                      error={type.valid && isTouch.linksTouch}
                      fullWidth
                      sx={{ margin: '0px' }}
                      required
                    >
                      <Select
                        required
                        fullWidth
                        sx={{ height: '40px' }}
                        native
                        onChange={(e) => handleFormFields(e, index)}
                        onBlur={(e) => handleFormFields(e, index)}
                        value={isOther ? 'other' : link.type}
                        inputProps={{
                          'data-testid': 'testLinksType',
                          name: 'link-type',
                          id: `link-type-${index}`,
                        }}
                      >
                        <option aria-label="None" value="" disabled>
                          Select
                        </option>
                        {linksTypeOptions.map(({ key, name, value: linkValue }) => (
                          <option key={key} value={linkValue}>
                            {name}
                          </option>
                        ))}
                      </Select>

                      <FormHelperText>{type.errorMsg}</FormHelperText>
                    </FormControl>
                    <Required> *</Required>
                  </div>
                </Grid>

                {isOther ? (
                  <Grid item xs={12} md={4}>
                    <div className="application-same-row-wrapper">
                      <InputText
                        placeholder="Enter link type"
                        name="link-type-field"
                        required
                        sx={{ margin: '0px' }}
                        value={state.links[index].type === 'other' ? '' : state.links[index].type}
                        id={`link-type-${index}`}
                        error={typeField.valid && isTouch.linksTouch}
                        helperText={typeField.errorMsg}
                        inputProps={{ 'data-testid': 'testLinksTypeText' }}
                        onChange={(e) => handleFormFields(e, index)}
                        onBlur={(e) => handleFormFields(e, index)}
                      />
                      <Required> *</Required>
                    </div>
                  </Grid>
                ) : null}
                <Grid item md={isOther ? 5 : 9} xs={9}>
                  <div className="application-same-row-wrapper">
                    <InputText
                      placeholder="Enter link path"
                      name="link-value"
                      required
                      disabled={state.links[index].type === 'none'}
                      value={state.links[index].value}
                      sx={{ margin: '0px' }}
                      id={`link-value-${index}`}
                      error={value.valid && isTouch.linksTouch}
                      helperText={value.errorMsg}
                      inputProps={{
                        'data-testid': 'testLinksText',
                        pattern: (value.valid && linkPattern[state.links[index].type]) || null,
                      }}
                      onChange={(e) => handleFormFields(e, index)}
                      onBlur={(e) => handleFormFields(e, index)}
                    />
                    <Required> *</Required>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="remove-button">
                    <span
                      data-testid="testRemoveLinkButton"
                      className="remove-another-section-text"
                      id={`link-remove-button-${index}`}
                      role="button"
                      onClick={() => {
                        handleAddAndRemoveSection('link', index);
                      }}
                      tabIndex={0}
                      onKeyDown={() => {}}
                    >
                      -
                    </span>
                  </div>
                </Grid>
              </Grid>
            </GridDiv>
          </div>
        );
      })}
      <div className="add-remove-button-container_application_form">
        <span
          data-testid="testAddLinkButton"
          className="add-another-section-text_application_form"
          id="link-add-button"
          role="button"
          onClick={() => {
            handleAddAndRemoveSection('link');
          }}
          tabIndex={0}
          onKeyDown={() => {}}
        >
          Add
        </span>
      </div>
    </div>
  );
}
