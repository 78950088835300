// @ts-check

/**
 * @function getCurrentDate
 * @returns Date in required format
 */
export const getCurrentDate = () => {
  const date = new Date();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const newDate = `0${date.getDate()}`.slice(-2);
  return `${date.getFullYear()}-${month}-${newDate}`;
};
