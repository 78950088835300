import { createFetcher } from '../../Common';

import { ACCESS_TOKEN_ENDPOINT, BASE_URL, USER_LOGIN_ENDPOINT } from '../../constants';

export class Authenticate {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Authenticate(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.base_url = BASE_URL;
    this.login_endpoint = USER_LOGIN_ENDPOINT;
    this.access_token_endpoint = ACCESS_TOKEN_ENDPOINT;
    this.fetcher = fetcher;
  }

  async postUserLogin(reqBody) {
    const url = `${this.base_url}${this.login_endpoint}`;
    const response = await this.fetcher.postData(url, reqBody);
    return response?.data;
  }
}

export const createAuthentication = () => Authenticate.getInstance();
